import {useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import {  IoIosSearch } from "react-icons/io";
import { handleNavigateSearch } from "../../utils/AllFunction";
import { Toaster, toast } from "react-hot-toast";
import { useFetchDiagnosticTest } from "../../QueryClient/DiagnosticQuery";
import TestCard from "./components/TestCard";
import AppHeader from "../Medicine/Components/Medicine_Header"
import AppFooter from "../Medicine/Components/Medicine_footer"
import NoDataFoundComp from "../../components/NoDataFound";
import AppLoader from "../Medicine/animation/loader_anime";
import { useSelector, useDispatch } from "react-redux";
import { getDiagnosticTestList , fetchDiagnosticCartDetails } from "../../Redux/actions/GetApiCall";

const CommonBookTest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { data: testData, error: testError, isLoading: isAllpackageLoading } = useFetchDiagnosticTest();
  const testData = useSelector(state => state.diagnostic_test_list_response);
  const cartDetailsResponse = useSelector(state =>state.diagnostic_cart_details)
  useEffect(() =>{
    dispatch(getDiagnosticTestList());
    dispatch(fetchDiagnosticCartDetails());
  },[dispatch])



  return (
    <div>
      <div className="home-review-banner">
        <AppHeader
            Title="Common Book Test"
            labCartCount={cartDetailsResponse?.data?.response?.cart_count}
            prescrip={"diagnostics"}
          />
          <div className="common-test-wrapper common-book-test mt-1" style={{height:'75vh',overflow:'scroll'}}>
            <div className="app-searh-section lab-search-bar">
              <div className="inputWithIcon" onClick={() => handleNavigateSearch(navigate)}>
                <input type="text" placeholder="Search" />
                <button>
                  <IoIosSearch />
                </button>
              </div>
            </div>
            <div className="container mt-4" style={{height:'64.5vh'}}>
              <div className="row">
                {testData?.data?.data?.length > 0 ? (
                  testData?.data?.data?.map((elem, index) => (
                    <div className="col-6 common-repo mb-4">
                      <TestCard cartDetailsResponse={cartDetailsResponse} test={elem} />
                    </div>
                  ))
                ) : (
              <NoDataFoundComp />
                )}
              </div>
            </div>
          </div>
          {cartDetailsResponse && ( cartDetailsResponse?.data?.response?.tests?.length > 0 || cartDetailsResponse?.data?.response?.tests?.packages > 0 ) && (
            <AppFooter labCartData={cartDetailsResponse?.data.response}  tabkey="diagnostics"/>
          )}
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 1800,
          style: {
            background: "#fff",
            color: "#363636",
          },
        }}
      />
    </div>
  );
};

export default CommonBookTest;
