import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap'; // Add Spinner for loader
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import '../style/Telestyle.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentTimeSlot } from '../../../../../Redux/actions/PostApiCall';

const TeleReorder = ({ show, handleClose, departId, setSelectedReason, selectedReason, selectedDate, setSelectedDate, handleReorder }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false); // Track API loading state

    const department_time_slot_response = useSelector(
        (state) => state.department_time_slot_response
    );

    const handleDateChange = (date) => {
        const formData = new FormData();
        setSelectedDate(date);
        const formattedDate = format(date, 'dd-MM-yyyy');

        if (formattedDate && departId) {
            setLoading(true); // Start loading
            formData.append("department_id", departId);
            formData.append("date", formattedDate);

            dispatch(getDepartmentTimeSlot(formData))
                .finally(() => setLoading(false)); // End loading after the API call completes
        }
    };

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const today = new Date();
    const sevenDaysLater = new Date();
    sevenDaysLater.setDate(today.getDate() + 7);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header>
                <Modal.Title>
                    <p className='Reduc-late'>RESCHEDULE APPOINTMENT</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group-depo">
                    <label htmlFor="cancelDate">Select Date:</label>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        className="form-control"
                        id="cancelDate"
                        dateFormat="dd/MM/yyyy"
                        minDate={today}
                        placeholderText="Select Date"
                        maxDate={sevenDaysLater}
                    />
                </div>

                <div className="form-group-depo">
                    <label htmlFor="cancelReason">Select Time:</label>
                    <select
                        id="cancelReason"
                        value={selectedReason}
                        onChange={handleReasonChange}
                        className="form-control"
                        disabled={loading || !department_time_slot_response?.data} // Disable if loading or no data
                    >
                        <option value="" disabled>
                            {loading ? 'Loading...' : 'Select Time'}
                        </option>
                        {loading ? ( // Show loader when API is pending
                            <option value="" disabled>
                                <Spinner animation="border" size="sm" /> Loading...
                            </option>
                        ) : department_time_slot_response?.data?.data?.time_slots?.length ? (
                            department_time_slot_response.data.data.time_slots.map((time) => (
                                <option key={time} value={time}>
                                    {time}
                                </option>
                            ))
                        ) : (
                            <option value="" disabled>No time slots available</option>
                        )}
                    </select>
                </div>
                
                <div className="confirmation-buttons-repo-box">
                    <Button className='yes-modal-data' disabled={!selectedReason} onClick={handleReorder}>Yes</Button>
                    <Button className='no-modal-data' onClick={handleClose}>No</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default TeleReorder;
