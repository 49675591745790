import React, { useEffect, useState } from 'react'
import '../../Pages/Medicine/Medicine_Style/thankyou.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchAutoThankyouData } from '../../Redux/actions/GetApiCall'
import useLocationState from '../../Pages/Medicine/hooks/useLocationState'
import LoaderAnime from '../../Pages/Medicine/animation/prescription_loader'
import ThankMedicine from '../../Pages/Medicine/Components/ThankMedicine'

const AutoMedicineThank = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const orderData = useLocationState();
    console.log(orderData)
    const ThankData = useSelector(state => state.auto_thank_you);
    const navigate = useNavigate();
    const orderID = 1


    useEffect(() => {
        if (orderData?.medicine_order_id)
            dispatch(fetchAutoThankyouData(orderData?.medicine_order_id))
    }, [])

    useEffect(() => {
        if (ThankData?.status === "loading") {
            setShowModal(true);
        } else if (ThankData?.status === "success") {
            setShowModal(false);
        }
    }, [ThankData]);

    console.log(orderData)

    const DataId = {
        medicine_order_id: orderData?.medicine_order_id,
        onemg_order_id: orderData?.onemg_order_id
    };

    const TeleData = {
        orderId: orderData?.orderId?.id,
        tabKey: "third",
    };

    const handleOrderDetails = () => {
        if (orderData?.tabKey === "third") {
            // Navigate and pass TeleData if tabKey is "third"
            navigate("/teleconsultation-order-details", { state: TeleData });
        } else {
            // Navigate and pass DataId otherwise
            navigate("/shipment-details", { state: DataId });
        }
    };


    const product = ThankData?.data?.medicines || orderData?.orderId;
    const address = ThankData?.data?.address;

    console.log(orderData)

    return (
        <div className="home-review-banner">
            <div className="home-app-header order-details-repo-data sticky-top">
                <div className="header-logo ">
                    <div className="user-name text-center text-repoo">
                        <p>Order Placed</p>
                    </div>
                </div>
            </div>

            {showModal && <LoaderAnime showModal={showModal} />}

            <>
                <ThankMedicine ThankData={ThankData} product={product} address={address} />
            </>



            {/* {product && (
                <div className="order-placed-data-buttons sticky-lg-bottom sticky-md-bottom sticky-bottom">
                    <button className='back-home-order' onClick={handleHome}>
                        BACK TO HOME
                    </button>
                    <button className='details-home-order' onClick={handleOrderDetails}>
                        ORDER DEATILS
                    </button>
                </div>
            )} */}
        </div>

    )
}

export default AutoMedicineThank