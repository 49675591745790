import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { validateNumberInput } from '../../utils/AllFunction';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPincode } from '../../Redux/actions/GetApiCall';
import { headerLogo } from '../../images';
import useLocationState from '../Medicine/hooks/useLocationState';
import SuccessFull from '../../Animation/SuccessFull';
import { FitnessPro } from '../../Redux/actions/PostApiCall';
import { useNavigate } from 'react-router';

const FitnessModal = () => {
    const { register, watch, handleSubmit, formState: { errors } } = useForm();
    const cityData = useSelector(state => state.medicine_pincode);
    const pincodeValue = watch("pincode");
    const [modalShow, setModalShow] = useState(false)
    const dispatch = useDispatch();
    const Data = useLocationState();
    const PackData = useSelector(state => state?.fitness_pro)
    const navigate = useNavigate();

    useEffect(() => {
        if (pincodeValue?.length === 6) {
            dispatch(fetchPincode(pincodeValue));
        }
    }, [pincodeValue, dispatch]);

    const onSubmitHandler = async (data) => {
        setModalShow(true)
        const DataRepo = {
            type: Data?.type,
            pincode: data?.pincode,
            landmark: data?.landmark,
        }
        await dispatch(FitnessPro(DataRepo))

        setTimeout(() => {
            setModalShow(false)
            navigate('/home')
        }, 2000);
    };

    return (
        <div className="home-review-banner">
            <div className="home-app-header">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name">
                        <p style={{ marginLeft: '10px' }}>Fitness Pro</p>
                    </div>
                </div>
            </div>
            <div className="repo-content-box">
                <div className="use-call-box-image">
                    <img src={Data?.banner} alt="" />
                </div>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="repo-form-data-cover">
                        <div className='mb-3'>
                            <label htmlFor="pincode">Pincode:</label>
                            <div className="inputcontainer">
                                <input
                                    placeholder="Enter Pincode"
                                    {...register("pincode", {
                                        required: "Pincode is required",
                                        pattern: {
                                            value: /^[0-9]{6}$/,
                                            message: "Invalid pincode"
                                        },
                                    })}
                                    disabled={cityData?.loader === true}
                                    maxLength={6}
                                    onInput={validateNumberInput}
                                />
                                {cityData?.loader === true && (
                                    <div className="icon-container">
                                        <i className="loader"></i>
                                    </div>
                                )}
                            </div>
                            {cityData?.loader === false && pincodeValue && pincodeValue.length === 6 && (
                                <p className={cityData?.data?.success ? 'green' : 'red'}>
                                    {cityData?.data?.message}
                                </p>
                            )}

                            {errors.pincode && <p className="error-message">{errors.pincode.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="landmark">Landmark:</label>
                            <input
                                type="text"
                                id="landmark"
                                placeholder='Enter Landmark'
                                {...register("landmark", { required: "Landmark is required" })}
                            />
                            {errors.landmark && <p className='error-msg' style={{ textAlign: "start", fontSize: "13px", marginTop: '5px' }}>{errors.landmark.message}</p>}
                        </div>
                    </div>
                    <button type="submit" className='repo-fit-btn' disabled={cityData?.data?.city === ""}>Submit</button>
                </form>
            </div>

            {modalShow && (
                <SuccessFull show={modalShow} status={PackData?.status} successMessage={PackData?.message} />
            )}
        </div>
    );
};

export default FitnessModal;
