import React, { useEffect , useState } from "react";
import Loader from '../../../Animation/Loader';
import { getPrivacy} from "../../../Redux/actions/GetApiCall";
import { useDispatch , useSelector } from "react-redux";
import AppHeader from "../../Medicine/Components/Medicine_Header"

const PrivacyPolicy = () => {
    const dispatch = useDispatch();

    const privacy_reponse = useSelector((state) => state?.privacy_response)

    useEffect(() =>{
      dispatch(getPrivacy());
    },[]);


  return (
    <>
      <div className="home-review-banner">
        <AppHeader Title="Privacy Policy" />
        <div className="terms-condition-main-wrapper-cover">
        {
          privacy_reponse?.data?.html_content ? (
        <div dangerouslySetInnerHTML={{ __html: privacy_reponse?.data?.html_content }} />
            ) : <Loader/>
        }
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
