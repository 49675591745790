import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import Medicine_Header from '../Components/Medicine_Header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicinelist } from '../../../Redux/actions/GetApiCall';
import '../Medicine_Style/medicine-corner.css';
import MediLoader from '../animation/MediLoader';
import Medicine_Card from '../Components/Medicine_Card';
import Medicine_footer from '../Components/Medicine_footer';


const Medicine_Corner = () => {
    const location = useLocation();
    const Title = location.state;
    const searchData = location.state?.[0] || {}
    const item = location.state?.[0] || {};
    const dispatch = useDispatch();
    const [medicineCorner, setMedicineCorner] = useState([])
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [cartListData, setCartListData] = useState([]);
    const cartListLength = useSelector(state => state.medicine_cart_list)
    const key = "medicines"
    const WebLink = window.location.pathname;

    const { loader: mediloader, data: mediData, error: mediError } = useSelector(state => state.medicine_list);


    const isPageReloaded = () => {
        return window.performance && window.performance.navigation.type === 1;
    };

    useEffect(() => {
        if (isPageReloaded) {
            setLoaderStatus(true)
        }
    },[])



    useEffect(() => {
        if (item?.value !== undefined || searchData?.inputData !== undefined) {
            dispatch(fetchMedicinelist(item?.value || searchData?.inputData))
        }
    }, [dispatch])

    useEffect(() => {
        setMedicineCorner(mediData)
    }, [mediData])

    const height = "75%"
    const corner = "data"

    const wrapperHeight = cartListLength?.data?.total_items > 0 ? "80vh" : "100vh";
    const containerClassName = mediData?.data.length > 1 ? "corner-data-container" : "";
    const containerStyle = WebLink === '/health-corner' ? { display: 'block' } : { display: 'flex' };

    useEffect(() => {
        if (!mediloader && (mediData || mediError)) {
            setLoaderStatus(false);
        }
    }, [mediloader, mediData, mediError]);

    return (
        <div className="home-review-banner">
            <Medicine_Header Title={item?.label || searchData?.Title} cartData={cartListData} />

            <div className="corner-wrapper-box" style={{ height: wrapperHeight }}>
                {loaderStatus ? (
                    <MediLoader height={height} />
                ) : (
                    <div className={containerClassName} style={containerStyle}>
                        <div className="row">
                            <Medicine_Card
                                setCartListData={setCartListData}
                                mediData={medicineCorner}
                                corner={corner}
                                inputPara={item?.value || searchData?.inputData}
                                setLoaderStatus={setLoaderStatus}
                            />
                        </div>
                    </div>
                )}
            </div>
            {cartListLength?.data?.total_items > 0 && cartListLength?.data?.available_stock.length > 0 && (
                <Medicine_footer cartData={cartListData} tabkey={key} />
            )}
        </div>
    )
}

export default Medicine_Corner