import { React, useEffect } from "react";
import Loader from "../../../Animation/Loader";
import { fetchPackageList } from "../../../Redux/actions/GetApiCall";
import { useDispatch, useSelector } from "react-redux";
import { NO_PACKAGE_GIF } from "../../../Constants";
import AppHeader from "../../Medicine/Components/Medicine_Header"

const MyPackageDetails = () => {
  const dispatch = useDispatch();
  const packagesData = useSelector((state) => state.package_list);

  useEffect(() => {
    dispatch(fetchPackageList());
  }, [dispatch])

  return (
    <>
      <div className="home-review-banner">
        <AppHeader Title="My Package Details" />
        {packagesData?.loader ? (
          <Loader />
        ) : (
          <div className="package-details-main-cover">
            {packagesData?.data?.active_package?.length > 0 && (
              <>
                <div className="package-card-wrapper mt-3">
                  <h6 className="pack-title">Active Package</h6>
                  {packagesData?.data?.active_package.map((packageData, index) => (
                    <div className="package-card" key={index}>
                      <div key={index} className="card-item-card">
                        <h4 className="package-title">{packageData.name}</h4>
                        <div className="package-details">
                          <div className="package-info">
                            <p>
                              Member Since: <span>{packageData.ordered_at}</span>
                            </p>
                            <p>
                              Valid Till: <span>{packageData.expiring_on}</span>
                            </p>
                          </div>
                          <div className="package-wallet">
                            <p>Wallet Amount:</p>
                            <h3>{packageData?.wallet_details?.final_wallet_amount}</h3>
                          </div>
                        </div>
                      </div>

                    </div>
                  ))}
                </div>
              </>
            )}

            <div className="package-card-wrapper mt-3">
              {packagesData?.data?.expired_package.map((packageData, index) => (
                <>
                  <h6 className="pack-title">Expired Package</h6>
                  <div className="package-card deactive" key={index}>
                    <div key={index} className="card-item-card">
                      <h4 className="package-title">{packageData.name}</h4>
                      <div className="package-details">
                        <div className="package-info">
                          <p>
                            Member Since: <span>{packageData.ordered_at}</span>
                          </p>
                          <p>
                            Valid Till: <span>{packageData.expiring_on}</span>
                          </p>
                        </div>
                        <div className="package-wallet">
                          <p>Wallet Amount:</p>
                          <h3>{packageData?.wallet_details?.final_wallet_amount}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
            {packagesData?.data?.active_package?.length === 0 && packagesData?.data?.expired_package?.length === 0 && (
              <div className="no-package-data-found">
                <img src={NO_PACKAGE_GIF} alt="no-package-gif"></img>
                <p className="text-center">No Packages Found</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MyPackageDetails;
