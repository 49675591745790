import React from 'react'
import { headerLogo, teleBanner1, teleBanner2 } from '../../images'
import { FiSearch } from 'react-icons/fi';
import './doctorhome.css'
import { ban1, ban2, hospitalIcon } from '../Medicine/Images';
import { DoctorData, HospitalCategories, HospitalData, HospitalFaq } from '../../data';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router';
import { Accordion } from 'react-bootstrap';
import DoctorCard from './DoctorCard';

function createRotatingShadowAnimation(color) {
    const styleSheet = document.styleSheets[0];
    const animationName = `rotating-shadow-${color.replace('#', '')}`;

    // Check if animation already exists to prevent duplicates
    if ([...styleSheet.cssRules].some(rule => rule.name === animationName)) return animationName;

    // Create the keyframes with the dynamic color
    const keyframes = `
    @keyframes ${animationName} {
        0%   { box-shadow: 0px -2px 4px ${color}; }
        25%  { box-shadow: 2px 0px 4px ${color}; }
        50%  { box-shadow: 0px 2px 4px ${color}; }
        75%  { box-shadow: -2px 0px 4px ${color}; }
        100% { box-shadow: 0px -2px 4px ${color}; }
    }
`;


    // Insert the new keyframes rule into the stylesheet
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    return animationName;
}


const DoctorHome = () => {
    const navigate = useNavigate();
    const handleDoctor = () => {
        navigate("/hospitals", { state: HospitalData })
    }

    const handleHospitalDetails = (item) => {
        navigate('/hospital-details', { state: item })
    }

    const handleDoctorDetails = () => {
        navigate('/doctor')
    }
    const handleDoctorDepartment = () => {
        navigate('/doctor-department')
    }

    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Doctor Consultation</p>
                    </div>
                </div>
            </div>

            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search doctor..."
                    onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;  // Regular expression to allow letters and spaces only
                        if (!regex.test(e.key)) {
                            e.preventDefault(); // Prevent non-letter characters
                        }
                    }}
                />
                <FiSearch />

            </div>

            <div className="Hospital-department-repo-clover hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>Departments</p>
                    <button onClick={handleDoctorDepartment}>View All</button>
                </div>

                <div className="department-clover-repo-hos">
                    {HospitalCategories.slice(0, 6).map((item, index) => {
                        const shadowColor = item.boxColor; // Color from the API
                        const animationName = createRotatingShadowAnimation(shadowColor);

                        return (
                            <div className="department-card-clover" key={index} onClick={handleDoctorDetails}>
                                <div
                                    className="icons-repo-cover-de depart-icon"
                                    style={{
                                        boxShadow: `0px 2px 4px ${shadowColor}`, // Initial shadow with dynamic color
                                        animation: `${animationName} 3s linear infinite`,
                                        color: shadowColor
                                    }}
                                >
                                    {item?.icon}
                                </div>

                                <div className="text-department-repo">
                                    <p>{item?.department}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>




            <div className="Hospital-banner-repo-clover hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>Banners</p>
                    {/* <button>View All</button> */}
                </div>

                <div className="hospital-categories-wrapper">
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={1}
                        loop={true}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 3000, // Adjust the delay for autoplay
                            disableOnInteraction: false, // Allows autoplay to continue after user interactions
                        }}
                        speed={600}
                        className='pb-2'
                    >
                        <SwiperSlide>
                            <div className="banner-image-repo-clover">
                                <img src={ban1} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="banner-image-repo-clover">
                                <img src={ban2} alt="" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>Recent Doctors</p>
                    {/* <button>View All</button> */}
                </div>
                <div className="hospital-categories-wrapper">
                    <Swiper
                        spaceBetween={1}
                        slidesPerView={1}
                        loop={true}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        speed={600}
                        className='pb-2'
                    >
                        {DoctorData.map((item, index) => (
                            <SwiperSlide key={index}
                            >
                                <DoctorCard doctor={item} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            </div>

            <div className="hospital-faq-department-data hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>FAQ</p>
                    {/* <button>View All</button> */}
                </div>

                <div className="need-help-accordion-main-cover-wrapper">
                    <Accordion defaultActiveKey="0" className='faq-box-clover'>
                        {HospitalFaq?.map((helpData, ind) => {
                            return (
                                <Accordion.Item eventKey={ind} key={ind}>
                                    <Accordion.Header>
                                        {helpData?.question}
                                    </Accordion.Header>
                                    <Accordion.Body>{helpData?.answer}</Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default DoctorHome