import { React, useEffect, useState, useContext } from "react";
import {
  ArrowBack,
  appAvatarDefault,
} from "../../images";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import { AppContext } from "../../ContextApi";
import ConfirmModal from "./Components/ConfirmModal";
import { fetchPackageList } from "../../Redux/actions/GetApiCall";
import { useDispatch , useSelector} from "react-redux";
import NavigationList from "./Components/ProfileList";

const Profile = (props) => {

  const { handleClose, formShow, handleShow } = useContext(AppContext);
  const dispatch = useDispatch();
  const package_list = useSelector((state) =>state?.package_list);
  console.log(package_list)

  const navigate = useNavigate();

  useEffect(() =>{
    dispatch(fetchPackageList())
  },[dispatch])

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    const newToken = localStorage.getItem("APP_TOKEN");;
    if (!newToken) {
      navigate("/");
    }
  };


  return (
    <>
      <div className="home-review-banner">
        <div className="profile-banner">
          <div className="back-icon">
            <NavLink onClick={ArrowBackButton}>
              <img src={ArrowBack} width="100%" alt="arrow-back" />
            </NavLink>
          </div>
          <div className="prescription-heading">
            <h5>Menu</h5>
          </div>
        </div>

        <div className="user-main-cover">
          <div className="user-cover-banner">
            <div className="user-banner-cont-box">
              <div className="user-banner-conts-box">
                <div className="user-image-section">
                  <img src={appAvatarDefault} width="100%" alt="Avatar" />
                </div>
                {package_list?.loader ? (
                  <Loader />
                ) : (
                  <div className="user-detail-box-sec">
                    <h4>{package_list?.data?.profile_details?.name}</h4>
                    <p>{package_list?.data?.profile_details?.mobile_number}</p>
                    <p>{package_list?.data?.profile_details?.email_id}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="navigation-list-cover-wrapper">
            <NavigationList handleShow={handleShow} />
          </div>
        </div>
      </div>
      <ConfirmModal handleClose={handleClose} formShow={formShow} onSubmit={handleLogout} heading={"Are you sure you want to logout ?"} />
    </>
  );
};

export default Profile;
