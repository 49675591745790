import React, { useEffect, useState } from 'react'
import useCartFunction from '../hooks/useCartFunction';
import { useDispatch } from 'react-redux';
import { fetchMedicineCartList } from '../../../Redux/actions/GetApiCall';
import { no_preview, px_Image } from '../Images'
import CartLoader from '../animation/CartLoader';
import toast from 'react-hot-toast';
import LoaderAnime from "../animation/prescription_loader";
import ToastBox from '../animation/ToastBox';
import { VscTrash } from "react-icons/vsc";
import { useNavigate } from 'react-router-dom';

const Cart_Card = ({ setCartListData, MedicineData, cartLoader, cartListData, walletId, selectedPackage }) => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [loaderStatus, setLoaderStatus] = useState(false)
    const { cartStatus, cart, CartData, DeleteStatus, loadingItems, handleIncrement, handleDecrement, handleDelete } = useCartFunction(null, setLoaderStatus, setCartListData,walletId,selectedPackage);

    useEffect(() => {
        if (DeleteStatus || (cartLoader && !loaderStatus)) {
            setShowModal(true)
        } else {
            setShowModal(false)
        }
    }, [DeleteStatus, cartLoader, cartListData, CartData, loaderStatus]);

    const hanldeDescription = (item) => {
        const data = {
            Title: "Product Details",
            data: item
        };
        navigate('/medicine-description', { state: data });
    };

    // console.log(CartData)

    return (
        <>
            {((cartLoader && DeleteStatus) || (cartLoader && !loaderStatus)) && <LoaderAnime showModal={showModal} />}
            <div className="cart-wrapper-repo-cover-box">
                {MedicineData?.available_stock?.map((item, index) => {
                    const cartItem = cart.find(cartItem => cartItem.sku_id === item.sku_id);
                    return (
                        <div key={item?.sku_id}>
                            <div className="product-wrapper-box-conver">
                                <div className="image-product-cov" onClick={() => hanldeDescription(item)}>
                                    {item?.prescription_required === 1 && (
                                        <div className="product-px-cover-repo">
                                            <img src={px_Image} alt="px-image" />
                                        </div>
                                    )}
                                    <img src={item?.banner_url ? item?.banner_url : no_preview}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = no_preview;
                                        }}
                                        alt="product-icon" />
                                </div>
                                <div className="product-data-desc-box">
                                    <div className="product-repo-data-cont">
                                        <div className="product-name">
                                            <p className='ellipsis-text-cart'>{item?.name}</p>
                                            {cartItem && cartItem.quantity > 1 && (
                                                <button key={cartItem.sku_id} onClick={() => handleDelete(cartItem.sku_id)}>
                                                    <VscTrash />
                                                </button>
                                            )}


                                        </div>
                                        <div className="repo-prod-data-cvoer">
                                            <div className="price-qty-cove">
                                                <div className="product-price-text">
                                                    <p>
                                                        {CartData?.data?.some(count => count.sku_id === item?.sku_id) ? (
                                                            CartData.data.filter(count => count.sku_id === item?.sku_id).map((count, idx) => (
                                                                <p key={idx}>
                                                                    {(count.discounted_price !== null && count?.discounted_price !== 0)
                                                                        ? (String(count?.discounted_price).startsWith('₹') ? count?.discounted_price : `₹${count?.discounted_price}`)
                                                                        : (count?.price !== 0 && count?.price !== null)
                                                                            ? (String(count?.price).startsWith('₹') ? count?.price : `₹${count?.price}`)
                                                                            : "No price available"}
                                                                </p>
                                                            ))
                                                        ) : (
                                                            <p>
                                                                {(item?.discounted_price !== null && item?.discounted_price !== 0)
                                                                    ? (String(item.discounted_price).startsWith('₹') ? item.discounted_price : `₹${item.discounted_price}`)
                                                                    : (item?.price !== 0 && item?.price !== null)
                                                                        ? (String(item.price).startsWith('₹') ? item.price : `₹${item.price}`)
                                                                        : "No price available"}
                                                            </p>
                                                        )}
                                                    </p>

                                                    {CartData?.data?.some(count => count.sku_id === item?.sku_id) ? (
                                                        CartData.data.filter(count => count.sku_id === item?.sku_id).map((count, idx) => (
                                                            (count?.discounted_price !== count?.price) && (
                                                                <span key={idx}>
                                                                    {(count?.price !== 0 && count?.price !== null) &&
                                                                        (String(count?.price).startsWith('₹') ? count?.price : `₹${count?.price}`)}
                                                                </span>
                                                            )
                                                        ))
                                                    ) : (
                                                        (item?.discounted_price !== item?.price) && (
                                                            <span>
                                                                {(item?.price !== 0 && item?.price !== null) &&
                                                                    (String(item?.price).startsWith('₹') ? item.price : `₹${item.price}`)}
                                                            </span>
                                                        )
                                                    )}

                                                    <div className="cart-discount-count-text">
                                                        {CartData?.data?.some(count => count.sku_id === item?.sku_id) ? (
                                                            CartData.data.filter(count => count.sku_id === item?.sku_id).map((count, idx) => (
                                                                <p key={idx}>
                                                                    {count.discount}
                                                                </p>
                                                            ))
                                                        ) : (
                                                            <p>{item?.discount}</p>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="product-qty">
                                                    <p>Min. Qty : {item?.min_quantity}</p>
                                                </div>
                                            </div>
                                            <div className="product-repo-buttons-repo">
                                                <div className="remove-btn-repo-cover">
                                                </div>
                                                <div className="add-product-box">
                                                    {(loadingItems.has(item.sku_id) && (cartStatus === 'loading' || DeleteStatus === "loading")) ? (
                                                        <CartLoader />
                                                    ) : (
                                                        (cartItem || (item?.is_available === 1 && item?.quantity !== 0)) && (
                                                            <div className="increase-cart-repo-boxs">
                                                                <button
                                                                    className={`incre-btn-reop ${cartItem && cartItem.quantity <= item.min_quantity ? 'border-red' : ''}`}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (cartItem && cartItem.quantity <= item.min_quantity) {
                                                                            handleDelete(item.sku_id);
                                                                        } else {
                                                                            handleDecrement(item.sku_id);
                                                                        }
                                                                    }}
                                                                >
                                                                    <p>-</p>
                                                                </button>

                                                                {/* {CartData?.data?.map((count, index) =>
                                                                    count.sku_id === item.sku_id ? (
                                                                        <span key={index}>
                                                                            {count.quantity}
                                                                        </span>
                                                                    ) : null
                                                                ) || ( */}
                                                                <span>
                                                                    {item?.quantity}
                                                                </span>
                                                                {/* // )} */}
                                                                <button
                                                                    className='incre-btn-reop'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (cartItem && cartItem.quantity < item.max_quantity) {
                                                                            handleIncrement(item.sku_id);
                                                                        } else {
                                                                            toast.error("Max Reached");
                                                                        }
                                                                    }}
                                                                >
                                                                    <p>+</p>
                                                                </button>
                                                            </div>
                                                        )
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {index < MedicineData?.available_stock?.length - 1 && <hr className='product-hr' />}
                        </div>
                    )
                })}
            </div>

            <div className="out-stock-cover-wrapper">

                {MedicineData?.out_of_stock.length > 0 && (
                    <div className="text-repo-out-stock">
                        <p>OUT OF STOCK PRODUCTS</p>
                    </div>
                )}
                {MedicineData?.out_of_stock?.map((item, index) => (
                    <>

                        <div key={item?.sku_id}>
                            <div className="product-wrapper-box-conver">
                                <div className="image-product-cov">
                                    {item?.prescription_required === 0 && (
                                        <div className="product-px-cover-repo">
                                            <img src={px_Image} alt="px-image" />
                                        </div>
                                    )}
                                    <img src={item?.banner_url ? item?.banner_url : no_preview}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = no_preview;
                                        }}
                                        alt="product-icon" />
                                </div>
                                <div className="product-data-desc-box">
                                    <div className="product-repo-data-cont">
                                        <div className="product-name">
                                            <p className='ellipsis-text-cart'>{item?.name}</p>
                                        </div>
                                        <div className="repo-prod-data-cvoer">
                                            <div className="price-qty-cove">
                                                <div className="product-price-text">
                                                    <p>
                                                        {(item?.discounted_price !== null && item?.discounted_price !== 0)
                                                            ? (String(item.discounted_price).startsWith('₹') ? item.discounted_price : `₹${item.discounted_price}`)
                                                            : (item?.price !== 0 && item?.price !== null)
                                                                ? (String(item.price).startsWith('₹') ? item.price : `₹${item.price}`)
                                                                : "No price available"}
                                                    </p>

                                                    <span>
                                                        {(item?.discounted_price !== null && item?.discounted_price !== 0) &&
                                                            (String(item.price).startsWith('₹') ? item.price : `₹${item.price}`)}
                                                    </span>
                                                </div>


                                                <div className="product-qty">
                                                    <p>Min. Qty : {item?.min_quantity}</p>
                                                </div>
                                            </div>
                                            <div className="product-repo-buttons-repo">
                                                <div className="remove-btn-repo-cover">
                                                    <button onClick={() => handleDelete(item?.sku_id)}>Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {index < MedicineData?.out_of_stock?.length - 1 && <hr className='product-hr' />}
                        </div>
                    </>
                ))}
            </div>

            <ToastBox />
        </>
    )
}

export default Cart_Card