import React from 'react'
import { appLabReportIcon } from '../../../images'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const HowitWorks = () => {
  return (
    <div className="how-it-works-main-cover-wrapper">
           <div className="health-work">
          <div className="book-test">
            <h6>How it works</h6>
          </div>
          <div className="health-how-it-works">
            <Swiper
              spaceBetween={10}
              slidesPerView={1.5}
              loop="true"
              modules={[Autoplay]}
              pagination={{ clickable: true }}
            // autoplay={{ delay: 2500, disableoninteraction: false, }}
            >
              <div className="swiper-wrapper">
                <SwiperSlide>
                  <div className="health-test-work">
                    <div className="health-test-number">
                      <span>01</span>
                    </div>
                    <div className="health-test-box-cover">
                      <img
                        src={appLabReportIcon}
                        width="100%"
                        alt="Lab-report"
                      />
                      <h6>Test Booking</h6>
                      <p>
                        Select your test as per your condition and requirement
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="health-test-work">
                    <div className="health-test-number">
                      <span>02</span>
                    </div>
                    <div className="health-test-box-cover">
                      <img
                        src={appLabReportIcon}
                        width="100%"
                        alt="Lab-report"
                      />
                      <h6>Health Expert Deputed</h6>
                      <p>Highly skilled Technicians will collect your sample</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="health-test-work">
                    <div className="health-test-number">
                      <span>03</span>
                    </div>
                    <div className="health-test-box-cover">
                      <img
                        src={appLabReportIcon}
                        width="100%"
                        alt="Lab-report"
                      />
                      <h6>Sample Collection</h6>
                      <p>
                        100% safe and Hygienic sample collection at your
                        convenient space
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="health-test-work">
                    <div className="health-test-number">
                      <span>04</span>
                    </div>
                    <div className="health-test-box-cover">
                      <img
                        src={appLabReportIcon}
                        width="100%"
                        alt="Lab-report"
                      />
                      <h6>Test Investigation</h6>
                      <p>
                        NABL accreditation laboratories will investigate your
                        sample
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="health-test-work">
                    <div className="health-test-number">
                      <span>05</span>
                    </div>
                    <div className="health-test-box-cover">
                      <img
                        src={appLabReportIcon}
                        width="100%"
                        alt="Lab-report"
                      />
                      <h6>E-Reports</h6>
                      <p>
                        You will receive your E-report on your register email
                        id.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
    </div>
  )
}

export default HowitWorks