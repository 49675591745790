import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import { Nav, Row, Col, Tab } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Loader from "../../Animation/Loader";
import {
  useFetchTestDescription,
  useFetchDiagnosticTest,
} from "../../QueryClient/DiagnosticQuery";
import {
  getTestDescription,
  getDiagnosticTestList,
  fetchDiagnosticCartDetails,
  fetchRadioApi,
  fetchRadioCartDetails,
  getRadioTestList,
} from "../../Redux/actions/GetApiCall";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "../Medicine/Components/Medicine_Header";
import Appfooter from "../Medicine/Components/Medicine_footer";

import {
  ArrowBack,
  appFastingTime,
  appRecommended,
  appAge,
  appReports,
  appCart,
  appLabCart,
} from "../../images";
import NoDataFoundComp from "../../components/NoDataFound";
import RadioFooter from "./RadioFooter";
import WhyBookwithUs from "../LabTestPages/components/WhyBookwithUs";
import RecommendedSection from "../LabTestPages/components/RecommendedSection";
import DescriptionDetailBox from "../LabTestPages/components/DescriptionDetailBox";
import RadioDescriptionButton from "./RadioDescriptionButton";
import RadioTestDescriptionButton from "./RadioTestDescriptionButton";
import RadioRecommendedSection from "./RadioRecommendedSection";

const RadioTestDescription = () => {
  const dispatch = useDispatch();
  const key = "diagnostics";
  const { test_id } = useParams();
  const testDetailsData = useSelector(
    (state) => state?.home_list_radio
  );
  const diagnostic_test_list_response = useSelector(
    (state) => state?.radio_test_list_response
  );
  const cartDetailsResponse = useSelector(
    (state) => state.radio_cart_details
  );

  useEffect(() => {
    dispatch(fetchRadioApi(test_id));
    dispatch(getRadioTestList());
    dispatch(fetchRadioCartDetails());
  }, [test_id]);

  return (
    <>
      <div className="home-review-banner">
        <AppHeader
          Title="Test Description"
          labCartCount={cartDetailsResponse?.data?.response?.cart_count}
          prescrip={key}
        />
        {testDetailsData?.loader ? (
          <Loader />
        ) : (
          <div className="package-details-main-cover-wrapper">
            {testDetailsData?.data?.data?.length > 0 ? (
              <>
                <DescriptionDetailBox detailsData={testDetailsData} />
                <div className="description-tabs">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row className="">
                      <Col lg={12}>
                        <Nav
                          variant="pills"
                          className="d-flex justify-content-center mb-2"
                        >
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              className="description-tab"
                              style={{borderRadius:'15px'}}
                            >
                              Test Description
                            </Nav.Link>
                          </Nav.Item>
                          {/* <Nav.Item>
                            <Nav.Link
                              eventKey="second"
                              className="description-tab"
                            >
                              Included Tests
                            </Nav.Link>
                          </Nav.Item> */}
                        </Nav>
                      </Col>
                      <Col lg={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <>
                              {testDetailsData?.data?.data
                                ?.pack_description && (
                                <div className="test-description-content-box">
                                  <p>
                                    {
                                      testDetailsData?.data?.data
                                        ?.pack_description
                                    }
                                  </p>
                                </div>
                              )}
                              {(testDetailsData?.data?.data[0]?.description !==
                                null ||
                                testDetailsData?.data?.data[0]?.description !==
                                  "") && (
                                <p className="description-repo-data-test">
                                  {testDetailsData?.data?.data[0]?.description}
                                </p>
                              )}
                              {testDetailsData?.data?.data[0]?.test_parameter
                                ?.length > 0 && (
                                <div className="test-desc-tabs">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-6 desc-detail-box-repo">
                                        <div className="desc-cover-box">
                                          <div className="desc-image-box">
                                            <img
                                              src={appReports}
                                              width="100%"
                                              alt="review"
                                            />
                                          </div>
                                          <div className="desc-detail-box">
                                            <p>E-Reports</p>
                                            <p>
                                              {
                                                testDetailsData?.data?.data[0]
                                                  ?.report_time
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6 desc-detail-box-repo">
                                        <div className="desc-cover-box">
                                          <div className="desc-image-box">
                                            <img
                                              src={appFastingTime}
                                              width="100%"
                                              alt="review"
                                            />
                                          </div>
                                          <div className="desc-detail-box">
                                            <p>Fasting Time</p>
                                            <p>
                                              {
                                                testDetailsData?.data?.data[0]
                                                  ?.fasting
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6 desc-detail-box-repo">
                                        <div className="desc-cover-box">
                                          <div className="desc-image-box">
                                            <img
                                              src={appRecommended}
                                              width="100%"
                                              alt="review"
                                            />
                                          </div>
                                          <div className="desc-detail-box">
                                            <p>Recommended</p>
                                            <p>
                                              for -{" "}
                                              {
                                                testDetailsData?.data?.data[0]
                                                  ?.gender
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-6 desc-detail-box-repo">
                                        <div className="desc-cover-box">
                                          <div className="desc-image-box">
                                            <img
                                              src={appAge}
                                              width="100%"
                                              alt="review"
                                            />
                                          </div>
                                          <div className="desc-detail-box">
                                            <p>Age</p>
                                            <p>
                                              {
                                                testDetailsData?.data?.data[0]
                                                  ?.age
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          </Tab.Pane>
                          {/* <Tab.Pane eventKey="second">
                            <div className="test-accordion-box">
                              <Accordion defaultActiveKey="0">
                                {testDetailsData?.data?.data[0]?.test_parameter
                                  ?.length > 0 ? (
                                  <Accordion.Item eventKey={0}>
                                    <Accordion.Header>
                                      <h6>
                                        {testDetailsData.data.data[0]?.name}
                                      </h6>
                                      <span className="test-count">
                                        {
                                          testDetailsData.data.data[0]
                                            .test_parameter?.length
                                        }
                                      </span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="test-main-body">
                                        <ol>
                                          {testDetailsData.data.data[0]
                                            .test_parameter?.length > 0 &&
                                            testDetailsData.data.data[0].test_parameter?.map(
                                              (data, index) => (
                                                <li key={index}>
                                                  {data?.name}
                                                </li>
                                              )
                                            )}
                                        </ol>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                ) : (
                                  <NoDataFoundComp />
                                )}
                              </Accordion>
                            </div>
                          </Tab.Pane> */}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <RadioTestDescriptionButton
                    descriptionId={test_id}
                    test={testDetailsData?.data?.data[0]}
                  />
                  <WhyBookwithUs />
                </div>
                <RadioRecommendedSection
                  descriptionId={test_id}
                  testData={diagnostic_test_list_response?.data?.data}
                  title="tests"
                />
              </>
            ) : (
              <div
                style={{
                  height: "73.4vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div className="empty-cart-image mb-3">
                  <img src={appLabCart} width="100%" alt="EmptyCart" />
                </div>
                <h5 style={{ color: "grey" }}>No Data Found</h5>
              </div>
            )}

            {cartDetailsResponse &&
              (cartDetailsResponse?.data?.response?.tests?.length > 0 ||
                cartDetailsResponse?.data?.response?.packages?.length > 0) && (
                <RadioFooter
                  labCartData={cartDetailsResponse?.data?.response}
                  tabkey={key}
                />
              )}
          </div>
        )}
      </div>
    </>
  );
};

export default RadioTestDescription;
