export const TOKEN = localStorage.getItem("ACCESS_TOKEN");

export const REQUESTOPTIONS = {
  method: "GET",
  headers: {
    Authorization: `${TOKEN}`,
  },
};

export const WHATSAPP_NUMBER = "8691054460"

export const IMAGE_HEALTH_URL = "https://medibhai.com/uploads/health_feed/"
// export const NEW_WEB_URL = "https://beta.medibhai.com/"
export const NEW_WEB_URL = process.env.REACT_APP_NEW_APP_API_URL;

//API'S=======================================================>

// DIAGNOSTICS----
export const DIAGNOSTIC_TEST_API = `diagnostic/get-tests`;
export const CATEGORY_TEST_API = `diagnostic/get-categories`;
export const HEALTH_PACKAGE_API = `diagnostic/get-packages`;
export const ADD_TO_CART_API = `diagnostic/diagnostic-cart`;
export const SEARCH_DIAGNOSTIC_TEST_API = `diagnostic/diagnostic-search`;
export const DIAGNOSTIC_CART_DETAILS_API = `diagnostic/cart-details`;
export const PATIENT_LIST_API = `diagnostic/fetch-members`;
export const GET_TIME_SLOTS_API = `diagnostic/fetch-time-slot`;
export const DIAGNOSTICS_ORDER_DETAILS_API = `diagnostic/place-order-details`;
export const SIGN_UP_API = `package/generate-web-package-registration-otp`;

// api/diagnostic/
export const PACKAGE_DETAIL_API = `${process.env.REACT_APP_API_URL}/diagnostics_api/package_details`;
export const TEST_BY_CATEGORY_API = `${process.env.REACT_APP_API_URL}/diagnostics_api/test_by_category`;
export const MY_ACCOUNT_API = `${process.env.REACT_APP_API_URL}/diagnostics_api/account_package`;
export const WALLET_API = `${process.env.REACT_APP_API_URL}/api/diagnostics/wallet`;
export const USER_PACKAGE_API = `${process.env.REACT_APP_API_URL}/api/home_index/get_user_package`;
export const PLACE_ORDER_DIAGNOSTIC_API = `${process.env.REACT_APP_API_URL}/api/diagnostics/place_Order`;
export const PREVIEW_ORDER_DIAGNOSTIC_API = `${process.env.REACT_APP_API_URL}/api/diagnostics/preview_order`;
export const TEST_DESCRIPTION_API = `${process.env.REACT_APP_API_URL}/api/diagnostics/test_detail?test_id=`;



//HOME API --------------
export const HOME_DASHBOARD_SCREEN_API = `${process.env.REACT_APP_API_URL}/claim_adminpanel/claim_api/dashboard`;
export const HOME_INDEX_API = `${process.env.REACT_APP_API_URL}/home_api/medibhai_app_index`;


//DENTAL CONSULTATION -----------------
export const DENTAL_CONSULTATION_API = `${process.env.REACT_APP_API_URL}/dental_api/consultation`;
export const DENTAL_TIME_SLOT_API = `${process.env.REACT_APP_API_URL}/diagnostics_api/time_slot`;
export const GET_AREA_API = `${process.env.REACT_APP_API_URL}/dental_api/get_city`;
export const BOOK_DENTAL_CONSULTATION_API = `${process.env.REACT_APP_API_URL}/dental_api/book`;


//MY ACCOUNT API-----------------
export const TERMS_CONDITION_API = `${process.env.REACT_APP_API_URL}/home_api/term_and_conditions`;
export const MY_ORDERS_API = `${process.env.REACT_APP_API_URL}/diagnostics_api/my_orders`;

// OPD -----------------------
export const DOCTOR_PROFILE_API = `${process.env.REACT_APP_API_URL}/doctor_api/profile`;
export const DOCTOR_VISIT_API = `${process.env.REACT_APP_API_URL}/doctor_api/visit`;
export const DOCTOR_DATES_API = `${process.env.REACT_APP_API_URL}/doctor_api/appointment_date`;
export const DOCTOR_TIMESLOT_API = `${process.env.REACT_APP_API_URL}/doctor_api/appointment_timeslot`;
export const DOCTOR_NEAR_ME_API = `${process.env.REACT_APP_API_URL}/doctor_api/near_me`;
export const DOCTOR_PREVIEW = `${process.env.REACT_APP_API_URL}/doctor_api/preview`;
export const DOCTOR_NOT_FOUND_API = `${process.env.REACT_APP_API_URL}/home_api/not_found`;
export const DOCTOR_PLACE_ORDER_API = `${process.env.REACT_APP_API_URL}/doctor_api/place_order`;

export const PACKAGES_API = `${process.env.REACT_APP_API_URL}/home_api/get_user_show_package`;
export const SAVE_PACKAGES_API = `${process.env.REACT_APP_API_URL}/tp-save-reimbursement-data`;


//MEDICINES -----------------------
export const UPLOAD_PRESCRIPTION__API = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/medicine_prescription_upload`;
export const SEARCH_MEDICINE_API = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/search_medicine`;
export const USER_ADDRESS_API = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/fetch_medicine_user_address`;
export const MEDICINE_PREVIEW_API = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/medicine_preview`;
export const MEDICINE_UPDATE = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/cart_manager_update`;
export const MEDICINE_CART_MANAGER_LIST = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/cart_manager_list/`;
export const MEDICINE_DELETE = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/cart_manager_delete/`
export const PATIENTS_MEMBERS_API = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/fetch_patient_member_details`
export const PATIENTS_MEMBER_DETAILS_API = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/patient_member_details`
export const MEDICINE_ORDER_API = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/medicine_order`
export const MEDICINE_PRESCRIPTION_UPLOAD_API = `${process.env.REACT_APP_API_URL}/api/api_medicine_1mg/medicine_prescription_upload`
export const AMBULANCE_REQUEST_API = `${process.env.REACT_APP_API_URL}/api/leads_request/ambulance_request`
export const SUPER_WATCH_API = `${process.env.REACT_APP_API_URL}/api/leads_request/boat_watch_request`
export const YOGA_MET_API = `${process.env.REACT_APP_API_URL}/lead_api/yoga_mat_request`

// NOTIFICATION API
export const NOTIFICATION_API = `${process.env.REACT_APP_API_URL}/notification_api/get_all_list`

// Reimbursement Status
export const REIMBURMENT_API = `${process.env.REACT_APP_API_URL}/home_api/get_user_reimbursement_data`


// ADDRESS APIS
export const ADD_ADDRESS = `${process.env.REACT_APP_API_URL}/medicine_api/add_user_address`


//TELECONSULTATION 
export const TELECONSULTATION_LIST_API = `${process.env.REACT_APP_API_URL}/teleconsultation_api/teleconsult_list`;
export const BOOK_TELECONSULTATION_API = `${process.env.REACT_APP_API_URL}/tp-book-teleconsultant`;
export const GET_TIME_SLOT_API = `${process.env.REACT_APP_API_URL}/api/teleconsult_api/get_time_slot`;

//DENTAL CONSULTATION

export const PAY_AT_HOME_API = `${process.env.REACT_APP_API_URL}/dental_api/pay_at_home`;

//Package Apis -------------------
export const TP_GET_IMBURSEMENTDATA_API = `${process.env.REACT_APP_API_URL}/tp-get-reimbursement-data`;
export const TP_PRO_FIT_PACKAGE_API = `${process.env.REACT_APP_API_URL}/tp-pro-fit-package`;
export const TP_BUY_CULT_PACKAGE_API = `${process.env.REACT_APP_API_URL}/tp-buy-cult-package`;


// OTHERS-----------------
export const STATE_CITY_LIST_API = `${process.env.REACT_APP_API_URL}/api/radiology/get_state_city_list`;
export const FIND_PINCODE_API = `${process.env.REACT_APP_API_URL}/medicine_api/find_pincode`;
export const MEDICAL_LOAN_API = `${process.env.REACT_APP_API_URL}/claim_adminpanel/api/api_medicine/medical_loan`;
export const WELLNESS_REQUEST_API = `${process.env.REACT_APP_API_URL}/wellness_api/request_support`;
export const UNIVERSAL_SEARCH_API = `${process.env.REACT_APP_API_URL}/universal_search_api`;
export const PINCODE_STATE_CITY_API = `${process.env.REACT_APP_API_URL}/medicine_api/get_city_from_pincode`;
export const HEALTH_FEED_BLOG_API = `${process.env.REACT_APP_API_URL}/home_api/health_blog`;


//Login
export const MATCH_OTP_API = `${process.env.REACT_APP_API_URL}/claim_adminpanel/claim_api/match_otp`
export const DISPATCH_OTP_API = `${process.env.REACT_APP_API_URL}/claim_adminpanel/claim_api/send_otp`

//ReimburseMent Api
export const SAVE_TP_IMBURSEDATA = `${process.env.REACT_APP_API_URL}/tp-save-reimbursement-data`



export const IMG_PATH = "https://medibhai.com/uploads/department/";
export const NO_PACKAGE_GIF = "https://medibhai.com/assets/images/medibhai_images/No_Package.gif"


export const BROKER_EMEDLIFE = 24;
export const BROKER_ODIN = 25;
export const BROKER_ICARE = 43;


// NEW APIS FOR MEDICINE
export const GET_MEDICINE_PINCODE_API = `${process.env.REACT_APP_NEW_APP_API_URL}/medicine/city/serviceable?pincode=`
export const GET_MEDICINE_CATEGORY_API = `${process.env.REACT_APP_NEW_APP_API_URL}/medicine/medicine-category-details`


// EXPLORE
export const EXPLORE_API = `${process.env.REACT_APP_API_URL}/home_api/package_faq`


export const DEFAULT_CAPTCHA_KEY = "6LeY0xIqAAAAAAheR6fqf1ghXVHLtAb6BOf5a6p3" //FOR UAT