import React from 'react'
import { appOPDIcon, headerLogo } from '../../images'
import useLocationState from '../Medicine/hooks/useLocationState'
import { MdSchool } from 'react-icons/md'
import { FaBuilding } from 'react-icons/fa'

const DoctorOrder = () => {
    const Data = useLocationState()
    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Book Online Consultation</p>
                    </div>
                </div>
            </div>

            <div className="doctor-detail-repo-corner">
                <div className="doctor-data-repo">
                    <div className="doctor-tepo-image">
                        <img src={appOPDIcon} alt="" />
                        <div className="dot"></div>
                    </div>
                    <div className="doctor-data-box-tepo">
                        <h5 style={{ marginBottom: '0px' }}>{Data?.name}</h5>
                        <p style={{ fontSize: "11px", color: "#757575", marginBottom: '5px' }}>{Data?.category}</p>
                        <p className="doctor-category"><MdSchool /> {Data?.education}</p>
                        <p className="doctor-category"><FaBuilding /> {Data?.hospital_name}, {Data?.city}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DoctorOrder