import React, { useEffect, useState } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// import '../../style/orderDetails.css';
import { useDispatch, useSelector } from "react-redux";
import MediLoader from "../../Medicine/animation/MediLoader";
import AppHeader from "../../Medicine/Components/Medicine_Header";
import { IoIosSearch } from "react-icons/io";
import HealthRecordCard from "../Components/HealthRecordCard";
import { fetchHealthRecords , fetchLabReports } from "../../../Redux/actions/GetApiCall";

const HealthRecords = () => {
  const dispatch = useDispatch();
  const healthRecordResponseData = useSelector((state) => state.health_record_response);
  const labReportResponseData = useSelector((state) => state.lap_report_response);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData , setFilteredData] = useState([])

  const [filteredHealthRecords, setFilteredHealthRecords] = useState([]);
  const [filteredLabReports, setFilteredLabReports] = useState([]);

  const [key, setKey] = useState(0);

  const height = "60vh"

  useEffect(() => {
    dispatch(fetchHealthRecords());
    dispatch(fetchLabReports());
  }, []);

  const handleTabSelect = (selectedKey) => {
    setKey(selectedKey);
  };

  // useEffect(() => {
  //   // Filter health records
  //   const filterHealthRecords = () => {
  //     if (healthRecordResponseData?.data?.data) {
  //       return healthRecordResponseData.data.data.filter(record =>
  //         record.patient_name.toLowerCase().includes(searchQuery.toLowerCase()) || 
  //         record.created_on.toLowerCase().includes(searchQuery.toLowerCase())
  //       );
  //     } else {
  //       return [];
  //     }
  //   };
  
  //   // Filter lab reports
  //   const filterLabReports = () => {
  //     if (labReportResponseData?.data?.response) {
  //       return labReportResponseData.data.response.filter(record =>
  //         record.patient_name.toLowerCase().includes(searchQuery.toLowerCase()) || 
  //         record.created_on.toLowerCase().includes(searchQuery.toLowerCase())
  //       );
  //     } else {
  //       return [];
  //     }
  //   };
  
  //   let filteredRecords = [];
  //   // Handle key == 0 (Health Reports)
  //   if (key === 0) {
  //     filteredRecords = filterHealthRecords();
  //   }
  //   // Handle key != 0 (Lab Reports)
  //   else {
  //     filteredRecords = filterLabReports();
  //   }
  
  //   // Update filteredData
  //   setFilteredData(filteredRecords);
  
  // }, [key, searchQuery, healthRecordResponseData, labReportResponseData]);
  


  useEffect(() => {
    // Filter health records
    const filterHealthRecords = () => {
      if (healthRecordResponseData?.data?.data) {
        return healthRecordResponseData.data.data.filter(record =>
          record.patient_name.toLowerCase().includes(searchQuery.toLowerCase()) || 
          record.created_on.toLowerCase().includes(searchQuery.toLowerCase())
        );
      } else {
        return [];
      }
    };

    // Filter lab reports
    const filterLabReports = () => {
      if (labReportResponseData?.data?.response) {
        return labReportResponseData.data.response.filter(record =>
          record.patient_name.toLowerCase().includes(searchQuery.toLowerCase()) || 
          record.created_on.toLowerCase().includes(searchQuery.toLowerCase())
        );
      } else {
        return [];
      }
    };

    // Filter data based on the key
    if (key === 0) {
      const healthRecords = filterHealthRecords();
      setFilteredHealthRecords(healthRecords);
    } else {
      const labReports = filterLabReports();
      setFilteredLabReports(labReports);
    }

  }, [key, searchQuery, healthRecordResponseData, labReportResponseData]);


  return (
    <div className="home-main-cover-wrapper">
      <div className="home-app-wrapper">
        <div className="home-app-main-cover">
          <div className="home-review-banner mb-4">
            <AppHeader Title="Health Records" />
            <div className="health-record-main-cover-wrapper">
              <div className="tab-main-cover-wrapper">
                <Tab.Container
                  id="left-tabs-example-repo-cont"
                  activeKey={key}
                  onSelect={handleTabSelect}
                >
                  <Row>
                    <Col lg={12} className="mb-2 tab-cover order-details-tab">
                      <Swiper
                        spaceBetween={10}
                        slidesPerView={2.5}
                        pagination={{ clickable: true }}
                        modules={[Navigation]}
                        className="order-tab-swiper health-records-swiper  d-flex justify-content-around"
                      >
                        <SwiperSlide className="health-records-swiper ">
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey={0}>
                                Prescriptions
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </SwiperSlide>
                        <SwiperSlide>
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey={1}>Lab Reports</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </SwiperSlide>
                      </Swiper>
                    </Col>
                    <div className="app-searh-section">
                      <div className="inputWithIcon health-record-icon">
                        <input type="text" placeholder="Search" 
                            value={searchQuery} 
                            onChange={(e) => setSearchQuery(e.target.value)} 
                        />
                        <button>
                          <IoIosSearch />
                        </button>
                      </div>
                    </div>

                    <Col lg={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey={0}>
                          <div className="medicine-cover-data-wrapper-boxs-cover">
                            {healthRecordResponseData?.status === "loading" ? (
                              <MediLoader height={height} />
                            ) : (
                              <div className="health-records-prescriptions-cover-wrapper">
                                {filteredHealthRecords?.length > 0 ? (
                                  <div className="health-records-card-list">
                                    {filteredHealthRecords.map((record_data) => (
                                      <HealthRecordCard
                                        recordkey={key}
                                        btnText="Download Prescription"
                                        record={record_data}
                                        key={record_data.id} // Ensure a unique key
                                      />
                                    ))}
                                  </div>
                                ) : (
                                  <h6 className="no-data-text">No data Found</h6>
                                )}
                              </div>
                            )}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey={1}>
                          <div className="medicine-cover-data-wrapper-boxs-cover">
                            {labReportResponseData?.status === "loading" ? (
                              <MediLoader height={height} />
                            ) : (
                              <div className="health-records-prescriptions-cover-wrapper">
                                {filteredLabReports?.length > 0 ? (
                                  <div className="health-records-card-list">
                                    {filteredLabReports.map((record_data) => (
                                      <HealthRecordCard
                                        recordkey={key}
                                        btnText="Download Prescription"
                                        record={record_data}
                                        key={record_data.id} // Ensure a unique key
                                      />
                                    ))}
                                  </div>
                                ) : (
                                  <h6 className="no-data-text">No data Found</h6>
                                )}
                              </div>
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthRecords;
