import React from 'react'
import { appEmptyCart } from '../../../images'
import { NavLink } from 'react-router-dom'

const EmptyCart = () => {
    return (
        <>
            <div className="medicines-empty-cart-cover">
                <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
                    <div className="empty-cart-image">
                        <img
                            src={appEmptyCart}
                            width="100%"
                            alt="EmptyCart"
                        />
                    </div>
                    <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                        <p>Your Product Cart is empty</p>
                        
                            <NavLink to="/buyMedicines">
                                <button>
                                    <p>ADD MEDICINES</p>
                                </button>
                            </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmptyCart