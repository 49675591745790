// src/hooks/useFetchMedicine.js
import { useQuery } from "@tanstack/react-query";

const pincode = localStorage.getItem("pincode");
const AppToken = localStorage.getItem("APP_TOKEN"); // Assuming the token is stored in localStorage

const API_BASE_URL = process.env.REACT_APP_NEW_APP_API_URL;
// const API_BASE_URL = "https://beta.medibhai.com/api";

// Fetch medicine categories with token in headers
const fetchMedicine = async () => {
  const response = await fetch(
    `${API_BASE_URL}/medicine/medicine-category-details`,
    {
      method: "GET",
      headers: {
        Authorization: AppToken, // Include token in Authorization header
        "Content-Type": "application/json", // Set Content-Type header
      },
    }
  );

  console.log(response);

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const fetchHome = async () => {
  const response = await fetch(`${API_BASE_URL}/employee-home-data`, {
    method: "GET",
    headers: {
      Authorization: AppToken, // Include token in Authorization header
      "Content-Type": "application/json", // Set Content-Type header
    },
  });
  console.log(response);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

// Fetch medicine list with token in headers
const fetchMedicineList = async (medicineName) => {
  const response = await fetch(
    `${API_BASE_URL}/medicine/medicine-list?pincode=${pincode}&medicine_name=${medicineName}`,
    {
      method: "GET",
      headers: {
        Authorization: AppToken, // Include token in Authorization header
        "Content-Type": "application/json", // Set Content-Type header
      },
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

// Hook to fetch medicine categories
const useFetchMedicine = () => {
  return useQuery({
    queryKey: ["fetchMedicine"],
    queryFn: fetchMedicine,
    staleTime: 86400000, // 24 hours
    cacheTime: 86400000, // 24 hours
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

const useFetchHome = () => {
  return useQuery({
    queryKey: ["fetchHome"],
    queryFn: fetchHome,
    staleTime: 86400000, // 24 hours
    cacheTime: 86400000, // 24 hours
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

// Hook to fetch medicine list by name
const useFetchMedicineList = (medicineName) => {
  return useQuery({
    queryKey: ["fetchMedicineList", medicineName],
    queryFn: () => fetchMedicineList(medicineName),
    staleTime: 86400000, // 24 hours
    cacheTime: 86400000, // 24 hours
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: !!medicineName, // Only run the query if medicineName is provided
  });
};

export { useFetchMedicine, useFetchMedicineList, useFetchHome };
