import React, { useEffect } from "react";
import "../Medicine/Medicine_Style/cart.css";
import { Need_Help_Image } from "../Medicine/Images";
import { appCommonlyBookTest, appLabCart } from "../../images";
import { FaRegTrashCan } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import Need_Help from "../Medicine/Components/Need_Help";
import { savings_image } from "../Medicine/Images";
import useRadioHandleCart from "../LabTestPages/hooks/useRadioHandleCart";
import { useDispatch, useSelector } from "react-redux";
import { fetchRadioCartDetails } from "../../Redux/actions/GetApiCall";
import Medicine_Header from "../Medicine/Components/Medicine_Header";
import RadioFooter from "./RadioFooter";

const RadiologyCart = () => {
    const { handleRemoveCart } = useRadioHandleCart();
    const dispatch = useDispatch();
    const cartDetailsResponse = useSelector(
        (state) => state.radio_cart_details
    );

    const labCartData = cartDetailsResponse && cartDetailsResponse?.data?.response
    console.log(labCartData?.cart_count)

    useEffect(() => {
        dispatch(fetchRadioCartDetails());
    }, [dispatch]);
    const Title = "Cart";
    const key = "diagnostics"


    return (
        <div className="home-review-banner">
            <Medicine_Header
                Title={Title}
                labCartCount={labCartData?.cart_count}
                prescrip={key}
            />
            <div className="app-main-cart-cover-wrapper">
                <div className="tab-main-cover-wrapper">
                    <div className="diagnostics-cart-main-cover-wrapper px-2 mt-2">
                        <div className="lab-test-tab-content-box">
                            <div className="lab-Cart-data-main-wrapper">
                                {labCartData?.tests?.length > 0 || labCartData?.packages?.length > 0 ? (
                                    <div className="added-test-cart-main-wrapper my-3" style={{ marginBottom: '2rem' }}>
                                        <div className="added-test-box">
                                            {labCartData?.tests?.length > 0 &&
                                                labCartData?.tests?.map((cartDat) => {
                                                    return (
                                                        <div className="test-cart-box mb-3">
                                                            <div className="cart-test-header d-flex ">
                                                                <div className="common-test-image">
                                                                    <img
                                                                        src={appCommonlyBookTest}
                                                                        width="100%"
                                                                        alt="Book-test"
                                                                    />
                                                                </div>
                                                                <div className="lab-cart-heading mx-2">
                                                                    <h6>{cartDat?.name}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="added-cart-details d-flex justify-content-between">
                                                                <div className="health-pack-price mb-0">
                                                                    <h6>MB Price {cartDat?.medibhai_price}</h6>
                                                                    <p>₹ {cartDat?.mrp}</p>
                                                                    <span>{cartDat?.discount_rate}% OFF</span>
                                                                </div>
                                                                <div
                                                                    className="remove-cart-icon"
                                                                    onClick={() => handleRemoveCart(cartDat)}
                                                                >
                                                                    <FaRegTrashCan />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            {labCartData?.packages?.length > 0 &&
                                                labCartData?.packages?.map((cartDat) => {
                                                    return (
                                                        <div className="test-cart-box mb-3">
                                                            <div className="cart-test-header d-flex ">
                                                                <div className="common-test-image">
                                                                    <img
                                                                        src={appCommonlyBookTest}
                                                                        width="100%"
                                                                        alt="Book-test"
                                                                    />
                                                                </div>
                                                                <div className="lab-cart-heading mx-2">
                                                                    <h6>{cartDat?.name}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="added-cart-details d-flex justify-content-between">
                                                                <div className="health-pack-price mb-0">
                                                                    <h6>MB Price {cartDat?.medibhai_price}</h6>
                                                                    <p>₹ {cartDat?.mrp}</p>
                                                                    <span>{cartDat?.discount_rate}% OFF</span>
                                                                </div>
                                                                <div
                                                                    className="remove-cart-btn-icon"
                                                                    onClick={() => handleRemoveCart(cartDat)}
                                                                >
                                                                    <FaRegTrashCan />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <div className="add-more-items-box-cover">
                                            <a className href="/radio-home">
                                                <h6>
                                                    Add More Items <FiPlusCircle />
                                                </h6>
                                            </a>
                                        </div>

                                        <div className="payment-repo-cover-box mx-1">
                                            <div className="payment-text-repo">
                                                <p>Payment Summary</p>
                                            </div>
                                            <div className="payment-summary-box-repo">
                                                {labCartData && labCartData.calculation
                                                    .filter(
                                                        (lab_cal_Data) =>
                                                            lab_cal_Data.key !== "Total payable price" &&
                                                            lab_cal_Data.key !== "Total saving"
                                                    )
                                                    .map((filteredData) => (
                                                        <div className="price-payment-sum-repo" key={filteredData.key}>
                                                            <p>{filteredData.key}</p>
                                                            <p>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0 }).format(filteredData.value)}</p>
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className="fin-text-repo">
                                                {labCartData && labCartData.calculation
                                                    .filter(
                                                        (lab_cal_Data) =>
                                                            lab_cal_Data.key === "Total payable price" ||
                                                            lab_cal_Data.key === "Total saving"
                                                    )
                                                    .map((filteredData) => (
                                                        <div
                                                            className={`price-payment-sum-repo ${filteredData.key === "Total payable price" ? "total-pay-text" : "total-save-text"
                                                                }`}
                                                            key={filteredData.key}
                                                        >

                                                            <p>
                                                                {filteredData.key == "Total saving" && (
                                                                    <img src={savings_image}></img>
                                                                )}
                                                                {filteredData.key}</p>
                                                            <p>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0 }).format(filteredData.value)}</p>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <Need_Help type="diagnostic" />
                                    </div>
                                ) : (
                                    <div className="medicines-empty-cart-cover">
                                        <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
                                            <div className="empty-cart-image">
                                                <img src={appLabCart} width="100%" alt="EmptyCart" />
                                            </div>
                                            <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                                                <p>Your Cart is empty</p>
                                                <Link to="/radio-home">
                                                    <button>
                                                        <p>ADD TESTS</p>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {cartDetailsResponse && cartDetailsResponse?.data?.response?.cart_count > 0 && (
                <RadioFooter
                    labCartData={cartDetailsResponse && cartDetailsResponse?.data?.response}
                />
            )}
        </div>
    );
};

export default RadiologyCart;
