import React from 'react'
import { headerLogo } from '../../images'
import { HospitalCategories } from '../../data'

const Departments = () => {
    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Departments</p>
                    </div>
                </div>
            </div>
            <div className="department-repo-clover-wrapper">
                {HospitalCategories.map((item) => (
                    <div className='depart-clover-wrap'
                    >
                        <div className="icon-path-rpeo" style={{ backgroundColor: item.boxColor }}>
                            {item?.icon}
                        </div>
                        <p
                            style={{ color: item.boxColor }}> {item.name.split(' ').map((word, index) => (
                                <span key={index}>
                                    {word}
                                    {index < item.name.split(' ').length - 1 && <br />} {/* Add line break except after the last word */}
                                </span>
                            ))}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Departments