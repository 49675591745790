import React from 'react'
import Order_Card from './Order_Card';
import '../../style/orderDetails.css'
import { NavLink } from 'react-router-dom';
import { IoHome } from 'react-icons/io5';

const SingleOrderDetails = () => {
    const V2HREF = localStorage.getItem("V2HREF")
    return (
        <div className="home-review-banner">
            <div className="home-app-header order-details-repo-data">

                <div className="header-logo ">
                    <div className="user-name text-center">
                        <p>Order Details</p>
                    </div>
                    {!V2HREF && V2HREF === null && (
                        <div className="order-home-repo">
                            <NavLink to={'/buyMedicines'}>
                                <IoHome />
                            </NavLink>
                        </div>
                    )}
                </div>
            </div>
            <div className="medicine-order-details-cover">
                <div className="heading-text-date">
                    <h6>Today</h6>
                </div>
                <Order_Card />
            </div>
        </div>
    )
}

export default SingleOrderDetails