import React, { useEffect, useState } from "react";
import { headerLogo } from "../../images";
import useLocationState from "../Medicine/hooks/useLocationState";
import FitnessModal from "./FitnessModal";
import { useNavigate } from "react-router-dom";

const FitnessPro = (props) => {
  const Data = useLocationState();
  const navigate = useNavigate();

  const handleBuyCultPackage = (package_data) => {
    navigate("/fitness-product", { state: package_data })
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p style={{ marginLeft: '10px' }}>{Data?.title}</p>
            </div>
          </div>
        </div>
        <div className="opd-reimbursement-main-wrapper-cover">
          <div className="book-now-form-main-cover">
            <div className="reimburse-header-title fitness-title p-3 pb-0">
              <h5>{Data?.description}</h5>
            </div>

            <div className="fitness-main-cover-wrapper p-3 pb-0">
              <div className="fitness-cards-cover-wrapper">
                {Data?.fitness_pro?.map((package_data) => {
                  return (
                    <div
                      className="fitness-card-box"
                      key={package_data?.type}
                      onClick={() => handleBuyCultPackage(package_data)}
                    >
                      <img src={package_data?.banner} alt={package_data?.type} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FitnessPro;
