import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicineCartList, fetchMedicinelist } from '../../../Redux/actions/GetApiCall';
import '../Medicine_Style/categories.css';
import MediLoader from '../animation/MediLoader';
import Medicine_Card from './Medicine_Card';
import ToastBox from '../animation/ToastBox';
import ErrorFull from '../../../Animation/ErrorFull';

const Medicine_Category = ({ textData, setCartListData }) => {
    const dispatch = useDispatch();
    const [loaderStatus, setLoaderStatus] = useState(true); // Initialize loader to true on page load
    const { loader: mediloader, data: mediData, error: mediError } = useSelector(state => state.medicine_list);
    
    // Function to check if the page is reloaded
    const isPageReloaded = () => {
        return window.performance && window.performance.navigation.type === 1;
    };

    // Fetch cart list on page load/reload and manage loader visibility based on Redux state
    useEffect(() => {
        if (isPageReloaded()) {
            setLoaderStatus(true);
        }
        dispatch(fetchMedicineCartList());
    }, [dispatch]);

    // Fetch medicine list when textData changes
    useEffect(() => {
        if (textData !== '') {
            setLoaderStatus(true); 
            dispatch(fetchMedicinelist(textData));
        }
    }, [dispatch, textData]);

    // Update loader status based on Redux state
    useEffect(() => {
        if (!mediloader && (mediData || mediError)) {
            setLoaderStatus(false); // Hide loader when API call completes, regardless of success or error
        }
    }, [mediloader, mediData, mediError]);

    const height = "75vh";
    const corner = "category";

    return (
        <div className="category-con-wrapper-box">
            {mediError ? (
                <ErrorFull errorMessage={mediError} />
            ) : loaderStatus ? (
                <MediLoader height={height} />
            ) : (
                <div className="corner-data-container">
                    <Medicine_Card
                        setCartListData={setCartListData}
                        setLoaderStatus={setLoaderStatus}
                        mediData={mediData}
                        corner={corner}
                        inputPara={textData}
                    />
                </div>
            )}
        </div>
    );
};

export default Medicine_Category;
