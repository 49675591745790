import React, { useCallback, useEffect, useRef, useState } from 'react';
import Medicine_Header from '../Components/Medicine_Header';
import useLocationState from '../hooks/useLocationState';
import { no_medicine_found, no_preview } from '../Images';
import '../Medicine_Style/search_result.css';
import { FaAngleRight } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicinelist } from '../../../Redux/actions/GetApiCall';
import { debounce } from 'lodash';
import MediLoader from '../animation/MediLoader';
import { useNavigate } from 'react-router-dom';

const Medicine_Search_Result = () => {
    const state = useLocationState();
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const { data: SearchData, error: SearchError, loader: SearchLoader } = useSelector(state => state?.medicine_list);
    const navigate = useNavigate();

    const debounceFetchMedicine = useCallback(
        debounce((value) => {
            if (value.trim() !== '') {
                dispatch(fetchMedicinelist(value));
            } else {
                setSearchResult([]);
            }
        }, 1000),
        [dispatch]
    );

    useEffect(() => {
        if (inputValue !== "") {
            debounceFetchMedicine(inputValue);
        }
        return () => {
            debounceFetchMedicine.cancel();
        };
    }, [inputValue, debounceFetchMedicine]);

    useEffect(() => {
        setSearchResult(SearchData);
        if (inputValue === "") {
            setSearchResult([]);
        }
    }, [SearchData, inputValue]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (value.trim() === '') {
            setSearchResult([]);
        }
    };

    const height = "75%";

    const getHighlightedText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? <strong key={index} style={{ color: 'black' }}>{part}</strong> : part
        );
    };

    const handleSearch = (name) => {
        setInputValue('');
        const data = [{
            Title: "Product List",
            inputData: name
        }];

        navigate('/health-corner', { state: data });
    };

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    // Placeholder data
    const PlaceorderData = [
        { category: "Medicine" },
        { category: "Health Care" },
        { category: "Skin Care" },
        { category: "Hair Care" },
        { category: "Homeopathy Products" },
        { category: "Ayurveda Products" },
        { category: "Fitness & Supplements" },
        { category: "Diabetic Care" },
        { category: "Sexual Wellness" }
    ];

    // Change placeholder text every second
    useEffect(() => {
        const interval = setInterval(() => {
            setPlaceholderIndex((prevIndex) => (prevIndex + 1) % PlaceorderData.length);
        }, 1500);
        return () => clearInterval(interval);
    }, [PlaceorderData.length]);

    return (
        <div className="home-review-banner">
            <Medicine_Header Title={state?.Title} state={state} />
            <div className='search-data-input-box'>
                <input
                    type="text"
                    placeholder={`Search for ${PlaceorderData[placeholderIndex].category}...`}
                    onChange={(e) => handleInputChange(e)}
                    ref={inputRef}
                    className="placeholder-animate"
                />
            </div>

            {
                SearchLoader ? (
                    <MediLoader height={height} />
                ) : (
                    <div className="data-repo-cover-medi-search">
                        {searchResult && searchResult?.data?.filter(item => item.search_term !== "undefined").length > 0 ? (
                            searchResult.data
                                .filter(item => item.search_term !== "undefined") // Filter out items with search_term as "undefined"
                                .map((item) => (
                                    <div className="search-result-data-box-cover" key={item?.sku_id} onClick={() => handleSearch(item?.name)}>
                                        <div className="search-data-box-cont">
                                            <img
                                                src={item?.banner_url ? item?.banner_url : no_preview}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = no_preview;
                                                }}
                                                alt="product-icon"
                                            />
                                            <p className='ellipsis-text'>{getHighlightedText(item?.name, inputValue)}</p>
                                        </div>
                                        <div className="search-left-arrow-box">
                                            <FaAngleRight />
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <p className='no-medicine-found'>
                                <img src={no_medicine_found} alt="" />
                            </p>
                        )}
                    </div>
                )
            }
        </div>
    );
};

export default Medicine_Search_Result;
