import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMedicineAddress } from "../../Redux/actions/GetApiCall";

const RadioFooter = ({ tabkey, AddressId, handleOrderMedicine, handleDiagnosticOrder, labCartData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const medicartData = useSelector(state => state.radio_cart_add);
    // const location = useLocation();
    const WebLink = window.location.pathname;
    const [activeTabKey, setActiveTabKey] = useState(tabkey);

    const totalAmount = labCartData?.calculation?.find((item) => item?.key === "Total payable price")?.value;;
    const totalItems = labCartData?.cart_count || labCartData?.cart_count || 0;

    useEffect(() => {
        if (WebLink === "/radiology-cart") {
            dispatch(fetchMedicineAddress());
        }
    }, [WebLink]);

    const handleCartLink = () => {
        if (WebLink === "/radiology-cart") {
            navigate("/radio-patients")
        }
        else if (WebLink === "/radio-patients") {
            navigate("/radio-date-timeslot")
        }
        else if (WebLink === "/radio-place-order") {
            handleDiagnosticOrder()
        }
        else {
            navigate("/radiology-cart", { state: activeTabKey });
        }
    };

    return (
        <div className="footer-repo-head-boxs sticky-lg-bottom sticky-md-bottom sticky-bottom">
            <div className="product-price-repo-store">
                <p>
                    Total <span>₹ {totalAmount?.toLocaleString('en-IN')}</span>
                </p>
                <span className="item-qty">{totalItems} Item(s)</span>
            </div>
            <div className="product-btn-box-store">
                <button
                    className="repo-cont-btn"
                    onClick={() => {
                        if (WebLink === "/place-order") {
                            if (activeTabKey === "medicines") {
                                handleOrderMedicine();
                            } else {
                                handleDiagnosticOrder();
                            }
                        } else {
                            handleCartLink();
                        }
                    }}
                >
                    {WebLink === "/place-order" ? "Place Order" : "CONTINUE"}
                </button>
            </div>
        </div>
    );
};

export default RadioFooter;
