import { headerLogo } from "../../../images";

const SymptomsCard = ({ data, handleBooking }) => {
  return (
    <div
      className="symptoms-card mb-3"
      onClick={() => handleBooking({ ...data, type: "symptoms" })}
    >
      <div className="symptoms-img-box">
        <img
          src={data.file_name ? data.file_name : headerLogo}
          width="100%"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = headerLogo;
          }}
          alt="review"
        />
      </div>
      <p>{data?.name}</p>
    </div>
  );
};

export default SymptomsCard;
