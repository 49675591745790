import React from "react";
import Loader from "../../Animation/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { NavLink } from "react-router-dom";
import RadioPackageCard from "./RadioPackageCard";

const RadioPackages = ({ packageData }) => {
  return (
    <div className="health-packages-main-cover-wrapper">
      {packageData?.data?.length > 0 && (
        <div className="health-package-wrapper">
          <div className="book-test">
            <h6>Health Packages</h6>
            <NavLink to="/radio-packages">
              <p>View All</p>
            </NavLink>
          </div>
          {packageData.data.length > 0 && (
              <div className="health-package-box-cover">
                <Swiper
                  spaceBetween={20}
                  slidesPerView={1.4}
                  loop="true"
                  modules={[Autoplay]}
                  pagination={{ clickable: true }}
                  // autoplay={{ delay: 2500, disableoninteraction: false, }}
                >
                  <div className="swiper-wrapper">
                    {packageData.data?.length > 0 &&
                      packageData.data.map((elem) => (
                        <SwiperSlide key={elem.id}>
                          <RadioPackageCard data={elem} />
                        </SwiperSlide>
                      ))}
                  </div>
                </Swiper>
              </div>)}
        </div>
      )}
    </div>
  );
};

export default RadioPackages;
