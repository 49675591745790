import React, { useEffect, useState } from 'react'
import { headerLogo } from '../../images'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO, format } from 'date-fns';
import { PiUploadBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { Toaster, toast } from "react-hot-toast";
import { FaRegCircleXmark } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReimbursementFormData } from '../../Redux/actions/GetApiCall';
import useLocationState from '../Medicine/hooks/useLocationState';
import { saveReiumbursementApi } from '../../Redux/actions/PostApiCall';
import SuccessFull from '../../Animation/SuccessFull';


const ReimbursementForm = () => {
    const [imburseData, setImburseData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const PackageData = useLocationState();
    const PackUserData = useSelector(state => state.reiumbursement_form_Details);
    const ReiumbersmentResponse = useSelector(state => state.save_reiumbursement_form_Details);



    useEffect(() => {
        if (PackageData)
            dispatch(fetchReimbursementFormData(PackageData?.package_id, PackageData?.type))
    }, [])


    const [isSubmitting, setSubmitting] = useState(false)
    const [dob, setDob] = useState([]);
    const [files, setFiles] = useState({
        Invoice: [],
        Prescription: [],
        BankDetails: [],
        OtherDoucments: [],
    });



    const {
        handleSubmit,
        control,
        register,
        setValue,
        watch,
        clearErrors,
        formState: { errors },
    } = useForm();

    const [encodedFiles, setEncodedFiles] = useState([]);

    const documentTypes = Object.keys(files);

    const reimburseUserName = watch('name');
    const reimburseAmount = watch('amount');
    const remainingAmount = PackUserData?.data?.data?.remaining_amount || 0;
    const isAmountExceeded = reimburseAmount > remainingAmount;

    useEffect(() => {
        if (reimburseAmount > remainingAmount) {
            setValue("amount", reimburseAmount, { shouldValidate: true });
        } else {
            clearErrors("amount");
        }
    }, [reimburseAmount, remainingAmount, setValue.clearErrors]);

    const docTypes = [
        {
            Invoice: "Invoice/Receipt",
            Prescription: "Prescription",
            BankDetails: "Bank Details",
            OtherDoucments: "Other Doucments",
        },
    ];

    useEffect(() => {
        const readFiles = () => {
            const documentDataArray = [];

            Object.entries(files).forEach(([documentType, fileInputs]) => {
                if (fileInputs.length > 0) {
                    Array.from(fileInputs).forEach((file) => {
                        const documentData = {
                            file_type: docTypes[0] ? docTypes[0][documentType] : "",
                            file_name: file.name,
                            file_path: file,
                        };
                        documentDataArray.push(documentData);
                    });
                }
            });

            // Only update state if the data has actually changed
            if (JSON.stringify(encodedFiles) !== JSON.stringify(documentDataArray)) {
                setEncodedFiles(documentDataArray);
            }
        };

        readFiles();
    }, [files, docTypes]);


    const formatDate = (date) => {
        if (!date) return '';
        const formattedDate = format(date, 'yyyy-MM-dd');
        setDob(formattedDate)
        return formattedDate;
    };
    const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
    const handleFileChange = (e, documentType) => {
        const newFiles = Array.from(e.target.files);
        const invalidFiles = newFiles.filter(file => !allowedExtensions.includes(file.type));

        if (invalidFiles.length > 0) {
            toast.error('Invalid file type. Please upload jpg, jpeg, png, or pdf files only.');
            return; // Prevent file upload
        }
        setFiles((prevFiles) => {
            // Filter out files with the same name
            const uniqueNewFiles = newFiles.filter(
                (newFile) =>
                    !prevFiles[documentType].find(
                        (prevFile) => prevFile.name === newFile.name
                    )
            );
            return {
                ...prevFiles,
                [documentType]: [...prevFiles[documentType], ...uniqueNewFiles],
            };
        });
    };

    const handleFileDelete = (e, documentType, file, index) => {
        e.preventDefault();
        const updatedDoc = files[documentType].filter(
            (myfile) => myfile.name !== file.name
        );
        setFiles((prevFiles) => ({
            ...prevFiles,
            [documentType]: updatedDoc,
        }));
    };


    const ReimburseMentSubmission = (data) => {
        console.log(data)
        let selectedValue;
        if (typeof data.name === 'string') {
            selectedValue = { name: data.name, member_id: null };
            console.log(selectedValue)
        } else {
            // If it's from the select, parse it as JSON
            selectedValue = JSON.parse(data.name);
        }
        data.files = encodedFiles
        if (files?.Invoice?.length == 0) {
            toast.error('Please Upload Invoice / Receipt');
            return;
        }
        else if (files?.Prescription?.length == 0) {
            toast.error('Please Upload Prescription');
            return;
        }
        else if (files?.BankDetails?.length == 0) {
            toast.error('Please Upload Bank Details');
            return;
        }
        else {
            setSubmitting(true)
            const formData = new FormData();
            formData.append("package_id", PackageData?.package_id);
            formData.append("reimbursement_type", PackageData?.type);
            formData.append("reimbursement_amount", data.amount);
            formData.append("member_id", selectedValue.member_id || "");
            formData.append("name", selectedValue.name);
            formData.append("relation", data.relation);
            formData.append("date_of_birth", PackUserData?.data?.data?.family_list?.length > 0 && PackUserData?.data?.data?.is_select_show !== 0 ? data?.start_date : dob);
            formData.append("gender", data.gender);
            data.files.forEach(file => {
                if (file.file_type && file.file_path) {
                    formData.append("document_type[]", file.file_type);
                    formData.append("document[]", file.file_path);
                }
            });
            dispatch(saveReiumbursementApi(formData))
        }

    }

    useEffect(() => {
        if (ReiumbersmentResponse?.data?.success === true) {
            setModalShow(true);
            setSuccessModal(true);
            setTimeout(() => {
                setModalShow(false);
                navigate("/reimbursment-details", { state: PackageData?.package_id })
                window.location.reload();
            }, 2500);
        }

        else if (ReiumbersmentResponse?.status === "failure") {
            setModalShow(true);
            setSuccessModal(true);
            setTimeout(() => {
                setModalShow(false);
                // navigate("/reimbursment-details", { state: PackageData?.package_id })
                window.location.reload();
            }, 2500);
        }
    }, [ReiumbersmentResponse])
    const [selectedFamilyMember, setSelectedFamilyMember] = useState(null);

    const isButtonDisabled =
        reimburseUserName === "" ||
        (files.length === 0 &&
            (!files.Invoice || files.Invoice.length === 0) &&
            (!files.BankDetails || files.BankDetails.length === 0) &&
            (!files.Prescription || files.Prescription.length === 0)) ||
        reimburseAmount === "" ||
        reimburseAmount > imburseData?.pending_amount || isAmountExceeded;



    return (
        <>
            <div className="home-review-banner">
                <div className="home-app-header">
                    <div className="header-logo">
                        <img src={headerLogo} className='mr-3' width="100%" alt="header-logo" />
                        <div className="package-name">
                            <p className='ml-3'>{PackageData?.title}</p>
                        </div>
                    </div>
                </div>

                <div className='opd-reimbursement-main-wrapper-cover'>
                    <div className="book-now-form-main-cover">
                        <div className='reimburse-header-title p-3'>
                            <h5>{PackageData?.title}</h5>
                            <p>{PackageData?.description}</p>
                        </div>

                        <div className="reimbursement-main-form-cover p-3 pt-0 pb-0">
                            <p className="validation-error mt-2 repo-tex-tepo-tepo">
                                Pending Wallet Points: {PackUserData?.data?.data?.remaining_amount}
                            </p>
                            <form onSubmit={handleSubmit(ReimburseMentSubmission)}>
                                <div className='reimburse-header-form'>
                                    <Form.Group className="mb-3 book-now-input-box" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            name='mobile_number'
                                            type="text"
                                            placeholder="Mobile Number"
                                            {...register("mobile_number", {
                                            })}
                                            maxLength={10}
                                            defaultValue={PackUserData?.data?.data?.user_details?.mobile_number || ""}
                                            onChange={(e) => setValue('mobile_number', e.target.value)}
                                            disabled={PackUserData?.data?.data?.user_details?.mobile_number !== null}
                                        />
                                        <span className="text-danger validation-error">
                                            {errors.mobile_number && errors.mobile_number.message}
                                        </span>
                                    </Form.Group>

                                    <Form.Group className="mb-3 book-now-input-box" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            type="text"
                                            name='email'
                                            placeholder="Email Address"
                                            {...register("email", {
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Please enter a valid email address",
                                                },
                                            })}
                                            defaultValue={PackUserData?.data?.data?.user_details?.email_id || ""}
                                            onChange={(e) => setValue('email', e.target.value)}
                                            disabled={PackUserData?.data?.data?.user_details?.email_id !== null}
                                        />
                                        <span className="text-danger validation-error">
                                            {errors.email && errors.email.message}
                                        </span>
                                    </Form.Group>
                                </div>

                                <h5 className='text-center mb-2 reimburseTitle'>Reimbursement Details</h5>

                                <div className="reimbursement-main-form">
                                    <div className='row'>
                                        <div className='col-12 mb-3'>
                                            <div className='reimbursement-input-box'>
                                                {PackUserData?.data?.data?.family_list?.length > 0 && PackUserData?.data?.data?.is_select_show !== 0 ? (
                                                    <select
                                                        name="name"
                                                        className=""
                                                        {...register("name", {
                                                            required: "Please select or enter a name",
                                                        })}
                                                        onChange={(e) => {
                                                            const selectedData = JSON.parse(e.target.value);
                                                            setValue('gender', selectedData.gender);
                                                            setValue('relation', selectedData.relation);
                                                            setValue('start_date', selectedData.dateofbirth);
                                                            setSelectedFamilyMember(selectedData);
                                                        }}

                                                    >
                                                        <option value="" selected disabled>Select Name</option>
                                                        {PackUserData?.data?.data?.family_list?.map((optionData) => (
                                                            <option key={optionData?.member_id} value={JSON.stringify({ name: optionData?.name, member_id: optionData?.member_id, gender: optionData?.gender, relation: optionData?.relation, dateofbirth: optionData?.date_of_birth })}>
                                                                {optionData?.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className='booking-date'
                                                        placeholder="Enter Name"
                                                        {...register("name", {
                                                            required: "Name is required",
                                                        })}
                                                    />
                                                )}
                                                {errors.name && <span className="text-danger">{errors.name.message}</span>}
                                            </div>
                                        </div>
                                        <div className='col-6 mb-3'>
                                            <div className='reimbursement-input-box'>
                                                {PackUserData?.data?.data?.family_list?.length > 0 && PackUserData?.data?.data?.is_select_show !== 0 && selectedFamilyMember?.gender !== "" ? (
                                                    <input
                                                        type="text"
                                                        style={{ minWidth: "132px" }}
                                                        name="gender"
                                                        className='booking-date'
                                                        placeholder="Enter Gender"
                                                        disabled={selectedFamilyMember !== null}
                                                        {...register("gender", {
                                                            required: "gender is required",
                                                        })}
                                                    />

                                                ) : (
                                                    <select
                                                        name="gender"

                                                        className=""
                                                        {...register("gender", {
                                                            required: "Please select gender",
                                                        })}
                                                        onChange={(e) => setValue('gender', e.target.value)}
                                                    >
                                                        <option value="" disabled selected>Select Gender</option>
                                                        {PackUserData?.data?.data?.gender?.map((optionData, index) => {
                                                            return (
                                                                <option value={optionData?.value}>{optionData?.text}</option>
                                                            )
                                                        })}
                                                    </select>
                                                )}

                                                {errors.gender && <span className="text-danger">{errors.gender.message}</span>}
                                            </div>
                                        </div>
                                        <div className='col-6 mb-3'>
                                            <div className='reimbursement-input-box'>

                                                {PackUserData?.data?.data?.family_list?.length > 0 && PackUserData?.data?.data?.is_select_show !== 0 && selectedFamilyMember?.relation !== "" ? (

                                                    <input
                                                        type="text"
                                                        style={{ minWidth: "132px" }}
                                                        name="relation"
                                                        className='booking-date'
                                                        placeholder="Enter relation"
                                                        disabled={selectedFamilyMember !== null}
                                                        {...register("relation", {
                                                            required: "relation is required",
                                                        })}
                                                    />
                                                ) : (
                                                    <select
                                                        name="relation"
                                                        className=""
                                                        {...register("relation", {
                                                            required: "Please select relation",
                                                        })}
                                                        onChange={(e) => setValue('relation', e.target.value)}
                                                    >
                                                        <option value="" disabled selected>Select relation</option>
                                                        {PackUserData?.data?.data?.relation?.map((optionData) => {
                                                            return (
                                                                <option value={optionData?.value}>{optionData?.text}</option>
                                                            )
                                                        })}
                                                    </select>
                                                )}

                                                {errors.relation && <span className="text-danger">{errors.relation.message}</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 mb-3'>
                                            <div className='reimbursement-input-box'>

                                                {PackUserData?.data?.data?.family_list?.length > 0 && PackUserData?.data?.data?.is_select_show !== 0 && selectedFamilyMember?.dateofbirth !== null ? (
                                                    <input
                                                        type="text"
                                                        name="start_date"
                                                        className='booking-date date-tepo'
                                                        placeholder="Enter Date"
                                                        disabled={selectedFamilyMember !== null}
                                                        {...register("start_date", {
                                                            required: "Date is required",
                                                        })}
                                                    />
                                                ) : (
                                                    <DatePicker
                                                        name="start_date"
                                                        {...register("start_date", {
                                                            required: "Please select a date", // Validation message
                                                        })}
                                                        value={dob}
                                                        onChange={(date) => {
                                                            formatDate(date);
                                                            setValue('start_date', date); // Update the value in the form
                                                        }}
                                                        placeholderText="Enter Date of Birth"
                                                        showYearDropdown={false}
                                                        showMonthDropdown={false}
                                                        autoComplete="off"
                                                        className="booking-date date-tepo"
                                                        minDate={new Date(imburseData?.min_date_time)}
                                                        maxDate={new Date(imburseData?.max_date_time)}
                                                        dateFormat="yyyy-MM-dd"
                                                    />
                                                )}


                                            </div>
                                            {errors.start_date && <span className="text-danger">{errors.start_date.message}</span>}
                                        </div>
                                        <Form.Group className="mb-3 book-now-input-box" controlId="exampleForm.ControlInput1">
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter Amount to be reimbursed"
                                                {...register("amount", {
                                                    required: "Please enter an amount",
                                                    validate: {
                                                        positive: (value) =>
                                                            value > 0 || "Amount must be greater than 0",
                                                        withinPending: (value) =>
                                                            value <= (PackUserData?.data?.data?.remaining_amount || 0) ||
                                                            `Amount cannot exceed pending wallet points of ${PackUserData?.data?.data?.remaining_amount}`,
                                                    },
                                                })}
                                                onChange={(e) => {
                                                    setValue('amount', e.target.value);
                                                }}
                                            />
                                            {errors.amount && (
                                                <span className="text-danger validation-error mt-2">
                                                    {errors.amount.message}
                                                </span>
                                            )}
                                        </Form.Group>


                                    </div>

                                    <div className="supporting-doucment-wrapper reimburment-box-data">
                                        <div className="supporting-doucment-heading">
                                            <p>Supporting Documents</p>
                                            <span>Note : Upload JPG, PNG, PDF file format</span>
                                        </div>
                                        <div className="file-data-box-repo">
                                            {documentTypes.map((documentData, index) => (
                                                <div className="upload-document-boxlist-cover" key={index}>
                                                    <div className="upload-document-list-header">
                                                        <div className="upload-data-box-repo-site">
                                                            <h6>{docTypes[0][documentData]} {docTypes[0][documentData] !== "Other Doucments" && <span style={{ color: "red" }}>*</span>}</h6>
                                                            <div className="upload-document-btn-box">
                                                                <button>
                                                                    <span>
                                                                        <PiUploadBold />
                                                                    </span>
                                                                    <input
                                                                        type="file"
                                                                        name="claim_upload_file[]"
                                                                        onChange={(e) =>
                                                                            handleFileChange(e, documentData)
                                                                        }
                                                                        style={{
                                                                            opacity: 0,
                                                                            width: 100,
                                                                            position: "absolute",
                                                                            left: "-8px",
                                                                            top: 3,
                                                                        }}
                                                                        multiple
                                                                    />
                                                                    <p>Upload</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                `${files[documentData]?.length > 2}`
                                                                    ? "uploaded-documents-list-box height-style"
                                                                    : "uploaded-documents-list-box"
                                                            }
                                                        >

                                                            {files[documentData].length > 0 ? (
                                                                files[documentData].map((file, index) => (
                                                                    <div
                                                                        className="uploaded-documents-list"
                                                                        key={index}
                                                                    >
                                                                        <h6>{file.name}</h6>
                                                                        <button
                                                                            onClick={(e) =>
                                                                                handleFileDelete(
                                                                                    e,
                                                                                    documentData,
                                                                                    file,
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <FaRegCircleXmark />
                                                                        </button>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>

                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={`${reimburseUserName == "" && files.length === 0 && files?.Invoice?.length === 0 && files?.BankDetails?.length === 0 && files?.Prescription?.length === 0 && reimburseAmount == "" && reimburseAmount > imburseData?.pending_amount && isSubmitting ? 'reimbursement-submit-box submit-btn-box disable-submit-btn' : 'reimbursement-submit-box submit-btn-box'}`}>
                                        <button
                                            type='submit'
                                            disabled={isButtonDisabled || isSubmitting}>
                                            {isSubmitting ? 'Submitting....' : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {successmodal && (
                        <SuccessFull show={modalShow} successMessage={ReiumbersmentResponse?.data?.message || ReiumbersmentResponse?.error} status={ReiumbersmentResponse?.status} animationtype={successmodal} />
                    )}
                    <Toaster
                        position="top-center"
                        reverseOrder={false}
                        gutter={8}
                        containerClassName=""
                        containerStyle={{}}
                        toastOptions={{
                            className: "custom-toast",
                            duration: 2000,
                            style: {
                                background: "#fff",
                                color: "#363636",
                                fontSize: "14px",
                                width: "300px",
                                top: "55px !important",
                            },
                        }}
                    />
                </div >

            </div >
        </>
    )
}

export default ReimbursementForm