import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FaStar } from "react-icons/fa6";
import { no_preview } from '../Images';
import CartLoader from '../animation/CartLoader';
import useCartFunction from '../hooks/useCartFunction';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ToastBox from '../animation/ToastBox';


const OrderRecent = ({ mediData }) => {
    const navigate = useNavigate();
    const hanldeDescription = (item) => {
        const data = {
            Title: "Product Details",
            data: item
        };
        navigate('/medicine-description', { state: data });
    };

    return (
        <>
            <div className='recent-order-repo-box'>
                <Swiper className="mySwiper" slidesPerView={2.1}>
                    {mediData?.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className="recent-product-data-repo-cover" onClick={() => hanldeDescription(item)}>
                                    <div className="image-cover-recent">
                                        <img
                                            src={item?.banner_url || no_preview}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = no_preview;
                                            }}
                                            alt="product-icon"
                                        />
                                    </div>
                                    <p className='recent-ellipsis-text'>{item?.name}</p>
                                    <div className="price-repo-text">
                                        <div className="price-tag-repo">
                                            <p>
                                                {(item?.prices?.discounted_price !== null && item?.prices?.discounted_price !== 0)
                                                    ? (String(item?.prices?.discounted_price).startsWith('₹') ? item?.prices?.discounted_price : `₹${item?.prices?.discounted_price}`)
                                                    : (item?.prices?.mrp !== 0 && item?.prices?.mrp !== null)
                                                        ? (String(item?.prices?.mrp).startsWith('₹') ? item?.prices?.mrp : `₹${item?.prices?.mrp}`)
                                                        : "No price available"}
                                            </p>

                                            <span>
                                                {(item?.prices?.discounted_price !== null && item?.prices?.discounted_price !== 0) &&
                                                    (String(item?.prices?.mrp).startsWith('₹') ? item?.prices.mrp : `₹${item?.prices?.mrp}`)}
                                            </span>
                                        </div>
                                        <div className="price-star">
                                            {item?.average_rating !== '' && (
                                                <span><FaStar />{item?.average_rating}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="offer-recent-text-box">
                                        {item?.prices?.discount && (
                                            <p>{item?.prices?.discount}</p>
                                        )}
                                    </div>
                                    {/* <div className="line-repo"></div>
                                    <div className="qty-repo-box">
                                        <p>Min. Qty : {item?.min_quantity}</p>
                                    </div> */}
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            <ToastBox />
        </>

    )
}


export default OrderRecent