import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';
import { radioaddPatientMembers } from '../../Redux/actions/PostApiCall';
import { fetchPatientsRadio } from '../../Redux/actions/GetApiCall';

const RadioPatientModal = ({ patientsList, showModal, modalMode, setShowModal, selectedPatient }) => {
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  const addPatientResponse = useSelector((state) => state?.add_patient_members);
  const dispatch = useDispatch();

  // const formValues = watch()

  useEffect(() => {
    if (modalMode === 'edit') {
      reset({
        member_id: selectedPatient?.id || '',
        name: selectedPatient.name || '',
        age: selectedPatient.age || '',
        gender: selectedPatient.gender || '',
        relation: selectedPatient.relation || '',
      });
    } else {
      reset({
        id: '',
        name: '',
        age: '',
        gender: '',
        relation: '',
      });

    }
  }, [modalMode, selectedPatient, reset]);

  const onSubmit = async (data) => {

    const isSelfExist = patientsList?.data?.response?.some((dat) => dat?.relation == "Self");
    const isConditionSatisfied = (isSelfExist && data?.relation == "Self");

    if (isConditionSatisfied) {
      toast.error('Relation Self already exists');
      return
    } else {
      await dispatch(radioaddPatientMembers(data));
    }
    await dispatch(fetchPatientsRadio());
  };

  console.log(patientsList)

  useEffect(() => {
    if (addPatientResponse?.status === "success") {
      // toast.success(addPatientResponse?.data?.message);
      setShowModal(false);
      reset();
    } else if (addPatientResponse?.status === "failure") {
      toast.error(addPatientResponse?.data?.message);
    }
  }, [addPatientResponse])

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Patient Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} className="patient-modal-form">
            <div className="patient-details-input-box">
              <label htmlFor="patientName">Patient Name</label>
              <input
                type="text"
                id="patientName"
                placeholder="Enter Patient Name"
                {...register('name', {
                  required: 'Patient Name is required',
                })}
                onKeyPress={(e) => {
                  const char = String.fromCharCode(e.which || e.keyCode);
                  if (!/^[A-Za-z\s]+$/.test(char)) {
                    e.preventDefault();
                  }
                }}
              />
              {errors.name && <p className="error-message">{errors.name.message}</p>}
            </div>


            <div className="patient-half-boxs">
              <div className="patient-details-input-box age-gender-box d-flex flex-column">
                <label htmlFor="age">Age</label>
                <input
                  type="number"
                  id="age"
                  className="age-input"
                  placeholder="Enter Age"

                  min={0}
                  max={101}
                  {...register('age', {
                    required: 'Age is required',
                    min: { value: 0, message: 'Age cannot be negative' },
                    max: { value: 100, message: 'Age cannot be more than 100' }
                  })}
                />
                {errors.age && <p className="error-message">{errors.age.message}</p>}
              </div>
              <div className="patient-details-input-box age-gender-box d-flex flex-column">
                <label htmlFor="gender">Gender</label>
                <select
                  name="gender"
                  id="gender"
                  className="patient-gender-input gender-input"
                  {...register('gender', { required: 'Gender is required' })}

                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                {errors.gender && <p className="error-message">{errors.gender.message}</p>}
              </div>
            </div>

            <div className="patient-details-input-box">
              <label htmlFor="relation">Relationship</label>
              <select
                name="relation"
                id="relation"
                className="relation-input"
                {...register('relation', { required: 'Relationship is required' })}

              >
                <option value="">Select Relationship</option>
                <option value="Self">Self</option>
                <option value="Spouse">Spouse</option>
                <option value="Child">Child</option>
                <option value="Mother">Mother</option>
                <option value="Father">Father</option>
                <option value="Other">Other</option>
              </select>
              {errors.relation && <p className="error-message">{errors.relation.message}</p>}
            </div>
            {modalMode == "edit" ? (
              <Button type="submit" className='mt-3 save-patient-btn'>
                {addPatientResponse?.loader ? "Updating..." : "Update member"}
              </Button>
            ) : (
              <Button type="submit" className='mt-3 save-patient-btn'>
                {addPatientResponse?.loader ? "Saving..." : "Save Member"}
              </Button>
            )}
          </form>
        </Modal.Body>
      </Modal>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#fff",
            color: "#363636",
          },
        }}
      />
    </>
  );
};

export default RadioPatientModal;
