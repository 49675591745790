import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  appHome,
  appSupport,
  appWhatsapp,
  appProfile,
  appTalkDoctor,
  appPathlogy,
  appRadiologysupport,
  teleconsultationsupport,
  appInsuranceClaimSupport,
  appOtherSupport,
  appConductTest,
} from "../images";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { help_Api } from "../Redux/actions/PostApiCall";
import SuccessFull from "../Animation/SuccessFull";
import LoaderAnime from "../Pages/Medicine/animation/prescription_loader";

const Footer = () => {
  const [showHelpBox, setShowHelpBox] = useState(false);

  const [activeButton, setActiveButton] = useState(null);
  const [selectdata, setSelectData] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const HelpData = useSelector(state => state.help_api_details)

  const handleButtonClick = (buttonIndex, buttonData) => {
    setActiveButton(activeButton === buttonIndex ? null : buttonIndex);
    setSelectData(activeButton === buttonData ? null : buttonData);
  };

  const handleToggleHelpBox = () => {
    setShowHelpBox(!showHelpBox);
  };
  const handleClose = () => {
    setShowHelpBox(false)
  }
  const [successmodal, setSuccessModal] = useState(false);
  const handleWhatsApplink = () => {
    const whatsappLink = `https://wa.me/123456789`; // example whatsapp number
    window.open(whatsappLink, "_blank");
  };


  const onSubmit = async () => {
    setShowHelpBox(false)
    setModalShow(true)
    const data = {
      request_type: selectdata
    }
    await dispatch(help_Api(data));
  };


  useEffect(() => {
    if (HelpData?.status === "success") {
      setSuccessModal(true)
      setSelectData(null)
      setActiveButton(null)
      setShowModal(false)

      setTimeout(() => {
        setSelectData(null)
        setActiveButton(null)
        setSuccessModal(false)
      }, 2000);
    }

    else if (HelpData?.status === "loading") {
      setShowModal(true)
    }
  }, [HelpData])

  return (
    <>
      <div className="footer-wrapper">
        <div className="footer-cover">
          <div className="home">
            <NavLink to="/home">
              <button>
                <div className="footer-image-box">
                  <img src={appHome} alt="Home" />
                </div>
                <p>Home</p>
              </button>
            </NavLink>
          </div>
          <div className="support">
            <button onClick={handleToggleHelpBox}>
              <div className="footer-image-box">
                <img src={appSupport} alt="support" />
              </div>
              <p>Help</p>
            </button>
          </div>
          <div className="whatsapp">
            <button onClick={handleWhatsApplink}>
              <div className="footer-image-box">
                <img src={appWhatsapp} alt="Whatsapp" />
              </div>
              <p>Whatsapp</p>
            </button>
          </div>
          <div className="profile">
            <NavLink to="/menu">
              <button>
                <div className="footer-image-box">
                  <img src={appProfile} alt="Profile" />
                </div>
                <p>Menu</p>
              </button>
            </NavLink>
          </div>
        </div>
      </div>

      <div className={`wrap-help-data-box ${showHelpBox ? "show" : ""}`}>
        <div className="home-help-header-box">
          <div className="home-help-text">
            <h6>Reach Us</h6>
            <p>Please select you options and click on submit</p>
          </div>
          <button onClick={() => handleClose()}>
            <IoCloseCircleSharp />
          </button>
        </div>
        <div className="Reach-us-cover-section">
          <div className="reach-box-section">
            <button
              className={`support-btn ${activeButton === 1 ? "active" : ""}`}
              onClick={() => handleButtonClick(1, "Talk to Doctor")}>
              <div className="reach-box-1">
                <img src={appTalkDoctor} alt="talk-doctor" />
                <p>Talk to Doctor</p>
              </div>
            </button>
            <button
              className={`support-btn ${activeButton === 2 ? "active" : ""}`}
              onClick={() => handleButtonClick(2, "Conduct Test")}
            >
              <div className="reach-box-2">
                <img src={appConductTest} alt="conduct-test" />
                <p>Conduct Test</p>
              </div>
            </button>
            <button
              className={`support-btn ${activeButton === 4 ? "active" : ""}`}
              onClick={() => handleButtonClick(4, "Radiology")}>
              <div className="reach-box-2">
                <img src={appRadiologysupport} alt="Radiology" />
                <p>Radiology</p>
              </div>
            </button>
          </div>
        </div>
        <div className="Reach-us-cover-section">
          <div className="reach-box-section">
            <button
              className={`support-btn ${activeButton === 6 ? "active" : ""}`}
              onClick={() => handleButtonClick(6, "Teleconsultation")}>
              <div className="reach-box-2">
                <img src={teleconsultationsupport} alt="teleconsultation" />
                <p>Teleconsultation</p>
              </div>
            </button>
            <button
              className={`support-btn ${activeButton === 7 ? "active" : ""}`}
              onClick={() => handleButtonClick(7, "OPD Claim")}
            >
              <div className="reach-box-1">
                <img src={appInsuranceClaimSupport} alt="claim-support" />
                <p>OPD Claim</p>
              </div>
            </button>
            <button
              className={`support-btn ${activeButton === 8 ? "active" : ""}`}
              onClick={() => handleButtonClick(8, "Others")}
            >
              <div className="reach-box-2">
                <img src={appOtherSupport} alt="Others" />
                <p>Others</p>
              </div>
            </button>
          </div>
        </div>

        <div className="reach-submit-btn">
          <button onClick={onSubmit} disabled={selectdata === null}>
            <p>Submit</p>
          </button>
        </div>
      </div>

      {showModal && (
        <LoaderAnime showModal={showModal} />
      )}

      {successmodal && (
        <SuccessFull show={modalShow} successMessage={HelpData?.message} animationtype={successmodal} />
      )}
    </>
  );
};

export default Footer;
