import React from 'react'
import { Modal } from 'react-bootstrap'
import './prescription_loader.css'
import { placordergif, walletcapgif } from '../Images'

const WalletLoader = ({showModal}) => {
    return (
        <>
            <Modal
                show={showModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <div className='presc-input-load capture-repo-image'>
                      <img src={walletcapgif} alt="" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WalletLoader