import React, { useState } from 'react';
import useLocationState from '../Medicine/hooks/useLocationState';
import { headerLogo } from '../../images';
import { hospitalIcon } from '../Medicine/Images';
import { FiSearch } from 'react-icons/fi';
import StarRatings from 'react-star-ratings';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router';

const HospitalCard = () => {
    const Data = useLocationState();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const navigate = useNavigate();

    // Extract unique departments
    const uniqueDepartments = Array.from(
        new Set(Data.flatMap(hospital => hospital.departments))
    );

    const filteredHospitalBySearch = Data.filter(hospital => {
        const matchesSearchTerm =
            hospital.hospital_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            hospital.city.toLowerCase().includes(searchTerm.toLowerCase());

        const matchesDepartment =
            selectedCategory === 'All' ||
            hospital.departments.includes(selectedCategory);

        return matchesSearchTerm && matchesDepartment;
    });

    const handleHospitalDetails = (item) => {
        navigate('/hospital-details', { state: item })
    }

    return (
        <div className="home-review-banner">
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Hospitals</p>
                    </div>
                </div>
            </div>

            {/* Search bar */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search hospital..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;
                        if (!regex.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                />
                <FiSearch />
                <div className="filter-buttons">
                    <Swiper spaceBetween={0} slidesPerView={2} effect="slide" grabCursor={true}>
                        <div className="swiper-wrapper">
                            <SwiperSlide
                                className={`repo-cate-doc ${selectedCategory === 'All' ? 'btn-active' : ''}`}
                                onClick={() => setSelectedCategory('All')}
                            >
                                All
                            </SwiperSlide>
                            {uniqueDepartments.map((department, index) => (
                                <SwiperSlide
                                    key={index}
                                    className={`repo-cate-doc ${selectedCategory === department ? 'btn-active' : ''}`}
                                    onClick={() => setSelectedCategory(department)}
                                >
                                    {department}
                                </SwiperSlide>
                            ))}
                        </div>
                    </Swiper>
                </div>
            </div>

            <div className="hospital-cover-repo-box" style={{ padding: '8px',paddingRight:'0px'}}>
                {filteredHospitalBySearch.map((item, index) => (
                    <div className="hospital-card-repo" key={index} onClick={() => handleHospitalDetails(item)}>
                        <img src={hospitalIcon} alt="hospital-image" />
                        <div className="hospital-data-wrap">
                            <p>Name: <span>{item?.hospital_name}</span></p>
                            <p style={{ color: 'GrayText' }}>City: <span>{item?.city}</span></p>
                            <p style={{ color: 'GrayText' }}>Speciality: <span>Multi-speciality</span></p>
                            <p style={{ color: 'GrayText' }}>
                                Doctors: <span>12</span>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HospitalCard;
