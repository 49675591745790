import { React, useEffect, useRef, useState } from "react";
import {
  appWalletprofile,
  NoWallet,
} from "../../../images";
import Loader from "../../../Animation/Loader";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { fetchPackageList, getWalletUtilization } from "../../../Redux/actions/GetApiCall";
import { useDispatch, useSelector } from "react-redux";
import PresciptionLoader from "../../Medicine/animation/prescription_loader";
import AppHeader from "../../Medicine/Components/Medicine_Header";

const MyWalletUtilization = () => {
  const [selectedDiagnosticPackageData, setSelectedDiagnosticPackageData] =
    useState("");
  const [myWalletTransactionData, setmyWalletTransactionData] = useState([]);
  const [walletCategories, setWalletCategories] = useState([]);
  const [filteredData, setFilteredData] = useState(myWalletTransactionData);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const packagesData = useSelector((state) => state.package_list);
  const wallet_details_response = useSelector((state) => state.wallet_details_response);

  const dataToDisplay = filteredData ? filteredData : myWalletTransactionData;
  const scrollRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (item, index) => {
    setActiveIndex(index);

    const selectedItem = item;

    let filtered;

    if (selectedItem === "All") {
      filtered = myWalletTransactionData;
    } else {
      const filtered = myWalletTransactionData.filter(walletData => 
        selectedItem && walletData.category.toLowerCase().includes(selectedItem.toLowerCase())
      );
      
      console.log(filtered)
    }
    setFilteredData(filtered);
  };

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -200,
        behavior: 'smooth'
      });
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 200,
        behavior: 'smooth'
      });
    }
  };

  const handleSelectPackage = (e) => {
    const selectedId = e.target.value;
    const selectedItem = packagesData.data.active_package.find(
      (item) => item.id == selectedId
    );
    setSelectedDiagnosticPackageData(selectedItem);
  };

  useEffect(() => {
    if (packagesData && packagesData.data?.active_package?.length > 0) {
      setSelectedDiagnosticPackageData(packagesData.data.active_package[0]);
      setShowModal(true);
    }
  }, [packagesData]);

  useEffect(() => {
    if (selectedDiagnosticPackageData?.id && selectedDiagnosticPackageData?.wallet_id) {
      dispatch(getWalletUtilization(selectedDiagnosticPackageData?.id, selectedDiagnosticPackageData?.wallet_id))
    }
  }, [selectedDiagnosticPackageData]);

  useEffect(() => {
    setShowModal(false);
    if (wallet_details_response && wallet_details_response?.data?.data?.transaction_history?.length > 0) {
      setmyWalletTransactionData(wallet_details_response?.data?.data?.transaction_history)
      const uniqueCategories = ['All', ...new Set(wallet_details_response?.data?.data?.transaction_history.map(transaction => transaction.category))];
      setWalletCategories(uniqueCategories)
    }
  }, [wallet_details_response]);

  useEffect(() => {
    dispatch(fetchPackageList());
  }, [dispatch]);

  useEffect(() => {
    setFilteredData(myWalletTransactionData);
  }, [myWalletTransactionData]);

  return (
    <>
      <div className="home-review-banner">
        <AppHeader Title="Wallet" />
        {showModal && (
          <PresciptionLoader showModal={showModal || wallet_details_response?.loader} />
        )}
        {packagesData?.loader ? (
          <Loader />
        ) : (
          <>
            {packagesData?.data?.active_package?.length > 0 ? (
              <div className="my-wallet-utilization-main-wrapper-cover">
                <>
                  <div class="wallet-points-box">
                    <div class="ope-select-data-box wallet-satate">
                      <p>Please Select the Package</p>
                      <select
                        onChange={handleSelectPackage}
                        value={selectedDiagnosticPackageData?.id || ""}
                      >
                        <option value="" disabled>
                          Select Package
                        </option>
                        {packagesData?.data?.active_package?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {wallet_details_response && (
                    <>
                      <div className="wallet-points-box-cover">
                        <div className="avail-wallet-data">
                          <div className="wallet-point-heading">
                            <p>Available Points</p>
                            <h6>{wallet_details_response?.data?.data?.remaining_wallet}</h6>
                          </div>
                          <div className="wallet-point-image">
                            <img src={appWalletprofile} width="100%" alt="review" />
                          </div>
                        </div>
                        <div className="total-dev-repo-point">
                          <div className="wallet-data-boxs-can">
                            <h5>{wallet_details_response?.data?.data?.total_wallet}</h5>
                            <p>
                              Total <br /> Points
                            </p>
                          </div>
                          <div className="wallet-data-boxs-can">
                            <h5>{wallet_details_response?.data?.data?.total_utilized}</h5>
                            <p>
                              Used <br /> Points
                            </p>
                          </div>
                          <div className="wallet-data-boxs-can">
                            <h5>{wallet_details_response?.data?.data?.refunded_wallet}</h5>
                            <p>
                              Refunded <br /> Points
                            </p>
                          </div>
                        </div>
                      </div>
                      {wallet_details_response?.data?.data?.freeze_wallet !== 0 && (
                        <div className="wallet-freez-repo">
                          <p>Your wallet points {wallet_details_response?.data?.data?.freeze_wallet} are currently under verification and are temporarily on hold.</p>
                        </div>
                      )}
                    </>
                  )}
                  {myWalletTransactionData && myWalletTransactionData.length > 0 ? (
                    <>
                      <div className="wallet-boxs-bep-repo-so">
                        <div className="wallet-filter-box-repo-data" ref={scrollRef}>
                          {walletCategories.map((item, index) => (
                            <div
                              className={`wallet-filter-box-repo-data-cover ${index === activeIndex ? 'active' : ''}`}
                              key={index}
                              onClick={() => handleItemClick(item, index)}
                            >
                              <div className="wallet-filter-box-repo-data-cover-inner">
                                <p>{item}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="button-repo-wallet">
                          <button onClick={handleScrollLeft}><FaChevronLeft /></button>
                          <button onClick={handleScrollRight}><FaChevronRight /></button>
                        </div>
                      </div>
                      <div className="wallet-categories-section">
                        {dataToDisplay.length === 0 ? (
                          <div className="no-data-avail">
                            <p>No results found</p>
                          </div>
                        ) : (
                          <div className="wallet-categories-main-box-wrapper">
                            <div className="trans-text-box">
                              <h5>Transistion : </h5>
                            </div>
                            {dataToDisplay.map((walletData) => (
                              <div className="wallet-categories-box-wrapper" key={walletData.id}>
                                <div className="wallet-categories-content-box">
                                  <div className="pack-accord-btn">
                                    <div className="wallet-categories-content-box-cover">
                                      <p>
                                        Category: <span className="category-text">{walletData?.category}</span>
                                      </p>
                                      {walletData?.product !== "" && (
                                        <p>
                                          Product: <span>{walletData?.product}</span>
                                        </p>
                                      )}
                                      <p>
                                        Points Used: <span className="point">{walletData?.amount}</span>
                                      </p>
                                      <p>
                                        Transaction Type: <span className="point"
                                          style={{ color: walletData?.transaction_type?.toLowerCase() === 'credit' ? 'green' : 'red' }}
                                        >{walletData?.transaction_type}</span>
                                      </p>
                                      <div className="points-text-border"></div>
                                      <p>
                                        Date of Transaction: <span>{walletData?.transaction_date}</span>
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="wallet-categories-section-image">
                      <img src={NoWallet} alt="" />
                    </div>
                  )}
                </>
              </div>
            ) : (
              <div className="wallet-categories-section-image no-wallet-found-repo">
                <img src={NoWallet} alt="" />
                <h6>No Wallet Found</h6>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MyWalletUtilization;
