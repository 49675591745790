import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Medicine_Header from '../Components/Medicine_Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../Medicine_Style/description.css';
import { Autoplay, Pagination } from 'swiper/modules';
import { px_Image } from '../Images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedicineCartList, fetchMedicinelist, fetchOfferList, fetchSingleMedicinelist } from '../../../Redux/actions/GetApiCall';
import LoaderAnime from "../animation/prescription_loader";
import useCartFunction from '../hooks/useCartFunction';
import CartLoader from '../animation/CartLoader';
import toast from 'react-hot-toast';
import StarRatings from 'react-star-ratings';
import Medicine_Recent from '../Components/Medicine_Recent';
import Medicine_footer from '../Components/Medicine_footer';

const Medicine_Description = () => {
  const location = useLocation();
  const Data = location?.state;
  const sku_id = Data?.data?.sku_id;
  const type = Data?.data?.type;
  const name = Data?.data?.name;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [cartListData, setCartListData] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const navigate = useNavigate();

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };
  const key = "medicines"
  const { data: mediData, loader: mediloader, error: mediError } = useSelector(state => state.single_medicine_list);
  const singleDataRepoCart = useSelector(state => state.single_medicine_list)
  const { data: offerData, loader: offerLoader } = useSelector(state => state.offer_list);
  const { data: similarData } = useSelector(state => state.medicine_list);
  const { availability, cartStatus, cart, CartData, DeleteStatus, loadingItems, handleCartAdd, handleIncrement, handleDecrement, handleDelete } = useCartFunction(null, setLoaderStatus, setCartListData, null, null);
  const cartListLength = useSelector(state => state.medicine_cart_list)


  const [selectedVariant, setSelectedVariant] = useState('');

  useEffect(() => {
    if (mediData?.data) {
      setSelectedVariant(mediData?.data?.variants_with_filter?.[0]?.active_variant?.[0])
    }
  }, [mediData])

  console.log(selectedVariant)
  useEffect(() => {
    dispatch(fetchSingleMedicinelist(sku_id, type));
    dispatch(fetchOfferList());
    dispatch(fetchMedicinelist(name))
  }, [sku_id, type]);

  const handleVaient = (id) => {
    dispatch(fetchSingleMedicinelist(id, type));
  };

  useEffect(() => {
    if (singleDataRepoCart?.error !== null && singleDataRepoCart?.status === "failure") {
      toast.error(singleDataRepoCart?.error)
      setTimeout(() => {
        window.history.back()
      }, 1500);
    }
  }, [singleDataRepoCart])

  useEffect(() => {
    dispatch(fetchMedicineCartList())
  }, [dispatch]);

  useEffect(() => {
    const variants = mediData?.data?.variants_with_filter?.[0]?.active_variant;
    if (variants?.length === 1) {
      setSelectedVariant(variants[0]);
    }
  }, [mediData]);

  const handleVariantChange = (e) => {
    setSelectedVariant(e.target.value);
  };

  const handleActiveVariantChange = (e) => {
    const id = e.target.value;
    dispatch(fetchSingleMedicinelist(id, type));
  }

  const filteredProducts = mediData?.data?.variants_with_filter?.[0]?.active_variant_products?.filter(
    (item) => item?.active_variant === selectedVariant
  );

  useEffect(() => {
    if (mediloader) {
      setShowModal(true)
    }
    else {
      setShowModal(false)
    }
  }, [mediloader]);

  const handleBanner = (item) => {
    const data = {
      Title: "Product Details",
      data: {
        sku_id: item?.sku_id,
        type: "otc"
      }
    };
    if (item?.sku_id !== "" && item?.redirect_parameter === "") {
      navigate('/medicine-description', { state: data });
    }
    else if (item?.sku_id === "" && item?.redirect_parameter !== "") {
      const cornerData = [{
        value: item?.redirect_parameter,
        label: item?.redirect_parameter,
      }];
      navigate("/health-corner", { state: cornerData });
    }
  }


  const cartItem = cart.find(cartItem => cartItem.sku_id === mediData?.data?.sku_id);


  return (
    <div className="home-review-banner">
      <Medicine_Header Title={Data?.Title} cartData={cartListData} />

      <div className="repo-desc-wrapper-boxss">
        {((mediloader && DeleteStatus !== "success")) ? (
          <>
            {mediloader && <LoaderAnime showModal={showModal} />}
          </>
        ) : (
          <>
            <div className="product-cover-wrapper-boxs">
              <div className="product-swiper">
                <Swiper
                  className="mySwiper product-swiper"
                  slidesPerView={1}
                  pagination={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Pagination, Autoplay]}
                >
                  {mediData?.data?.image_urls?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="product-swiper-image">
                        <img src={item?.high} alt="" />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              {mediData?.data?.prescription_required === 1 && (
                <div className="px-data-rpeo">
                  <img src={px_Image} alt="px-image" />
                  <span>Prescription Required*</span>
                </div>
              )}

              {mediData?.data?.social_cue !== "" && (
                <div className="social-cue">
                  <p>{mediData?.data?.social_cue}</p>
                </div>
              )}

              <div className="product-container-wrapper">
                <div className="product-name-repo-text">
                  <p>{mediData?.data?.name}</p>
                </div>

                <div className="pack-size">
                  {(mediData?.data?.pack_size_label !== "" || mediData?.data?.pack_size !== "") && (
                    <p>{mediData?.data?.pack_size_label || mediData?.data?.pack_size}</p>
                  )}
                </div>



                <div className="pro-pric-and-qty">
                  <div className="both-repo-text">
                    <div className="product-pric-repo-text-con price-repo-text">
                      <p>
                        {CartData?.data?.some(count => count.sku_id === mediData?.data?.sku_id) ? (
                          CartData.data.filter(count => count.sku_id === mediData?.data?.sku_id).map((count, idx) => (
                            <p key={idx} >
                              {(count.discounted_price !== null && count?.discounted_price !== 0)
                                ? (String(count?.discounted_price).startsWith('₹') ? count?.discounted_price : `₹${count?.discounted_price}`)
                                : (count?.price !== 0 && count?.price !== null)
                                  ? (String(count?.price).startsWith('₹') ? count?.price : `₹${count?.price}`)
                                  : "No price available"}
                            </p>
                          ))
                        ) : (
                          <p>
                            {(mediData?.data?.discounted_price !== null && mediData?.data?.discounted_price !== 0)
                              ? (String(mediData?.data.discounted_price).startsWith('₹') ? mediData?.data.discounted_price : `₹${mediData?.data.discounted_price}`)
                              : (mediData?.data?.price !== 0 && mediData?.data?.price !== null)
                                ? (String(mediData?.data.price).startsWith('₹') ? mediData?.data.price : `₹${mediData?.data.price}`)
                                : "No price available"}
                          </p>
                        )}
                      </p>


                      {CartData?.data?.some(count => count.sku_id === mediData?.data?.sku_id) ? (
                        CartData.data.filter(count => count.sku_id === mediData?.data?.sku_id).map((count, idx) => (
                          <span key={idx}>
                            {(count?.discounted_price !== null && count?.discounted_price !== 0) &&
                              (String(count?.price).startsWith('₹') ? count?.price : `₹${count?.price}`)}
                          </span>
                        ))
                      ) : (

                        <span>
                          {(mediData?.data?.discounted_price !== null && mediData?.data?.discounted_price !== 0) &&
                            (String(mediData?.data.price).startsWith('₹') ? mediData?.data.price : `₹${mediData?.data.price}`)}
                        </span>

                      )}

                      <div className="discount-count-text">
                        {CartData?.data?.some(count => count.sku_id === mediData?.data?.sku_id) ? (
                          CartData.data.filter(count => count.sku_id === mediData?.data?.sku_id).map((count, idx) => (
                            <p key={idx}>
                              {count.discount}
                            </p>
                          ))
                        ) : (
                          <p>
                            {mediData?.data?.discount}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="single-qty-repo-text">
                      <p>Min. Qty : {mediData?.data?.min_quantity}</p>
                    </div>
                  </div>

                  <div className="product-btn-repo-data-description">

                    <div className="add-product-box">
                      {(loadingItems.has(mediData?.data?.sku_id) && (cartStatus === 'loading' || DeleteStatus === "loading")) ? (
                        <CartLoader />
                      ) : (
                        <div className="add-cart-repo-boxs single-repo-desc">
                          {availability[mediData?.data?.sku_id] === 0 || mediData?.data?.is_available === 0 || (mediData?.data?.min_quantity === 0 && mediData?.data?.max_quantity === 0) ? (
                            <div className="out-of-stock left-repo-size">
                              <p>Out of Stock</p>
                            </div>
                          ) : (cartItem || (mediData?.data?.in_cart === 1 && mediData?.data?.is_available === 1 && mediData?.data?.quantity !== 0)) ? (
                            <div className="increase-cart-repo-boxs single-repo-desc-repo">
                              <button
                                className={`incre-btn-reop ${(cartItem && cartItem.quantity <= mediData?.data?.min_quantity) ? 'border-red' : ''}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (cartItem && cartItem.quantity <= mediData?.data?.min_quantity) {
                                    handleDelete(mediData?.data?.sku_id);
                                  } else {
                                    handleDecrement(mediData?.data?.sku_id);
                                  }
                                }}
                              >
                                <p>-</p>
                              </button>

                              {CartData?.data?.some(count => count.sku_id === mediData?.data?.sku_id) ? (
                                CartData.data.filter(count => count.sku_id === mediData?.data?.sku_id).map((count, idx) => (
                                  <span key={idx}>
                                    {count.quantity}
                                  </span>
                                ))
                              ) : (
                                <span>
                                  {mediData?.data?.quantity}
                                </span>
                              )}
                              <button
                                className='incre-btn-reop'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (cartItem && cartItem.quantity < mediData?.data?.max_quantity) {
                                    handleIncrement(mediData?.data?.sku_id);
                                  } else {
                                    toast.error("Max Reached");
                                  }
                                }}
                              >
                                <p>+</p>
                              </button>

                            </div>
                          ) : (
                            <button
                              className='single-btn-repo-box'
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCartAdd(mediData?.data);
                              }}
                            >
                              <p>ADD</p>
                            </button>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="product-ratings">
                      {mediData?.data?.ratings !== undefined && mediData?.data?.ratings !== "" && (
                        <StarRatings
                          rating={Number(mediData?.data?.ratings)}
                          starRatedColor="#ffd700"
                          numOfStars={5}
                          starDimension="15px"
                          starSpacing="0px"
                          name='rating'
                        />
                      )}
                      <p>{mediData?.data?.ratings}</p>
                    </div>
                  </div>

                  <div className="product-expiry">
                    <p dangerouslySetInnerHTML={{ __html: mediData?.data?.expiry_by }} />
                  </div>

                  <div className="delivery-date-repo">
                    <p dangerouslySetInnerHTML={{ __html: mediData?.data?.delivered_by }} />
                  </div>
                </div>

                {mediData?.data?.variants.length > 0 && (
                  <div className="product-varient-repo-box">
                    <h6>Choose Variant:</h6>
                    <div className="data-repo-variant-texts">
                      {mediData?.data?.variants?.map((item, index) => (
                        <p
                          key={index}
                          className='vaient-data-boxs'
                          onClick={() => handleVaient(item?.id)}
                          style={{
                            backgroundColor: item?.selected === true ? '#ffb125' : 'white',
                            color: item?.selected === true ? 'white' : 'black',
                            border: item?.selected === true ? '1px solid #ffb125' : '1px solid grey',
                          }}
                        >
                          {item?.display_text}
                        </p>
                      ))}
                    </div>
                  </div>
                )}


                {mediData?.data?.variants_with_filter.length > 0 && (
                  <div className="product-varient-repo-box">
                    <h6>Choose Variant:</h6>
                    <div className="data-repo-variant-options-select">

                      {/* First select: Sets the default selected option to the first variant */}
                      <select onChange={handleVariantChange} defaultValue={mediData?.data?.variants_with_filter?.[0]?.active_variant?.[0]}>
                        {mediData?.data?.variants_with_filter?.[0]?.active_variant?.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>

                      {/* Second select: Sets the default selected option to the first product, if available */}
                      <select onChange={handleActiveVariantChange}>
                        {mediData?.data?.variants_with_filter?.[0]?.active_variant_products.length >= 2 && (
                          <option value="" disabled selected>Select Value</option>
                        )}
                        {filteredProducts?.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item?.display_text}
                          </option>
                        ))}
                      </select>

                    </div>
                  </div>
                )}


                <hr className='repo-hr-line-data' />

                {mediData?.data?.description !== "" && (
                  <div className="single-product-repo-description">
                    <div className="prod-head-text-con-single">
                      <h6>Product Details</h6>
                    </div>
                    <div
                      className="prod-con-desc-para-repo"
                      style={{ height: isExpanded ? '100%' : '112px', overflow: 'hidden', transition: 'height 0.3s ease-in' }}
                    >
                      <p dangerouslySetInnerHTML={{ __html: mediData?.data?.description }} />
                    </div>
                    <div className="show-btns-repo-con" onClick={toggleDescription}>
                      <p>{isExpanded ? 'Show Less' : 'Show More'}</p>
                    </div>
                  </div>
                )}

                {offerData && offerData?.data?.offer_banner?.length > 0 && (
                  <>
                    <div className="head-offer-text">
                      <h6>Offers Available</h6>
                    </div>
                    <div className="medicine-slider-repo-box repo-single-offer-repo">
                      <Swiper className="mySwiper" slidesPerView={1.25}>
                        {offerData && offerData?.data?.offer_banner && offerData?.data?.offer_banner?.map((item, index) => (
                          <SwiperSlide className='repo-offer-tepo'>
                            <div className="image-box-slider-repo" onClick={() => handleBanner(item)}>
                              <img src={item?.url} alt="Offer-Banner" />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </>

                )}

                {(mediData?.data?.manufacturer?.id !== null || mediData?.data?.id !== null || mediData?.data?.manufacturer?.length > 0 || mediData?.data?.marketer?.length > 0) && (
                  <div className="single-product-repo-description marketer-repo-text">
                    {(mediData?.data?.manufacturer?.id !== null || mediData?.data?.manufacturer?.length > 0) && (
                      <>
                        <div className="manufacture-box">
                          <div className="heade-marketer-text">
                            <p>{mediData?.data?.manufacturer?.header}</p>
                          </div>
                          <div className="manufacturer-data">
                            <p>Name:{mediData?.data?.manufacturer?.name}</p>
                            <p>Address:{mediData?.data?.manufacturer?.address}</p>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    {(mediData?.data?.marketer?.id !== null || mediData?.data?.marketer?.length > 0) && (
                      <div className="marketer-box">
                        <div className="manufacture-box">
                          <div className="heade-marketer-text">
                            <p>{mediData?.data?.marketer?.header}</p>
                          </div>
                          <div className="manufacturer-data">
                            <p>Name : {mediData?.data?.marketer?.name}</p>
                            <p>Address : {mediData?.data?.marketer?.address}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {similarData?.data?.filter(item => item?.sku_id !== mediData?.data?.sku_id).length > 0 && (
                  <div className="similar-head-repo-text">
                    <h6>Similar Products</h6>
                  </div>
                )}

                <div className="similar-product-data-repo">
                  <Medicine_Recent mediData={similarData} similarId={mediData?.data?.sku_id} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {
        cartListLength?.data?.total_items > 0 && (
          <Medicine_footer cartData={cartListData} tabkey={key} />
        )
      }

    </div >
  );
};

export default Medicine_Description;
