import React, { useState, useEffect } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { LabTestIcon, appTabletMed, savings_image } from "../../images";
import Medicine_Header from "../Medicine/Components/Medicine_Header";
import Medicine_footer from "../Medicine/Components/Medicine_footer";
import useLocationState from "../Medicine/hooks/useLocationState";
import { useSelector, useDispatch } from "react-redux";
import { orderDiagnostic, fetchDiagnosticPlaceOrderDetails, orderRadiology, fetchRadiologyPlaceOrderDetails } from "../../Redux/actions/PostApiCall";
// import LoaderAnime from "../Medicine/animation/prescription_loader";
import CartLoader from "../Medicine/animation/prescription_loader"
import Medicine_order from "../Medicine/Pages/Medicine_order";
import { orderMedicine } from "../../Redux/actions/PostApiCall";
import { fetchPackageList, getQueryParams } from "../../Redux/actions/GetApiCall";
import { useLocation } from "react-router";
import ToastBox from "../Medicine/animation/ToastBox";
import RadioPlaceOrder from "../LabTestPages/components/RadioPlaceOrder";
import RadioFooter from "./RadioFooter";


const RadioMainOrder = () => {
    const Title = "Placed Order";
    const dispatch = useDispatch();
    const mediCart = 1;
    const tabKey = useLocationState();
    const [cartData, setCartData] = useState([]);
    const [key, setKey] = useState(tabKey);
    const [showModal, setShowModal] = useState(false);

    const placeOrderDetailsData = useSelector(
        (state) => state.radiology_place_order_details_response
    );
    console.log(tabKey)
    const currentUrl = window.location.href; // Get the current URL



    // -----------------------DIAGNOSTICS-----------------------------
    const [selectedDiagnosticPackageData, setSelectedDiagnosticPackageData] = useState("");

    const [placeOrderDiagnosticApiData, setPlaceOrderDiagnosticApiData] = useState({
        package_id: null,
        wallet_id: null,
        source: 0
    });

    useEffect(() => {
        if (placeOrderDetailsData?.status === "loading") {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [placeOrderDetailsData]);

    const handleDiagnosticOrder = async () => {
        await dispatch(orderRadiology(placeOrderDiagnosticApiData));
    }

    useEffect(() => {
        // if (tabKey === "diagnostics") {
        dispatch(fetchPackageList())
        dispatch(fetchRadiologyPlaceOrderDetails(placeOrderDiagnosticApiData));
        // }
    }, [dispatch]);

    console.log(placeOrderDetailsData)


    return (
        <div className="home-review-banner">
            <Medicine_Header Title={Title} cartData={cartData} labCartCount={placeOrderDetailsData?.data?.response?.members?.length} prescrip={key} />


            <div className="app-main-cart-cover-wrapper">
                <div className="tab-main-cover-wrapper">
                    <RadioPlaceOrder
                        showModal={showModal}
                        setShowModal={setShowModal}
                        selectedDiagnosticPackageData={selectedDiagnosticPackageData}
                        setSelectedDiagnosticPackageData={setSelectedDiagnosticPackageData}
                        setPlaceOrderDiagnosticApiData={setPlaceOrderDiagnosticApiData}
                        placeOrderDiagnosticApiData={placeOrderDiagnosticApiData}
                        labCartData={
                            placeOrderDetailsData &&
                            placeOrderDetailsData?.data?.response
                        }
                    />
                </div>
            </div>
            {placeOrderDetailsData && placeOrderDetailsData?.data?.response?.cart_count > 0 && (
                <RadioFooter
                    labCartData={placeOrderDetailsData?.data?.response}
                    tabkey={key}
                    mediCart={mediCart}
                    handleDiagnosticOrder={handleDiagnosticOrder}
                />
            )}
            <ToastBox />
        </div>
    );
};

export default RadioMainOrder;
