import React from 'react'
import toast from 'react-hot-toast';
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineLink } from "react-icons/md";
import { gmail } from '../Images';
import { Modal } from 'react-bootstrap';

const SharModal = ({ setShowShare, showShare, Data, tabDataRepo }) => {
    const pincode = localStorage.getItem("pincode")
    const handleShareClose = () => setShowShare(false);

    console.log(Data)

    console.log(tabDataRepo)

    const constructUrl = () => {
        let pathname = window.location.pathname;

        const params = new URLSearchParams();

        if (tabDataRepo && tabDataRepo === "Diagnostic" && tabDataRepo !== undefined) {
            pathname = "/place-order";
        }

        if (pathname.includes('place-order') && tabDataRepo === undefined) {
            pathname = pathname.replace('place-order', 'medicine-place-order');
            params.append('pincode', pincode);
        }

        if (pathname.includes('order-consultation')) {
            params.append('id', Data?.data?.id);
        }

        params.append('auto', Data?.user_auto_data || 'default');
        params.append('agentcode', 1);

        const baseUrl = window.location.origin + pathname;
        return `${baseUrl}?${params.toString()}`;
    };

    const openWhatsApp = () => {
        try {
            const constructedUrl = constructUrl(); // Get the full URL with params
            const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent("Check out this link: ")}${encodeURIComponent(constructedUrl)}`;
            window.open(whatsappUrl, '_blank');
        } catch (err) {
            console.error('Failed to open WhatsApp: ', err);
        }
    };

    const openGmail = () => {
        try {
            const constructedUrl = constructUrl(); // Get the full URL with params
            const subject = encodeURIComponent("Check out this link!");
            const body = encodeURIComponent(
                "Hello,\n\nI wanted to share the details of the order with you:\n\n[Include Order Details Here]\n\n"
            ) + encodeURIComponent(`<a href="${constructedUrl}">Click here to view the order</a>`);
            
            const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&su=${subject}&body=${body}`;
            window.open(gmailUrl, '_blank');
        } catch (err) {
            console.error('Failed to open Gmail: ', err);
        }
    };
    
    

    const CopyLink = () => {
        const fullUrl = constructUrl(); // Use the same URL logic
        window.navigator.clipboard.writeText(fullUrl)
            .then(() => {
                toast.success("URL with user data copied to clipboard successfully");
            })
            .catch((error) => {
                toast.error("Failed to copy URL to clipboard");
                console.error('Failed to copy:', error);
            });
    };



    return (
        <div>
            <Modal
                show={showShare}
                onHide={handleShareClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Share Quotes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="shar-btn-close">
                        <div className="shar-text-icon">
                            <button onClick={() => openWhatsApp()}>
                                <BsWhatsapp />
                            </button>
                            <p>WhatsApp</p>
                        </div>
                        <div className="shar-text-icon">
                            <button onClick={() => openGmail()} className='gmail-color-icon'>
                                <img src={gmail} alt="gmail-icon" />
                            </button>
                            <p>Gmail</p>
                        </div>
                        <div className="shar-text-icon">
                            <button onClick={() => CopyLink()} className='copy-color-icon'>
                                <MdOutlineLink />
                            </button>
                            <p>Copy Link</p>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default SharModal