import React from "react";
import { WorkPointIcon1 ,WorkPointIcon2 , WorkPointIcon3 , WorkPointIcon4 , WorkPointIcon5 , howitworknum5 , howitworknum1 , howitworknum2 , howitworknum3 , howitworknum4 } from "../../../images";

const How_it_works = () => {
  return (
    <>
      <div className="how-it-works-main-wrapper mt-4">
        <div className="how-it-works-main-content">
          <div className="how-it-works-header">
            <h6>How It Works?</h6>
            <p>
              We aim to extend our doctor consultation services to remote
              location via Teleconsultation.
            </p>
          </div>
          <div className="how-it-works-points-box">
            <div className="how-it-works-point my-3">
              <div className="title-detail-box d-flex align-items-center">
                <img src={WorkPointIcon1} alt="how-it-works-icon"></img>
                <div className="title-content">
                  <h6>Select Specility</h6>
                  <p>Select the specialty or symptoms</p>
                </div>
              </div>
              <img
                src={howitworknum1}
                className="numbering-img"
                alt="WorkPointIcon"
              ></img>
            </div>
            <div className="how-it-works-point my-3">
              <div className="title-detail-box d-flex align-items-center">
                <img src={WorkPointIcon2} alt="how-it-works-icon"></img>
                <div className="title-content">
                  <h6>Share Details</h6>
                  <p>Share the patient name and make the payment</p>
                </div>
              </div>
              <img
                src={howitworknum2}
                className="numbering-img"
                alt="WorkPointIcon"
              ></img>
            </div>
            <div className="how-it-works-point my-3">
              <div className="title-detail-box d-flex align-items-center">
                <img src={WorkPointIcon3} alt="how-it-works-icon"></img>
                <div className="title-content">
                  <h6>Receive Link</h6>
                  <p>You will receive a link for Video consultation</p>
                </div>
              </div>
              <img
                src={howitworknum3}
                className="numbering-img"
                alt="WorkPointIcon"
              ></img>
            </div>
            <div className="how-it-works-point my-3">
              <div className="title-detail-box d-flex align-items-center">
                <img src={WorkPointIcon4} alt="how-it-works-icon"></img>
                <div className="title-content">
                  <h6>Join Consultation</h6>
                  <p>Join the link and consult with specialist Doctor</p>
                </div>
              </div>
              <img
                src={howitworknum4}
                className="numbering-img"
                alt="WorkPointIcon"
              ></img>
            </div>
            <div className="how-it-works-point my-3">
              <div className="title-detail-box d-flex align-items-center">
                <img src={WorkPointIcon5} alt="how-it-works-icon"></img>
                <div className="title-content">
                  <h6>Get Prescription</h6>
                  <p>
                    You will receive the signed prescription soon after the call
                  </p>
                </div>
              </div>
              <img
                src={howitworknum5}
                className="numbering-img"
                alt="WorkPointIcon"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default How_it_works;
