import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { appCommonlyBookTest } from "../../../images";
import useHandleCart from "../hooks/useHandleCart";
import CartLoader from "../../Medicine/animation/CartLoader";

const TestCard = ({ test, title, cartDetailsResponse, packdescriptionId, descriptionId, callCategories, callTestdescriptionApi, callPackagedescriptionApi }) => {
  const { handleCartAdd, handleRemoveCart, testDataStatus, loadingItems, cartResponseStatus, cartDetailsResponseStatus, myApiLoader } =
    useHandleCart("test");

  console.log(test)

  return (
    <>
      <div className="common-test-box mb-3">
        <NavLink to={title === "Packages" ? `/package-description/${test?.id}` : `/test-description/${test?.id}`}>
          <div className="common-test-image">
            <img src={appCommonlyBookTest} width="100%" alt="Book-test" />
          </div>
          {test?.discount_rate > 0 && (
            <div className="offer-common">
              <p>{test?.discount_rate}% OFF</p>
            </div>
          )}
          <div className="common-test-heading">
            <h6>{test?.name}</h6>
          </div>
          <hr />
          <div className="common-test-content text-center">
            <p>
              Included Tests :{" "}
              {test?.parameter_count || test?.test_count}
            </p>
            <h6>MB Price: ₹ {test?.medibhai_price}</h6>
            <span>
              {test?.mrp > 0 && test?.medibhai_price !==test?.mrp && (
                <>
                  MRP: ₹ {test?.mrp}
                </>
              )}
            </span>
          </div>
        </NavLink>

        {loadingItems.has(test?.id) && myApiLoader ? (
          <CartLoader />
        ) : test.in_cart === 1 ? (
          <button
            class="remove-cart-btn"
            onClick={() => handleRemoveCart({ ...test, packdescriptionId: packdescriptionId, type: packdescriptionId ? "package" : "test", callCategories: callCategories, callTestdescriptionApi: callTestdescriptionApi, descriptionId: descriptionId, callPackagedescriptionApi: callPackagedescriptionApi })}
          >
            Remove
          </button>
        ) : (
          <div className="common-add-cart">
            <button onClick={() => handleCartAdd({ ...test, packdescriptionId: packdescriptionId, type: packdescriptionId ? "package" : "test", callCategories: callCategories, callTestdescriptionApi: callTestdescriptionApi, descriptionId: descriptionId, callPackagedescriptionApi: callPackagedescriptionApi })}>
              <p>ADD TO CART</p>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default TestCard;
