import React from "react";
import { headerLogo } from "../../../images";

const SpecialityProduct = ({ data, handleBooking }) => {
  return (
    <>
      <div
        className="speciality-product align-items-center mb-2"
        onClick={() => handleBooking({ ...data, type: "specialist" })}
      >
        {data?.discount_type === "free" ? (
          <div className="free-price-card">
            <h6>Free</h6>
          </div>
        ) : (
          data?.discount_type !== null && (
            <div className="free-price-card">
              <h6>{data?.discount_type}</h6>
            </div>
          )
        )}
        <div className="speciality-product-details">
          <h6>{data?.name}</h6>
          <p className="speciality-product-price mb-0">
            ₹{data?.original_price}{" "}
            {data?.mrp != data?.original_price && (
              <span className="cut-price">₹{data?.mrp}</span>
            )}
          </p>
        </div>
        <div className="speciality-prod-img-box">
          <img
            src={data?.file_name ? data?.file_name : headerLogo}
            width="100%"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = headerLogo;
            }}
            alt="spec-icon"
          />
        </div>
      </div>
    </>
  );
};

export default SpecialityProduct;
