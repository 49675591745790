import React, { useState, useEffect } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Medicine_Cart from "../Medicine/Pages/Medicine_Cart";
import LabCart from "../LabTestPages/components/LabCart";
import { LabTestIcon, appTabletMed } from "../../images";
import Medicine_Header from "../Medicine/Components/Medicine_Header";
import Medicine_footer from "../Medicine/Components/Medicine_footer";
import useLocationState from "../Medicine/hooks/useLocationState";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDiagnosticCartDetails,
  fetchMedicineCartList,
} from "../../Redux/actions/GetApiCall";

// import LoaderAnime from "../Medicine/animation/prescription_loader";
import CartLoader from "../Medicine/animation/prescription_loader";
import EmptyCart from "../Medicine/Components/EmptyCart";

const MainCart = () => {
  const Title = "Cart";
  const dispatch = useDispatch();
  const mediCart = 1;
  const addressMedi = 2;
  const presMedi = 3;
  const tabKey = useLocationState();
  const [cartData, setCartData] = useState([]);
  const [key, setKey] = useState(tabKey?.tabkey || tabKey);
  const cartDetailsResponse = useSelector(
    (state) => state.diagnostic_cart_details
  );
  const cartMediData = useSelector((state) => state.medicine_cart_list.data);


  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (cartDetailsResponse?.status === "loading") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [cartDetailsResponse]);

  useEffect(() => {
    dispatch(fetchMedicineCartList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDiagnosticCartDetails());
  }, [dispatch]);

  return (
    <div className="home-review-banner">
      <Medicine_Header
        Title={Title}
        cartData={cartData}
        labCartCount={cartDetailsResponse?.data?.response?.cart_count}
        prescrip={key}
      />
      <div className="app-main-cart-cover-wrapper">
        <div className="tab-main-cover-wrapper">
          <Tab.Container id="left-tabs-example" defaultActiveKey={key}>
            <Row>
              <Col lg={12} className=" tab-cover tab-cover2">
                <div className="cart-header-tabs d-flex justify-content-center">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="medicines"
                        onClick={() => setKey("medicines")}
                        className={
                          key === "medicines" ? "active-link" : "not-active"
                        }
                      >
                        <div className="medicine-box lab-box">
                          <div className="medi-image">
                            <img
                              src={appTabletMed}
                              width="100%"
                              alt="Tablet-capsule"
                            />
                          </div>
                          <div className="box-heading">
                            <p>Medicines</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="diagnostics"
                        onClick={() => setKey("diagnostics")}
                        className={
                          key === "diagnostics" ? "active-link" : "not-active"
                        }
                      >
                        <div className="lab-box">
                          <div className="medi-image">
                            <img
                              src={LabTestIcon}
                              width="100%"
                              alt="Lab-Test"
                            />
                          </div>
                          <div className="box-heading">
                            <p>Lab Tests</p>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col lg={12}>
                <div className="tab-content-main-cover">
                  <Tab.Content>
                    <Tab.Pane eventKey="medicines">
                      <Medicine_Cart setCartData={setCartData} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="diagnostics">
                      {cartDetailsResponse?.status === "loading" && (
                        <CartLoader showModal={showModal} />
                      )}
                      {cartDetailsResponse && cartDetailsResponse?.data && (
                        <LabCart
                          labCartData={
                            cartDetailsResponse &&
                            cartDetailsResponse?.data?.response
                          }
                        />
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
      {((key === "medicines" &&
        cartMediData &&
        (cartMediData?.total_item > 0 || cartMediData?.total_items > 0) &&
        (cartMediData?.data?.length > 0 ||
          cartMediData?.available_stock?.length > 0)) ||
        (key === "diagnostics" &&
          cartDetailsResponse &&
          (cartDetailsResponse?.data?.response?.tests?.length > 0 ||
            cartDetailsResponse?.data?.response?.packages?.length > 0))) && (
        <Medicine_footer
          cartData={cartMediData}
          labCartData={cartDetailsResponse?.data?.response}
          tabkey={key}
          mediCart={mediCart}
          addressMedi={addressMedi}
          presMedi={presMedi}
        />
      )}
    </div>
  );
};

export default MainCart;
