import React, { useEffect, useState } from 'react'
import { headerLogo, RemiburmentIamge } from '../../../images'
import useLocationState from '../../Medicine/hooks/useLocationState'
import { useDispatch, useSelector } from 'react-redux';
import { fetchPackageList, fetchReimbursementStatusData } from '../../../Redux/actions/GetApiCall';
import MediLoader from '../../Medicine/animation/MediLoader';
import { pdf_icon } from '../../Medicine/Images';
import { useLocation } from 'react-router';

const ReimbursmentDetails = () => {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const dispatch = useDispatch();
    const ReiumbursementDetails = useSelector(state => state.reiumbursement_Details);
    const package_list = useSelector((state) => state?.package_list);

    const detailsData = ReiumbursementDetails?.data?.data?.reimbursement_data;

    const Reiumdata = useLocationState();

    useEffect(() => {
        dispatch(fetchPackageList())
    }, [dispatch])

    // Set the first active package as the default selected option
    useEffect(() => {
        if (package_list?.data?.active_package?.length > 0) {
            setSelectedPackage(package_list?.data?.active_package[0].id);
        }
    }, [package_list?.data]);


    useEffect(() => {
        if (selectedPackage || Reiumdata) {
            dispatch(fetchReimbursementStatusData(selectedPackage || Reiumdata));
        }
    }, [selectedPackage, dispatch, Reiumdata]);

    const handleSelectPackage = (e) => {
        setSelectedPackage(e.target.value);
    };

    const height = "75vh"

    return (
        <div>
            <div className="home-review-banner">
                <div className="home-app-header">
                    <div className="header-logo">
                        <img src={headerLogo} width="100%" alt="header-logo" />
                        <div className="user-name">
                            <p>Reimbursement Status</p>
                        </div>
                    </div>
                </div>

                {(Reiumdata || package_list?.data?.active_package?.length > 0) ? (
                    <div className="my-wallet-utilization-main-wrapper-cover reimburse-data-repo">
                        {package_list?.data?.active_package !== undefined && (
                            <div className="ope-select-data-box wallet-satate">
                                <p>Please Select the Package</p>
                                <select onChange={handleSelectPackage} value={selectedPackage || ""}>
                                    <option value="" disabled>
                                        Select Package
                                    </option>
                                    {package_list?.data?.active_package?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {ReiumbursementDetails?.status === "loading" ? (
                            <MediLoader height={height} />
                        ) : (
                            <div className="wallet-categories-section">
                                <div className="wallet-categories-main-box-wrapper">
                                    {detailsData && detailsData?.length !== 0 && (
                                        <div className="trans-text-box">
                                            <h5>Transition : </h5>
                                        </div>
                                    )}

                                    {detailsData && detailsData?.length === 0 ? (
                                        <div className="no-data-found-reim">
                                            <img src={RemiburmentIamge} alt="Reimbursement-icon" />
                                            <p>No Reimbursement Found</p>
                                        </div>
                                    ) : (
                                        detailsData && detailsData?.map((item) => (
                                            <div className="wallet-categories-box-wrapper mb-3" key={item?.id}>
                                                <div className="wallet-categories-content-box">
                                                    <div className="pack-accord-btn">
                                                        <div className="wallet-categories-content-box-cover">
                                                            <p>
                                                                Category: <span className="reim-text">{item?.reimbursement_type}</span>
                                                            </p>
                                                            <p>
                                                                Claim Date: <span>{item?.claimed_at}</span>
                                                            </p>
                                                            <p>
                                                                Claim Amount: <span className="-reim-point">{item?.amount}</span>
                                                            </p>
                                                            <div className="points-text-border"></div>
                                                            {item?.settled_at !== "" && (
                                                                <p>
                                                                    Date of Settlement: <b>{item?.settled_at}</b>
                                                                </p>
                                                            )}
                                                            {item?.settled_amount !== "" && (
                                                                <p>
                                                                    Settled Amount: <b className='-reim-point'>{item?.settled_amount}</b>
                                                                </p>
                                                            )}
                                                            {console.log(item?.status?.text)}
                                                            <p>
                                                                Status: <b style={{ color: item?.status?.dark_color }}>{item?.status?.text}</b>
                                                            </p>
                                                            {item?.remark !== "" && (
                                                                <p>
                                                                    Remark: <span>{item?.remark}</span>
                                                                </p>
                                                            )}

                                                            <div className="presciption-head-repo-text">
                                                                <div className="wallet-categories-content-box-cover">
                                                                    <p>Uploaded Documents</p>
                                                                </div>

                                                                <div className="data-presc-wrapper-box">
                                                                    {item?.documents?.map((item, index) => (
                                                                        <div className="prescipion-image-box-repo-data-repo-cat" key={index}>
                                                                            {item?.file_url?.endsWith(".pdf") ? (
                                                                                <a href={item.file_url} target="_blank" rel="noopener noreferrer">
                                                                                    <img
                                                                                        src={pdf_icon} // Use a placeholder image for PDF files
                                                                                        alt="PDF File"
                                                                                    />
                                                                                </a>
                                                                            ) : (
                                                                                <a href={item.file_url} target="_blank" rel="noopener noreferrer">
                                                                                    <img
                                                                                        src={item.file_url}
                                                                                        alt="Prescription Image"
                                                                                    />
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        )}

                    </div>
                ) : (
                    <div className="no-data-found-reim">
                        <img src={RemiburmentIamge} alt="Reimbursement-icon" />
                        <p>No Reimbursement Found</p>
                    </div>
                )}
            </div>
        </div >
    );
};

export default ReimbursmentDetails;
