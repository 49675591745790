import React, { useState, useEffect, useContext } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { NavLink, useLocation, useNavigate, browserHistory } from "react-router-dom";
import { ArrowBackButton } from "../../utils/AllFunction";
import { IoInformationCircle } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import GotoCart from "../Cart/GotoCart";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Select from "react-select";
import useRazorpay from "react-razorpay";
import PaymentModal from "../../Animation/PaymentModal";
import PaymentLoader from "../../Animation/PaymentLoader";
import {
  DIAGNOSTIC_TEST_API,
  MEDICINE_CART_MANAGER_LIST,
  MEDICINE_DELETE,
  MEDICINE_PREVIEW_API,
  MEDICINE_UPDATE,
  HEALTH_PACKAGE_API,
  USER_PACKAGE_API,
  PLACE_ORDER_DIAGNOSTIC_API,
  MY_ACCOUNT_API,
  PREVIEW_ORDER_DIAGNOSTIC_API,
  MEDICINE_ORDER_API,
  MEDICINE_PRESCRIPTION_UPLOAD_API,
  UPLOAD_PRESCRIPTION__API,
} from "../../Constants";

import {
  ArrowBack,
  appCartIcon,
  appEmptyCart,
  appHeartIcon,
  appLabMed,
  appTabletMed,
  appPackageIcon,
  ereportsIcon,
  appPXImg,
  DummyMedicine,
  appDeleteIcon,
  appUploadIcon,
  appDeliverLocation,
  appLabCart,
} from "../../images";
import LocationSection from "../LocationSection/LocationSection";
import toast, { Toaster } from "react-hot-toast";
import Mediloader from "../../Animation/Mediloader";
import SuccessFull from "../../Animation/SuccessFull";
import ErrorFull from "../../Animation/ErrorFull";
import Deleteloader from "../../Animation/DeleteLoader";
import { AppContext } from "../../ContextApi";
import ConfirmModal from "../MyProfile/Components/ConfirmModal";

const MainCart = () => {
  const { link } = useParams();
  const [key, setKey] = useState(
    link === "diagnostictest" ? "second" : "first"
  );
  const [CartData, setCartData] = useState(
    JSON.parse(localStorage.getItem("CartData")) || []
  );
  const [cartTotal, setcartTotal] = useState(null);
  const [cartDataTotal, setCartDataTotal] = useState([]);
  const [totalMrp, setTotalMrp] = useState(null);
  const [isPaymentModalOpen, setPaymentModal] = useState(false);
  const [paymentMsg, setpaymentMsg] = useState("");
  const [paymentAnimation, setPaymentAnimation] = useState(false);
  const [testData, setTestData] = useState([]);
  const [packageData, setPackageData] = useState([]);
  const [ispackageLoading, setPackageLoading] = useState(true);
  const [isAllpackageLoading, setAllPackageLoading] = useState(true);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [packageId, setPackageId] = useState(null);
  const [userPackageList, setUserPackageList] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [walletStatus, setWalletStatus] = useState("");
  const [newUpdatedTest, setNewupdatedTest] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [successmodal, setSuccessModal] = useState(false);
  const [successPaymentmodal, setSuccessPaymentModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [newUpdatedMedicine, setNewupdatedMedicine] = useState("");
  const [transactionId, setTransactionId] = useState(null);
  const [callBackUrl, setCallBackUrl] = useState(null);
  const V2HREF = localStorage.getItem("V2HREF");
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userinfo")) || ""
  );
  const [Razorpay] = useRazorpay();

  const token = localStorage.getItem("ACCESS_TOKEN");

  const [prescriptionId, setPrescriptionId] = useState(0);
  const { handleClose, formShow, handleShow } = useContext(AppContext)

  useEffect(() => {
    if (link === "diagnostictest") {
      setPrescriptionId(1);
    } else {
      setPrescriptionId(0);
    }
  }, [link]);

  useEffect(() => {
    setPrescriptionId(key === "second" ? 1 : 0);
  }, [key]);
  const [loadingHomeData, setLoadingHomeData] = useState(true);
  const [selectedaddressdata, setSelectedAddressData] = useState(
    JSON.parse(localStorage.getItem("SelectedAddresses"))
  );
  const [pincodeData, setPincodeData] = useState(
    JSON.parse(localStorage.getItem("pincode")) || ""
  );
  const [responseData, setResponseData] = useState([]);
  const [dataImage, setDataImage] = useState([]);
  const [loadingData, setLoadingData] = useState({});
  const handleAddressData = Object.values(selectedaddressdata);

  const [mediData, setMediData] = useState([])

  const handleClickTab = (tab) => {
    setKey(tab);
  };

  const location = useLocation();
  const presciptionData = location.state;


  const uploadFind = (rx) => {
    return responseData.find((item) => item.rx_required === rx);
  };
  const rxbox = uploadFind(true);

  // For Medicines functions
  // To delete
  const handleDelete = async (id) => {
    setSuccessModal(true);
    setModalShow(true);
    try {
      const deleteRes = await fetch(`${MEDICINE_DELETE}${id}/${pincodeData}`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });

      if (deleteRes.ok) {
        const manageDataAgain = await fetch(
          `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
          {
            headers: {
              Authorization: `${token}`,
            },
            method: "GET",
          }
        );

        const resAgain = await manageDataAgain.json();
        setResponseData(resAgain?.skus_res);
        setCartDataTotal(resAgain?.vas_charges || []);
        setMediData(resAgain)
      }
      setSuccessModal(false);
      setModalShow(false);
    } catch (error) {
      console.error(error);
    }
  };


  // To Increment
  const handleIncrement = async (filteredItem) => {
    try {
      setLoadingData((prevStates) => ({
        ...prevStates,
        [filteredItem.id]: true,
      }));
      const updatedQuantity = Math.max(filteredItem.qty + 1);

      // Update the cart locally
      const updatedCart = responseData.map((item) => {
        if (item.id === filteredItem.id) {
          return {
            ...item,
            qty: updatedQuantity,
          };
        }
        return item;
      });

      // Update the state with the updated cart
      setResponseData(updatedCart);

      // Construct the request body data
      const requestBodyData = {
        pincode: pincodeData,
        cart: [
          {
            onemg_sku_id: filteredItem.id,
            quantity: updatedQuantity,
            min_quantity: filteredItem.min_quantity,
            max_quantity: filteredItem.max_quantity,
          },
        ],
      };

      // Call cart_manager_update API to update the cart
      const updateData = await fetch(`${MEDICINE_UPDATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(requestBodyData),
      });

      if (updateData.ok) {
        // If the update is successful, fetch the updated cart data
        const manageDataAgain = await fetch(
          `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
          {
            headers: {
              Authorization: `${token}`,
            },
            method: "GET",
          }
        );

        const resAgain = await manageDataAgain.json();
        setCartDataTotal(resAgain?.vas_charges || []);
        setMediData(resAgain)

        // Set loading to false after receiving response
        setLoadingData((prevStates) => ({
          ...prevStates,
          [filteredItem.id]: false,
        }));
      } else {
        console.error("Failed to fetch", updateData.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // To Decrement
  const handleDecrement = async (filteredItem) => {
    try {
      setLoadingData((prevStates) => ({
        ...prevStates,
        [filteredItem.id]: true,
      }));
      // Calculate updated quantity ensuring it respects minimum quantity
      let updatedQuantity = Math.max(filteredItem.qty - 1, 0);

      // If the updated quantity is less than the minimum quantity, set it to 0
      if (updatedQuantity < filteredItem.min_quantity) {
        updatedQuantity = 0;
      }

      // Update the cart locally
      const updatedCart = responseData.map((item) => {
        if (item.id === filteredItem.id) {
          return {
            ...item,
            qty: updatedQuantity,
          };
        }
        return item;
      });

      // Update the state with the updated cart
      setResponseData(updatedCart);

      // Construct the request body data
      const requestBodyData = {
        pincode: pincodeData,
        cart: [
          {
            onemg_sku_id: filteredItem.id,
            quantity: updatedQuantity,
            min_quantity: filteredItem.min_quantity,
            max_quantity: filteredItem.max_quantity,
          },
        ],
      };
      // Call cart_manager_update API to update the cart
      const updateData = await fetch(`${MEDICINE_UPDATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(requestBodyData),
      });

      if (updateData.ok) {
        const manageDataAgain = await fetch(
          `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
          {
            headers: {
              Authorization: `${token}`,
            },
            method: "GET",
          }
        );

        const resAgain = await manageDataAgain.json();
        setCartDataTotal(resAgain?.vas_charges || []);
        setMediData(resAgain)
        // console.log(resAgain?.vas_charges)
        // Update UI after successful API response
        setResponseData(resAgain?.skus_res);

        // Set loading to false after receiving response
        setLoadingData((prevStates) => ({
          ...prevStates,
          [filteredItem.id]: false,
        }));
      } else {
        console.error("Failed to fetch", updateData.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const MangerListDataRepo = async () => {
    setSuccessModal(true)
    setModalShow(true)
    try {
      const ManagerListRes = await fetch(
        `${MEDICINE_CART_MANAGER_LIST}${pincodeData}`,
        {
          headers: {
            Authorization: `${token}`,
          },
          method: "GET",
        }
      );
      const ListDataRes = await ManagerListRes.json();
      setResponseData(ListDataRes?.skus_res || []);
      setMediData(ListDataRes)
      // console.log(ListDataRes?.skus_res)
      ListDataRes?.skus_res.map((res) => {
        setDataImage(res?.rx_required);
      });
      setCartDataTotal(ListDataRes?.vas_charges || []);
      setLoadingHomeData(false);
    } catch (error) {
      console.log(error);
      setLoadingHomeData(false);
    } finally {
      setSuccessModal(false)
      setModalShow(false)
    }
  };

  useEffect(() => {
    MangerListDataRepo();
  }, []);

  // End Medicine Function

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${token}`,
    },
  };
  const [selectedTests, setSelectedTests] = useState(
    JSON.parse(localStorage.getItem("SELECTED_TEST")) || []
  );
  const [selectedAddressDetails, setAddressDetails] = useState(
    JSON.parse(localStorage.getItem("SelectedAddresses"))
  );

  const [isPaymentLoading, setPaymentLoading] = useState(false);

  const [selectedTestDate, setSelectedTestDate] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setcartTotal(0);

    selectedTests.map((testData) => {
      if (testData.packIds.length > 0) {
        testData.packIds.forEach((data) => {
          if (data !== null) {
            setcartTotal((prev) => prev + data.pack_mrp);
          }
        });
      }

      // Calculate total for testIds
      if (testData.testIds.length > 0) {
        testData.testIds.forEach((data) => {
          if (data !== null) {
            // Adjust the property name as needed for testIds
            setcartTotal((prev) => prev + data.test_mrp);
          }
        });
      }

      // ... other logic if needed
    });
    setTotalMrp(0);
    selectedTests.map((testData) => {
      if (testData.packIds.length > 0) {
        testData.packIds.forEach((data) => {
          if (data !== null) {
            setTotalMrp((prev) => prev + data.pack_medi_price);
          }
        });
      }

      // Calculate total for testIds
      if (testData.testIds.length > 0) {
        testData.testIds.forEach((data) => {
          if (data !== null) {
            // Adjust the property name as needed for testIds
            setTotalMrp((prev) => prev + data.test_medi_price);
          }
        });
      }

      // ... other logic if needed
    });

    localStorage.setItem("CartData", JSON.stringify(CartData));
  }, [selectedTests]);


  useEffect(() => {
    const FetchCommonTest = async () => {
      setAllPackageLoading(true);
      try {
        const response = await fetch(DIAGNOSTIC_TEST_API, requestOptions);

        const res = await response.json();
        if (res.status) {
          setTestData(res.data);
          setAllPackageLoading(false);
        } else {
          // console.log(res);
        }
      } catch (error) {
        console.error(error);
        setAllPackageLoading(false);
      }
    };
    const Fetchpackage = async () => {
      setPackageLoading(true);
      try {
        const response = await fetch(HEALTH_PACKAGE_API, requestOptions);

        const res = await response.json();
        if (res.status) {
          setPackageData(res.data);
          setPackageLoading(false);
          // console.log(res.data);
        } else {
          console.log(res);
        }
      } catch (error) {
        console.error(error);
        setPackageLoading(false);
      }
    };

    const FetchpackList = async () => {
      setPackageLoading(true);
      try {
        const response = await fetch(USER_PACKAGE_API, requestOptions);

        const res = await response.json();
        if (res.status) {
          setUserPackageList(res.user_package);
        }
      } catch (error) {
        console.error(error);
        setPackageLoading(false);
      }
    };

    const FetchUserProfile = async () => {
      setLoadingHomeData(true);
      try {
        const response = await fetch(MY_ACCOUNT_API, requestOptions);
        const res = await response.json();
        if (res.status) {
          setWalletData(res.data?.wallet_data);
        } else {
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingHomeData(false);
      }
    };

    FetchUserProfile();
    Fetchpackage();
    FetchCommonTest();
    FetchpackList();
    setSelectedTimeSlot(JSON.parse(localStorage.getItem("SELECTED_TIMESLOT")));
    setSelectedTestDate(JSON.parse(localStorage.getItem("SELECTED_TEST_DATE")));
  }, []);

  const handleCartTestData = (patientId) => {
    setSelectedTests((prevdata) =>
      prevdata.filter((testData, i) => testData.patientId !== patientId)
    );
  };

  const handlePackageSelectChange = (selectedOption) => {
    if (selectedOption) {
      setPackageId(selectedOption.id);
    } else {
      setPackageId("");
    }
  };


  //Diagnostics Place Order
  const handlePlaceOrder = async () => {
    setPaymentLoading(true)
    const placeOrderData = {
      insert_id: "",
      check_use_wallet: walletStatus,
      user_package_id: packageId ? packageId : userPackageList[0]?.id,
    };

    const handlePayment = async (options, insert_id, mycallBackUrl) => {
      // Define a custom handler function
      const customSuccessHandler = function (response) {
        if (response) {
          localStorage.removeItem("SELECTED_TIMESLOT");
          localStorage.removeItem("CartData");
          localStorage.removeItem("SELECTED_TEST");
          localStorage.removeItem("SELECTED_TEST_DATE");
          setTimeout(() => {
            if (V2HREF && V2HREF !== null) {
              window.location.href = V2HREF;
              localStorage.removeItem('V2HREF')
            }
            else {
              navigate('/diagnostictest')
            }
          }, 5000)
        }
        setTransactionId(response.razorpay_payment_id);
        MakeOnlinePayment(
          mycallBackUrl,
          response.razorpay_payment_id,
          insert_id
        );
      };

      const customOptions = {
        handler: customSuccessHandler,
        modal: {
          ondismiss: function () {
            toast.error("Payment Cancelled");
            setTimeout(() => {
              navigate('/diagnostictest')
            }, 2000)
          }
        }
      };

      const rzp1 = new Razorpay({ ...options, ...customOptions });
      // options.amount = (totalMrp <= 300  ? totalMrp + 150 : totalMrp) * 100;
      // console.log(rzp1)

      rzp1.on("payment.failed", function (response) {
        // Handle failed payment
        console.log(response)
      });

      rzp1.open();
    };

    const payment_data = {
      insert_id: "",
      transaction_id: "",
    };

    const paymentOptions = { ...requestOptions };
    paymentOptions.method = "POST";
    paymentOptions.body = JSON.stringify(payment_data);
    paymentOptions.contentType = false;
    paymentOptions.processData = false;


    //INFO : Calling This function in HandlePayment ==================
    const MakeOnlinePayment = async (callBackUrl, id, insert_id) => {
      setPaymentLoading(true);
      const url = new URL(callBackUrl);

      const updatedOnlineData = {
        ...payment_data,
        insert_id: insert_id,
        transaction_id: id,
      };

      const updatedRequestOption = {
        ...paymentOptions,
        body: JSON.stringify(updatedOnlineData),
      };

      try {
        const response = await fetch(url.href, updatedRequestOption);

        const res = await response.json();
        console.log(res, "Mera Response ")
        if (res.status == 200) {
          // console.log(res);
          setPaymentAnimation(true);
          setpaymentMsg(res.message);
          setPaymentLoading(false);
          setPaymentModal(true);
          setTimeout(() => {
            if (V2HREF && V2HREF !== null) {
              window.location.href = V2HREF;
              localStorage.removeItem('V2HREF')
            }
            else {
              navigate('/diagnostictest')
            }
          }, 5000)
        } else {
          setPaymentAnimation(false);
          setpaymentMsg(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const placeOrderrequestOption = { ...requestOptions };
    placeOrderrequestOption.method = "POST";
    placeOrderrequestOption.body = JSON.stringify(placeOrderData);
    placeOrderrequestOption.contentType = false;
    placeOrderrequestOption.processData = false;

    const placeOrder = async (insert_id) => {
      const updatedPlaceOrderData = {
        ...placeOrderData,
        insert_id: insert_id,
      };

      const updatedRequestOption = {
        ...placeOrderrequestOption,
        body: JSON.stringify(updatedPlaceOrderData),
      };

      try {
        const response = await fetch(
          PLACE_ORDER_DIAGNOSTIC_API,
          updatedRequestOption
        );

        const res = await response.json();
        if (res.status) {
          if (typeof res.data.razorpay_details == "undefined") {
            setSuccessPaymentModal(true);
            setSuccessMessage(res?.data?.message);
            setModalShow(true);
            localStorage.removeItem("SELECTED_TIMESLOT");
            localStorage.removeItem("CartData");
            localStorage.removeItem("SELECTED_TEST");
            localStorage.removeItem("SELECTED_TEST_DATE");
            setTimeout(() => {
              setModalShow(false);
              setSuccessPaymentModal(false);
              if (V2HREF && V2HREF !== null) {
                window.location.href = V2HREF;
                localStorage.removeItem('V2HREF')
              }
              else {
                navigate('/diagnostictest')
              }
            }, 5000);
          }
          if (res.data.razorpay_details.call_back_url) {
            setCallBackUrl(res.data.razorpay_details.call_back_url);
          }
          // This Function Basically make Online Payment =====================
          handlePayment(
            res.data.razorpay_details,
            insert_id,
            res.data.razorpay_details.call_back_url
          );
        } else {
          console.log(res);
        }
      } catch (error) {
        console.error(error);
      }
      finally {
        setPackageLoading(false)
      }
    };

    let visit_date = new Date(selectedTestDate);
    const previewOrderdata = {
      user_data: {
        otp_verify: "1",
        city_valid: "1",
        email_id: selectedAddressDetails[0].email,
        address: selectedAddressDetails[0].state,
        landmark: selectedAddressDetails[0].landmark,
        pincode: selectedAddressDetails[0].pincode,
        city: selectedAddressDetails[0].city,
        visit_date: visit_date.toLocaleDateString("en-CA"),
        visit_time: selectedTimeSlot,
      },
      package_data: newUpdatedTest,
      check_use_wallet: walletStatus,
      user_package_id: packageId,
    };

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = JSON.stringify(previewOrderdata);

    // will get Insert Id from this Preview Order and Send to PlaceOrder
    try {
      const response = await fetch(
        PREVIEW_ORDER_DIAGNOSTIC_API,
        requestOptionsCopy
      );
      const res = await response.json();
      if (res.status) {
        if (walletStatus === "") {
          // alert("online pay karo")
          placeOrder(res.data.insert_id);
        } else if (
          res.wallet.show_button == "wallet" ||
          res.wallet.show_button == "partial"
        ) {
          placeOrder(res.data.insert_id);
        }
      } else {
        // console.log(res);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setPaymentLoading(false)
    }
  };

  // For Medicine Place order
  const handleMedicinePlaceOrder = async () => {
    setPaymentLoading(true);
    if (presciptionData === null && rxbox) {
      setPaymentLoading(false)
      toast.error("Please Upload Prescription");
      return;
    }
    const placeOrderData = {
      insert_id: "",
      check_use_wallet: walletStatus,
      user_package_id:
        userPackageList && userPackageList.length > 0
          ? packageId || userPackageList[0].id || 0
          : 0,
    };

    const handlePayment = async (options, insert_id, mycallBackUrl) => {
      // Define a custom handler function
      setPaymentLoading(true);
      const customSuccessHandler = function (response) {

        if (response) {
          const message = "Your Order has been successfully placed. We will keep you updated about order status."
          setPaymentAnimation(true);
          setpaymentMsg(message);
          setSuccessMessage(message);
          setPaymentLoading(false);
          setPaymentModal(true);
          setTimeout(() => {
            if (V2HREF && V2HREF !== null) {
              window.location.href = V2HREF;
              localStorage.removeItem('V2HREF')
            }
            else {
              navigate("/buyMedicines", { replace: true })
            }
          }, 3000)
        }
        setTransactionId(response.razorpay_payment_id);
        MakeOnlinePayment(
          mycallBackUrl,
          response.razorpay_payment_id,
          insert_id
        );
      };

      const customOptions = {
        handler: customSuccessHandler,
        modal: {
          ondismiss: function () {
            setLoadingData(false)
            setPaymentLoading(false);
            toast.error("Payment Cancelled");
            setTimeout(() => {
              navigate('/buyMedicines', { replace: true });
            }, 2000)
          }
        }
      };

      const rzp1 = new Razorpay({ ...options, ...customOptions });

      // console.log("options", options);
      rzp1.on("payment.failed", function (response) {
        console.log(response)
      });

      rzp1.open();
    };

    const payment_data = {
      insert_id: "",
      transaction_id: "",
    };

    const paymentOptions = { ...requestOptions };
    paymentOptions.method = "POST";
    paymentOptions.body = JSON.stringify(payment_data);
    paymentOptions.contentType = false;
    paymentOptions.processData = false;

    const MakeOnlinePayment = async (callBackUrl, id, insert_id) => {
      // setPaymentLoading(true);
      const url = new URL(callBackUrl);
      const updatedOnlineData = {
        ...payment_data,
        insert_id: insert_id,
        transaction_id: id,
      };

      const updatedRequestOption = {
        ...paymentOptions,
        body: JSON.stringify(updatedOnlineData),
      };

      try {
        // const apiUrl = `${window.location.origin}${url.pathname}`;
        const response = await fetch(url.href, updatedRequestOption);

        const res = await response.json();
        // setPaymentLoading(true);
        if (res.status) {
          // setPaymentAnimation(true);
          // setpaymentMsg(res.message);
          // setSuccessMessage(res?.message);
          // setPaymentModal(true);
          // setPaymentLoading(false);


          localStorage.removeItem("uploadedImages");
          // setTimeout(() => {
          // setPaymentModal(false);
          // window.location.href="/buymedicines"
          // navigate("/buyMedicines");
          // window.location.reload();
          // }, 1500);
        } else {
          console.log(res);
          setPaymentAnimation(false);
          setpaymentMsg(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const placeOrderrequestOption = { ...requestOptions };
    placeOrderrequestOption.method = "POST";
    placeOrderrequestOption.body = JSON.stringify(placeOrderData);
    placeOrderrequestOption.contentType = false;
    placeOrderrequestOption.processData = false;

    const placeOrder = async (insert_id) => {
      const updatedPlaceOrderData = {
        ...placeOrderData,
        insert_id: insert_id,

      };

      const updatedRequestOption = {
        ...placeOrderrequestOption,
        body: JSON.stringify(updatedPlaceOrderData),
      };

      try {
        const response = await fetch(MEDICINE_ORDER_API, updatedRequestOption);
        const res = await response.json();

        setPaymentLoading(true)
        if (res.results.status === 200) {
          if (typeof res.results.razorpay_details == "undefined") {
            setSuccessPaymentModal(true);
            setSuccessMessage(res?.results?.message);
            setModalShow(true);
            setPaymentLoading(false)
            setTimeout(() => {
              if (V2HREF && V2HREF !== null) {
                window.location.href = V2HREF;
                localStorage.removeItem('V2HREF')
              }
              else {
                navigate("/buyMedicines", { replace: true })
              }
            }, 3000);
          }
          if (res.results.razorpay_details.call_back_url) {
            setCallBackUrl(res.results.razorpay_details.call_back_url);
          }
          handlePayment(
            res?.results?.razorpay_details,
            insert_id,
            res?.results?.razorpay_details.call_back_url
          );
        } else {
          console.log(res);
        }
      } catch (error) {
        console.error(error);
      }

    };

    const previewOrderdata = {
      user_id: selectedAddressDetails[0].userid,
      name: selectedAddressDetails[0].name,
      mobile_number: selectedAddressDetails[0].mobile,
      email_id: selectedAddressDetails[0].email,
      address_type: selectedAddressDetails[0].type,
      address: walletData.length > 0 ? walletData[0].address : selectedAddressDetails[0].address,
      streetname: selectedAddressDetails[0].landmark,
      city: selectedAddressDetails[0].city,
      state: selectedAddressDetails[0].state,
      pincode: selectedAddressDetails[0].pincode,
      consultation_charges: "",
      delivery_charges: "",
      cart: newUpdatedMedicine,
      check_use_wallet: walletStatus,
      user_package_id: packageId,
      vas_charges: cartDataTotal,
    };
    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = JSON.stringify(previewOrderdata);

    // const formdata = new FormData();
    // const filePromises = imageData.map((uploadedFile, index) => {
    //   formdata.append(`file[${index}]`, uploadedFile.file);
    //   return convertFileToBase64(uploadedFile.file).then(base64File => ({
    //     name: uploadedFile.file.name,
    //     base64: base64File
    //   }));
    // });

    try {
      if (rxbox) {
        // Assuming prescriptionData and requestOptionsCopy are defined elsewhere
        const formdata = new FormData();
        presciptionData.forEach((uploadedFile, index) => {
          formdata.append(`file[${index}]`, uploadedFile.file);
        });

        const apiUrl = MEDICINE_PREVIEW_API;
        const response = await fetch(apiUrl, requestOptionsCopy);
        const res = await response.json();

        if (res.status) {
          formdata.append("insert_id", res.results.insert_id);

          const imgapiUrl = `${UPLOAD_PRESCRIPTION__API}`;
          const imageresponse = await fetch(imgapiUrl, {
            headers: {
              Authorization: `${token}`,
            },
            method: "POST",
            body: formdata,
          });

          // Handle image upload response
          if (imageresponse.ok) {
            const imageResult = await imageresponse.json(); // or .text() if response type is text
            // Process imageResult if needed
          } else {
            console.error('Image upload failed:', imageresponse.status, imageresponse.statusText);
            // Handle error conditionally as per your requirement
          }

          // Check wallet status and show_button for placing order
          if (walletStatus === "" || res.wallet.show_button === "wallet") {
            placeOrder(res.results.insert_id); // Place order if conditions met
          } else if (res.wallet.show_button === "online") {
            // Handle online button case if needed
          }
        } else {
          console.log(res); // Log the response for debugging
        }
      } else {
        // Handle case when rxbox is false
        const apiUrl = MEDICINE_PREVIEW_API;
        const response = await fetch(apiUrl, requestOptionsCopy);
        const res = await response.json();


        // Handle invalid_amount and other conditions
        if (res.wallet.show_button.length > 0 && res.wallet.show_button === "invalid_amount") {
          toast.error(res.results.data.message);
          setTimeout(() => {
            navigate("/cart", { replace: true });
          }, 2000);
        }

        // Check status and show_button for placing order
        if (res.status) {
          if (walletStatus === "" || res.wallet.show_button === "wallet") {
            placeOrder(res.results.insert_id); // Place order if conditions met
          } else if (res.wallet.show_button === "online") {
            // Handle online button case if needed
          }
        } else {
          console.log(res.data.message); // Log error message if status is false
        }
      }
    } catch (error) {
      console.error("An error occurred:", error); // General error handling
    }

  };


  useEffect(() => {
    localStorage.setItem("SELECTED_TEST", JSON.stringify(selectedTests));

    if (selectedTests && selectedTests.length > 0) {
      selectedTests.map((mypatientData) => {
        const testData = mypatientData?.testIds
          .filter((innerTestData) => innerTestData !== null)
          .map((innerTestData) => ({
            patient_name: mypatientData.patientName,
            patient_age: mypatientData.patient_age,
            patient_gender: mypatientData.patient_gender,
            relation_with_patient: mypatientData.patient_relation,
            testname:
              innerTestData?.test_heading || innerTestData?.package_name,
            package_id: innerTestData?.test_id || innerTestData?.pack_id,
            test_type: "Single",
            discount: "0",
            vendor_id: "3",
            final_price: innerTestData?.test_medi_price,
            mobile_number: JSON.parse(localStorage.getItem("userinfo")).mobile,
            user_id: selectedAddressDetails[0]?.userid,
            qty: "1",
            mb_price: innerTestData?.test_medi_price,
            price: innerTestData?.test_mrp,
            // Add other properties from innerTestData as needed
          }));

        const packData = mypatientData?.packIds
          .filter((innerTestData) => innerTestData !== null)
          .map((innerTestData) => ({
            patient_name: mypatientData.patientName,
            patient_age: mypatientData.patient_age,
            patient_gender: mypatientData.patient_gender,
            relation_with_patient: mypatientData.patient_relation,
            testname: innerTestData?.package_name,
            package_id: innerTestData?.pack_id,
            test_type: "Package",
            discount: "0",
            vendor_id: "3",
            final_price: innerTestData?.pack_medi_price,
            mobile_number: JSON.parse(localStorage.getItem("userinfo")).mobile,
            user_id: selectedAddressDetails[0]?.userid,
            qty: "1",
            mb_price: innerTestData?.pack_medi_price,
            price: innerTestData?.pack_mrp,
            // Add other properties from innerTestData as needed
          }));

        const testEntries = [...testData, ...packData];
        setNewupdatedTest((prevdata) => [...prevdata, ...testEntries]);
      });
    }
  }, [selectedTests, CartData]);

  useEffect(() => {
    setUserProfile(JSON.parse(localStorage.getItem("userinfo")));
  }, [newUpdatedTest]);

  const walletBalanceLab = walletData?.module_wise_pending?.DIAGNOSTICS || 0;
  const walletBalance = walletData?.module_wise_pending?.MEDICINE || 0;
  const totalAmount = mediData?.total_amount || 0;
  const insufficientAmount = walletBalance < totalAmount ? Math.abs(totalAmount - walletBalance).toFixed(2) : 0;
  const insufficientAmountLabtest = walletBalanceLab < totalMrp ? Math.abs(totalMrp - walletBalanceLab).toFixed(2) : 0;

  return (
    <>
      <div className="home-review-banner">
        <div className="app-header-wraper">

          {key === "first" ? (
            <div className="header-top-section placholoder-header-box">
              <div className="header-top-section-category-section">
                <h3>Place Order</h3>
              </div>
              <LocationSection />
              <div className="header-top-section-add-to-cart-section postion-repo placholder-span">
                <button>
                  <img src={appCartIcon} width="100%" alt="Cart-icon" />
                  <span className="Header-count-number">
                    {responseData && responseData.length + +CartData.length}
                  </span>
                </button>
              </div>
              <div className="header-top-section-add-to-cart-section-hert">
                <img src={appHeartIcon} width="100%" alt="heart-icon" />
              </div>
            </div>
          ) : (
            <div className="header-top-section placholoder-header-box">
              <div className="header-top-section-category-section">
                <h3>Place Order</h3>
              </div>
              <div className="header-top-section-add-to-cart-section postion-repo">
                <button>
                  <img src={appCartIcon} width="100%" alt="Cart-icon" />
                  <span className="Header-count-number">
                    {responseData && responseData.length + +CartData.length}
                  </span>
                </button>
              </div>
              <div className="header-top-section-add-to-cart-section-hert">
                <img src={appHeartIcon} width="100%" alt="heart-icon" />
              </div>
            </div>
          )}
        </div>

        <div className="medicine-cover">
          <div className="container medi-cover-repo2">
            <div className="tab-main-cover-wrapper">
              <Tab.Container id="left-tabs-example" defaultActiveKey={key}>
                <Row>
                  <Col lg={12} className="mb-2 tab-cover">
                    <div className="cart-header-tabs d-flex justify-content-center">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="first"
                            onSelect={() => setKey("first")}
                            onClick={() => handleClickTab("first")}
                            className={
                              key === "first" ? "active-link" : "not-active"
                            }
                          >
                            <div className="medicine-box">
                              <div className="medi-image">
                                <img
                                  src={appTabletMed}
                                  width="100%"
                                  alt="Tablet-capsule"
                                />
                              </div>
                              <div className="box-heading">
                                <p>
                                  Medicines
                                  {responseData && responseData.length > 0 && `(${responseData.length})`}
                                </p>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second"
                            onSelect={() => setKey("second")}
                            onClick={() => handleClickTab("second")}
                            className={
                              key === "second" ? "active-link" : "not-active"
                            }
                          >
                            <div className="lab-box">
                              <div className="medi-image">
                                <img
                                  src={appLabMed}
                                  width="100%"
                                  alt="Lab-Test"
                                />
                              </div>
                              <div className="box-heading">
                                <p>
                                  Lab Test
                                  {CartData.length > 0 && `(${CartData.length})`}
                                </p>

                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Col>
                  {/* ==============Tab Content=================== */}
                  <Col lg={12} className="placebox">
                    <div className="tab-content-main-cover">
                      <Tab.Content>
                        {/* =======================Medicine Content Tab=================== */}
                        <Tab.Pane eventKey="first">
                          <div className="medicines-tab-content-main-wrapper">

                            {successmodal && successmodal ? (
                              <Deleteloader show={modalShow} />
                            ) : responseData && responseData.length > 0 ? (
                              <div>
                                {rxbox && rxbox ? (
                                  <div className="upload-prescription-wraper place-upload-repo">
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-lg-2"></div>
                                        {presciptionData === null && (
                                          <div className="col-lg-10">
                                            <div className="upload-prescription-right-side">
                                              <h3>
                                                Upload Prescription to place Order
                                              </h3>
                                              <p>What is valid Prescription?</p>
                                              <div className="upload-prescription-right-side-icon-wraper">
                                                <NavLink
                                                  to={`/UploadPrescription?prescriptionId=${prescriptionId}`}
                                                >
                                                  <div className="upload-prescription-right-side-icon">
                                                    <img
                                                      src={appUploadIcon}
                                                      width="100%"
                                                      alt="upload-icon"
                                                    />
                                                  </div>
                                                  <p>Upload Prescription</p>
                                                </NavLink>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className="product-box-repo">
                                  <div className="container">
                                    {responseData &&
                                      responseData.map((storemedicine) => (
                                        <div
                                          className={`row cart-page-item ${loadingData[storemedicine.id]
                                            ? "loading"
                                            : ""
                                            }`}
                                          key={storemedicine.id}
                                        >
                                          <div className="col-lg-4 col-md-4 col-4">
                                            <div
                                              className="product-image"
                                              style={{
                                                backgroundImage: `url(${storemedicine.image ||
                                                  DummyMedicine
                                                  })`,
                                              }}
                                            >
                                              {storemedicine.image !==
                                                undefined &&
                                                storemedicine.image !== "" ? (
                                                <></>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                            <div className="medicine-rx-image">
                                              {storemedicine.rx_required ===
                                                false ? (
                                                <></>
                                              ) : (
                                                <img
                                                  src={appPXImg}
                                                  alt={storemedicine.name}
                                                />
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-lg-8 col-md-8 col-8">
                                            <div className="product-detail">
                                              <div className="product-trash-data">
                                                <div className="product-heading">
                                                  <p
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        storemedicine.name?.replace(
                                                          /(<? *script)/gi,
                                                          "illegalscript"
                                                        ),
                                                    }}
                                                  ></p>
                                                </div>
                                                <div className="trash-icon trash-cart-icon">
                                                  <button
                                                    onClick={() => {
                                                      handleDelete(
                                                        storemedicine.id,
                                                        storemedicine
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      src={appDeleteIcon}
                                                      width="100%"
                                                      alt="Delete"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="product-box">
                                                <div className="product-price">
                                                  <p>
                                                    ₹ {storemedicine.price}
                                                    <span>
                                                      ₹ {storemedicine.mrp}
                                                    </span>
                                                    <p className="min-cart-qty">
                                                      Min Qty:
                                                      {
                                                        storemedicine.min_quantity
                                                      }
                                                    </p>
                                                  </p>
                                                </div>

                                                <div className="tranding-medicine-container">
                                                  {responseData
                                                    .filter(
                                                      (item) =>
                                                        item.id ===
                                                        storemedicine.id
                                                    )
                                                    .map((filteredItem) => (
                                                      <div
                                                        className={`tranding-medicine-add-cart-add trand-data-bar ${loadingData[
                                                          filteredItem.id
                                                        ]
                                                          ? "loading"
                                                          : ""
                                                          }`}
                                                        key={filteredItem.id}
                                                      >
                                                        {loadingData[
                                                          filteredItem.id
                                                        ] && (
                                                            <p>
                                                              <Mediloader />
                                                            </p>
                                                          )}
                                                        {!loadingData[
                                                          filteredItem.id
                                                        ] &&
                                                          filteredItem.qty >
                                                          0 && (
                                                            <>
                                                              <div
                                                                className="image-add-icon"
                                                                onClick={() =>
                                                                  handleDecrement(
                                                                    filteredItem
                                                                  )
                                                                }
                                                              >
                                                                <p>-</p>
                                                              </div>
                                                              <span>
                                                                {
                                                                  filteredItem.qty
                                                                }
                                                              </span>
                                                              <div
                                                                className="image-add-icon"
                                                                onClick={() =>
                                                                  handleIncrement(
                                                                    filteredItem
                                                                  )
                                                                }
                                                              >
                                                                <p>+</p>
                                                              </div>
                                                            </>
                                                          )}
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {storemedicine.rx_required === true && presciptionData !== null && (
                                            <div className="col-lg-12 upload-strip">
                                              <div className="presciption-strip">
                                                <p>Presciption Uploaded</p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    <hr />
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="add-more-btn">
                                          <p>Add More Items</p>
                                          <NavLink to={"/buymedicines"}>
                                            <button>
                                              <span>+</span>
                                            </button>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {userPackageList.length > 0 && (
                                  <div className="coupen-box-cover">
                                    <p>Please Select the Package</p>

                                    <Select
                                      name="time"
                                      id="time"
                                      className="coupen-box"
                                      options={userPackageList.map(
                                        (packageList) => ({
                                          value: packageList.package_name,
                                          label: packageList.package_name,
                                          id: packageList.id,
                                        })
                                      )}
                                      defaultValue={
                                        userPackageList.length > 0
                                          ? {
                                            value:
                                              userPackageList[0].package_name,
                                            label:
                                              userPackageList[0].package_name,
                                            id: userPackageList[0].id,
                                          }
                                          : null
                                      }
                                      placeholder={
                                        userPackageList.length > 0
                                          ? userPackageList[0].package_name
                                          : "Select Package"
                                      }
                                      onChange={handlePackageSelectChange}
                                    />
                                  </div>
                                )}
                                {walletData?.module_wise_pending?.MEDICINE > 0 && (
                                  <>
                                    <div className="medi-wallet">
                                      <div className="left-wallet">
                                        <div className="checkbox">
                                          <input
                                            type="checkbox"
                                            checked={walletStatus === "1"}
                                            onChange={() =>
                                              setWalletStatus(
                                                walletStatus === "1" ? "" : "1"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="wallet-heading">
                                          <h5>Medibhai Wallet</h5>
                                          <p>
                                            Remaining Balance : ₹{" "}
                                            {
                                              walletData.module_wise_pending
                                                .MEDICINE
                                            }
                                          </p>
                                        </div>
                                      </div>

                                    </div>
                                    {walletStatus === '1' && walletBalance < totalAmount && (
                                      <div className="wallet-heading-insu">
                                        <p>
                                          Your wallet balance is insufficient ₹ {insufficientAmount} payment is required online
                                        </p>
                                      </div>
                                    )}
                                  </>
                                )}
                                <>
                                  <div className="payment-summery-wrapper">
                                    {cartDataTotal.map((item, index) => (
                                      <div key={index} className="pay-box">
                                        <p>{item.display_text}</p>
                                        <p
                                          style={{
                                            color:
                                              index === 1 || index === 2
                                                ? "#000000"
                                                : "#0CB875",
                                          }}
                                        >
                                          {item.amount}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="deliver-box">
                                    <div className="deliver-content-box">
                                      <div className="deliver-icon">
                                        <img
                                          src={appDeliverLocation}
                                          width="100%"
                                          alt="location"
                                        />
                                      </div>
                                      <div className="deliver-content">
                                        <p>
                                          Deliver to{" "}
                                          {handleAddressData[0]?.type},{" "}
                                          {handleAddressData[0]?.address},{" "}
                                          {handleAddressData[0]?.pincode},{" "}
                                          {handleAddressData[0]?.city},
                                          {handleAddressData[0]?.state},
                                        </p>
                                      </div>
                                    </div>
                                    <div className="deliver-change">
                                      <NavLink to="/addressdetail">
                                        <p>Change</p>
                                      </NavLink>
                                    </div>
                                  </div>

                                  <div className="address-box place-order-address-box">
                                    <div className="total-box">
                                      <p>
                                        Total{" "}
                                        <span>
                                          {
                                            cartDataTotal[
                                              cartDataTotal.length - 1
                                            ]?.amount
                                          }
                                        </span>
                                      </p>
                                      <p className="item">
                                        {responseData && responseData.length}{" "}
                                        Item(s)
                                      </p>
                                    </div>
                                    <div className="address-box-repo placed-order">
                                      <NavLink>
                                        <button
                                          onClick={handleShow}
                                          disabled={loadingHomeData}
                                        >
                                          <p>Place Order</p>
                                        </button>
                                      </NavLink>
                                    </div>
                                  </div>
                                </>
                              </div>
                            ) : (
                              <div className="medicines-empty-cart-cover">
                                <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
                                  <div className="empty-cart-image">
                                    <img
                                      src={appEmptyCart}
                                      width="100%"
                                      alt="EmptyCart"
                                    />
                                  </div>
                                  <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                                    <p>Your Medicine Cart is empty</p>
                                    <NavLink to="/buymedicines">
                                      <button>
                                        <p>ADD MEDICINES</p>
                                      </button>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Tab.Pane>
                        {/* /* =======================Diagnostics Content Tab=================== */}
                        <Tab.Pane eventKey="second">
                          <div className="lab-test-tab-content-box">
                            <>
                              {selectedTests.length === 0 ? (
                                <div className="medicines-empty-cart-cover">
                                  <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
                                    <div className="empty-cart-image">
                                      <img
                                        src={appLabCart}
                                        width="100%"
                                        alt="EmptyCart"
                                      />
                                    </div>
                                    <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                                      <p>Your Cart is empty</p>
                                      <NavLink to="/diagnostictest">
                                        <button>
                                          <p>ADD TESTS</p>
                                        </button>
                                      </NavLink>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="number-of-package-content">
                                    <p>
                                      {selectedTests.length} Health Package / Lab
                                      Test
                                    </p>
                                  </div>
                                  <div className="patient-test-details-wrapper">
                                    {selectedTests.length > 0 &&
                                      selectedTests.map((testData, index) => {
                                        return (
                                          <div
                                            className="patient-full-detail-payment"
                                            key={index}
                                          >
                                            <div className="patient-name-heading">
                                              <div className="patient-full-name">
                                                <p>{testData.patientName}</p>
                                              </div>
                                              <div className="patient-delete-icon-pay">
                                                <button
                                                  onClick={() =>
                                                    handleCartTestData(
                                                      testData.patientId
                                                    )
                                                  }
                                                >
                                                  <FaRegTrashCan />
                                                </button>
                                              </div>
                                            </div>
                                            <hr />
                                            {testData.testIds.length > 0 &&
                                              testData.testIds.map((data) => {
                                                if (data !== null) {
                                                  return (
                                                    <div
                                                      className="patient-package-detail-cover"
                                                      key={data?.test_id}
                                                    >
                                                      <div className="package-icon">
                                                        <img
                                                          src={appPackageIcon}
                                                          width="100%"
                                                          alt="review"
                                                        />
                                                      </div>
                                                      <div className="package-details-content">
                                                        <p>{data?.test_heading}</p>
                                                        <span>
                                                          Included Tests :{" "}
                                                          {
                                                            data?.test_parameter
                                                              .length
                                                          }
                                                        </span>
                                                      </div>
                                                      <div className="package-price-boxs order-price">
                                                        <p>
                                                          ₹ {data?.test_medi_price}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  );
                                                }

                                                // If matchingCartItem is not found, you may want to handle this case
                                                return null;
                                              })}

                                            {testData.packIds.length > 0 &&
                                              testData.packIds.map((data) => {
                                                if (data !== null) {
                                                  return (
                                                    <div
                                                      className="patient-package-detail-cover"
                                                      key={data?.pack_id}
                                                    >
                                                      <div className="package-icon">
                                                        <img
                                                          src={appPackageIcon}
                                                          width="100%"
                                                          alt="review"
                                                        />
                                                      </div>
                                                      <div className="package-details-content">
                                                        <p>
                                                          {data?.package_name}
                                                        </p>
                                                        <span>
                                                          Included Tests :{" "}
                                                          {
                                                            data?.test_parameter
                                                              .length
                                                          }
                                                        </span>
                                                      </div>
                                                      <div className="package-price-boxs order-price">
                                                        <p>
                                                          ₹{" "}
                                                          {
                                                            data?.pack_medi_price
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>
                                                  );
                                                }

                                                // If matchingCartItem is not found, you may want to handle this case
                                                return null;
                                              })}
                                          </div>
                                        );
                                      })}
                                  </div>

                                  <div className="patient-full-address-box">
                                    <div className="patient-address-heading">
                                      <h4>Home Address</h4>
                                      <NavLink to="/lab-addressdetails">
                                        <p>Change</p>
                                      </NavLink>
                                    </div>
                                    {selectedAddressDetails.map((DetailsData) => {
                                      return (
                                        <>
                                          <div className="patient-address-from">
                                            <p>{DetailsData.name}</p>
                                            <p>
                                              {DetailsData.mobile}{" "}
                                              <span>{DetailsData.email}</span>
                                            </p>
                                            <p>{DetailsData.landmark}</p>
                                            <p>
                                              <span>{DetailsData.pincode}</span>
                                              {", "}
                                              <span>{DetailsData.city}</span>
                                              {","}
                                              <span>{DetailsData.state}</span>
                                              {""}
                                            </p>
                                          </div>
                                          <div className="patient-time-date">
                                            <p>
                                              <span>Date : </span>
                                              {selectedTestDate}
                                            </p>
                                            <p>
                                              <span>Time : </span>{" "}
                                              {selectedTimeSlot}
                                            </p>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div className="e-repost">
                                    <img
                                      src={ereportsIcon}
                                      width="100%"
                                      alt="review"
                                    />
                                    <p>
                                      Get E-Reports on your registered Email-id
                                    </p>
                                  </div>
                                  <div className="coupen-box-cover">
                                    {userPackageList.length > 0 && (
                                      <Select
                                        name="time"
                                        id="time"
                                        className="coupen-box"
                                        options={userPackageList.map(
                                          (packageList) => ({
                                            value: packageList.package_name,
                                            label: packageList.package_name,
                                            id: packageList.id,
                                          })
                                        )}
                                        defaultValue={
                                          userPackageList.length > 0
                                            ? {
                                              value:
                                                userPackageList[0].package_name,
                                              label:
                                                userPackageList[0].package_name,
                                              id: userPackageList[0].id,
                                            }
                                            : null
                                        }
                                        placeholder={
                                          userPackageList.length > 0
                                            ? userPackageList[0].package_name
                                            : "Select Package"
                                        }
                                        onChange={handlePackageSelectChange}
                                      />
                                    )}
                                  </div>

                                  {walletData?.module_wise_pending?.DIAGNOSTICS >
                                    0 && (
                                      <>
                                        <div className="medi-wallet">
                                          <div className="left-wallet">
                                            <div className="checkbox">
                                              <input
                                                type="checkbox"
                                                checked={walletStatus === "1"}
                                                onChange={() =>
                                                  setWalletStatus(
                                                    walletStatus === "1" ? "" : "1"
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="wallet-heading">
                                              <h5>Medibhai Wallet</h5>
                                              <p>
                                                Remaining Balance : ₹{" "}
                                                {
                                                  walletData.module_wise_pending
                                                    .DIAGNOSTICS
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        {walletStatus === '1' && walletBalanceLab < totalMrp && (
                                          <div className="wallet-heading-insu">
                                            <p>
                                              Your wallet balance is insufficient ₹ {insufficientAmountLabtest} payment is required online
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    )}

                                  <div className="medibhai-payment-summary mb-4">
                                    <div className="medibhai-payment-summary-container">
                                      <h4>Payment Summary</h4>
                                      <div className="payment-summary-list">
                                        <p>Cart Total</p>
                                        <p>₹ {cartTotal}</p>
                                      </div>
                                      <div className="payment-summary-list">
                                        <p>Discount Applied</p>
                                        <p>- ₹ {cartTotal - totalMrp}</p>
                                      </div>
                                      <div className="payment-summary-list">
                                        <p>Price After Discount</p>
                                        <p>₹ {totalMrp}</p>
                                      </div>
                                      <div className="payment-summary-list">
                                        <p>Collection Charges <span>
                                          <a
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="Additional 150 Home collection charges will be added with offer rate less than 300"
                                            data-tooltip-place="top"

                                          >
                                            <IoInformationCircle />
                                          </a>
                                          <Tooltip id="my-tooltip"
                                            style={{ width: "220px" }}
                                          />

                                        </span></p>
                                        {totalMrp <= 300 ? (
                                          <p>+ ₹ 150</p>
                                        ) : (
                                          <p>+ ₹ 0</p>
                                        )}
                                      </div>

                                      <hr className="payment-summary-list-dash" />
                                      <div className="payment-summary-list total-payable">
                                        <p>Total Payable</p>
                                        <p>
                                          ₹{" "}
                                          {totalMrp <= 300
                                            ? totalMrp + 150
                                            : totalMrp}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="payment-summary-list total-saving">
                                      <p>Total Savings</p>
                                      <p>₹ {cartTotal - totalMrp}</p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
        <ConfirmModal handleClose={handleClose} formShow={formShow} onSubmit={key && key === "second" ? handlePlaceOrder : handleMedicinePlaceOrder} />
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "custom-toast",
            duration: 3000,
            style: {
              background: "#fff",
              color: "#363636",
              fontSize: "14px",
              width: "300px",
              top: "55px !important",
            },
          }}
        />
        {selectedTests.length > 0 && key === "second" && (
          <GotoCart
            total={totalMrp <= 300 ? totalMrp + 150 : totalMrp}
            CartData={CartData}
            TestCartData={selectedTests}
            link="diagnostictest"
            handlePlaceOrder={handlePlaceOrder}
            handleShow={handleShow}
          />
        )}
      </div>
      {paymentAnimation && (
        <PaymentModal
          show={isPaymentModalOpen}
          successMessage={paymentMsg}
          paymentAnimation={paymentAnimation}
        />
      )}

      {isPaymentLoading && (
        <div className="payment-loading-page">
          <PaymentLoader />
        </div>
      )}
      {successPaymentmodal && (
        <SuccessFull show={modalShow} successMessage={successMessage} animationtype={successPaymentmodal} />
      )}

      {successmodal && <Deleteloader show={modalShow} />}
    </>
  );
};

export default MainCart;
