import React, { useEffect, useState } from "react";
import { headerLogo } from "../../images";
import useQuery from "../../CustomHooks/useQuery";
import Lottie from "lottie-react";
import YogasessionJson from "../../AnimationJson/yogasession.json";
import Modal from 'react-bootstrap/Modal';
import { bookSlot, eveningSlot, morningSlot, yogaBanner } from "../Medicine/Images";
import { FaSun } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { yoga_session } from "../../Redux/actions/PostApiCall";
import useLocationState from "../Medicine/hooks/useLocationState";
import Medicine_Success from "../Medicine/animation/Medicine_Success";
import LoaderAnime from "../Medicine/animation/prescription_loader";
import { useNavigate } from "react-router";

const YogaSession = () => {
  const title = useQuery("title");
  const [successmodal, setSuccessModal] = useState(false);
  const [modalShow, setModalShow] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState(null);
  const YogaData = useLocationState();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const yoga_response = useSelector(state => state.yoga_session_response);


  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot); // Only one slot can be selected
  };

  const slotData = [
    {
      name: 'Morning Slot',
      type: "Morning",
      time: '10:00 AM To 10:30 AM',
      icon: morningSlot,
    },
    {
      name: 'Evening Slot',
      type: "Evening",
      time: '05:00 PM To 06:30 PM',
      icon: eveningSlot,
    },
  ];

  const handleBookYoga = () => {
    setModalShow(true)
    if (selectedSlot) {
      const data = {
        package_id: YogaData?.package_id,
        timing: selectedSlot.time,
        slot: selectedSlot.type,
      };
      dispatch(yoga_session(data));
    }
  };

  useEffect(() => {
    if (yoga_response?.status === "success") {
      setModalShow(false)
      setSuccessModal(true)
      setTimeout(() => {
        navigate("/home")
      }, 2000);
    }
  }, [yoga_response]);

  return (
    <>
      <div className="home-review-banner">
        {modalShow && <LoaderAnime showModal={modalShow} />}
        <div className="home-app-header">
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p className="ml-3">Yoga / Zumba Session</p>
            </div>
          </div>
        </div>

        <div className="yoga-repo-wrapper-clover">
          <div className="image-box-clover">
            <img src={yogaBanner} alt="" />
          </div>
        </div>

        <div className="booking-container">
          <h3 style={{ marginBottom: "2rem" }}><img src={bookSlot} alt="" /> Book Online Session</h3>
          {slotData.map((item, index) => (
            <div
              className="slot-prefix"
              onClick={() => handleSlotSelect(item)}
              key={index}
            >
              <input
                type="checkbox"
                checked={selectedSlot?.name === item.name}
                readOnly
              />
              <img src={item.icon} alt="" />
              <div className="slot-info">
                <h4>{item.name}</h4>
                <p>{item.time}</p>
              </div>
            </div>
          ))}

          <button
            className="book-button"
            onClick={handleBookYoga}
            disabled={!selectedSlot} // Enable button only if a slot is selected
          >
            BOOK SLOT
          </button>
        </div>

        {/* Success Modal */}
        {yoga_response && <Medicine_Success showModal={successmodal} message={yoga_response?.message} status={yoga_response?.success} />}
      </div>
    </>
  );
};


export default YogaSession;

