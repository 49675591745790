import React, { useEffect } from 'react';
import { Cart_Icon, Header_Background } from '../Images';
import LocationSection from '../../LocationSection/LocationSection';
import '../Medicine_Style/header.css';
import Medicine_Location from './Medicine_Location';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { FiSearch } from "react-icons/fi";
import toast from 'react-hot-toast';
import { getQueryParams } from '../../../Redux/actions/GetApiCall';

const Medicine_Header = ({ Title, state, cartData, labCartCount, prescrip, radio }) => {
    const navigate = useNavigate();
    const WebLink = window.location.pathname;
    const cartList = useSelector(state => state.medicine_cart_list);
    const CartData = cartList?.data

    const handleSearch = () => {
        const dataState = {
            Title: "Search Product",
            Link: "buymedicines"
        }
        navigate('/search-medicine', { state: dataState })
    }

    const cartTotal = CartData?.total_items || CartData?.total_item;
    const handleBack = () => {
        if (WebLink === "/medicines-address") {
            window.history.back();  // Correct way to navigate back
        } else {
            navigate(`/buymedicines`);  // Navigate to a specific link
        }
    };

    const handleCartLink = () => {
        if (radio) {
            navigate("/radiology-cart")
        } else {
            const tabkeyUrl = prescrip == "diagnostics" ? prescrip : "medicines"
            const tabkeyData = {
                cartdata: CartData,
                tabkey: tabkeyUrl
            }
            navigate('/cart', { state: tabkeyData });
        }
    };

    const cartResponseStatus = useSelector(state => state.diagnostic_cart_add);

    useEffect(() => {
        if (cartResponseStatus?.status === "failure") {
            toast.error(cartResponseStatus?.error);
        }
    }, [cartResponseStatus]);

    const currentUrl = window.location.href; // Get the current URL
    const { auto } = getQueryParams(currentUrl); // Get the 'auto' parameter from URL

    return (
        <div className="medicine-header sticky-lg-top sticky-md-top sticky-top">
            <div className="images-box" style={{ backgroundImage: `url(${Header_Background})` }}>
                <div className="header-box-repo-data">
                    {Title ? (
                        <div className="data-box-repo-store">
                            {(state?.Title === 'Search Product' || WebLink === "/medicines-address") && (
                                <button onClick={handleBack}><FaChevronLeft /></button>
                            )}
                            <div className="header-title">
                                <h6>{Title}</h6>
                            </div>
                        </div>
                    ) : (
                        <Medicine_Location />
                    )}

                    {!(state?.Title === 'Search Product' ||
                        (WebLink === "/medicines-address" || WebLink === "/address-list" ||
                            Title === "Call a doctor" || WebLink === "/patients" ||
                            WebLink === "/my-package-details" || WebLink === "/my-wallet-utilization" ||
                            WebLink === "/order-consultation" || WebLink === "/health-records" ||
                            WebLink === "/help" || WebLink === "/date-timeslot" || WebLink === "/radio-date-timeslot" ||
                            WebLink === "/privacy-policy" || WebLink === "/terms-conditions")
                    ) && (
                            <div className='right-header-data'>
                                {(WebLink === "/recent-products" ||
                                    WebLink === "/medicine-category" ||
                                    WebLink === "/health-corner") && (
                                        <div className="search-icon-repo" onClick={handleSearch}>
                                            <FiSearch />
                                        </div>
                                    )}

                                {!auto && WebLink !== "/medicine-place-order" && (
                                    <div className="cart-icon-repo" onClick={handleCartLink}>
                                        <img src={Cart_Icon} alt="cart-icon" />
                                        {(labCartCount || cartTotal) && (
                                            prescrip === "diagnostics" ? (
                                                <p className="count-cart-text">
                                                    {labCartCount || 0}
                                                </p>
                                            ) : (
                                                <p className="count-cart-text">
                                                    {cartTotal}
                                                </p>
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default Medicine_Header;
