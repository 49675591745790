import React, { useEffect, useState } from 'react';
import Medicine_Header from '../Components/Medicine_Header';
import '../Medicine_Style/doctor.css';
import useLocationState from '../hooks/useLocationState';
import { useDispatch, useSelector } from 'react-redux';
import { callDoctor } from '../../../Redux/actions/PostApiCall';
import { fetchPackageList } from '../../../Redux/actions/GetApiCall';
import { useForm } from 'react-hook-form';
import Medicine_Success from '../animation/Medicine_Success';
import { useNavigate } from 'react-router-dom';
import LoaderAnime from "../animation/prescription_loader";

const Medicine_Doctor = () => {
    const Title = useLocationState();
    const naviagte = useNavigate();
    const dispatch = useDispatch();
    const packageData = useSelector(state => state.package_list);
    const doctorData = useSelector(state => state.call_doctor);
    console.log(doctorData)
    const userData = packageData?.data?.profile_details;
    const [successModal, setSuccessModal] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        const fetchData = async () => {
            if (!userData) {
                await dispatch(fetchPackageList());
            } else {
                setValue('name', userData.name || '');
                setValue('mobile_number', userData.mobile_number || '');
                setValue('email_id', userData.email_id || '');
            }
        };

        fetchData();
    }, [dispatch, userData]);


    const onSubmit = async (data) => {
        setShowModal(true)
        const payload = {
            name: data.name,
            mobile_no: data.mobile_number,
            email_id: data.email_id,
            remark: data.remark,
        };
        await dispatch(callDoctor(payload));
    };

    useEffect(() => {
        if (doctorData && doctorData?.data?.success === true) {
            setShowModal(false);
            setSuccessModal(true)
            setTimeout(() => {
                setSuccessModal(false)
                naviagte('/buyMedicines')
                window.location.reload();
            }, 1500);
        }
        else if (doctorData && doctorData?.status === "failure") {
            setShowModal(false);
            setSuccessModal(true)
            setTimeout(() => {
                setSuccessModal(false)
                naviagte('/call-doctor')
                // window.location.reload();
            }, 1500);
        }
    }, [doctorData])

    return (
        <div className="home-review-banner">
            <Medicine_Header Title={Title} />

            {showModal && <LoaderAnime showModal={showModal} />}

            <div className="doctor-text-repo">
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="doctor-form-cover-repo">
                <div className="input-cover-form">
                    <label>Name</label>
                    <input
                        type="text"
                        placeholder='Enter Name'
                        {...register('name', { required: 'Name is required' })}
                    />
                    {errors.name && <span className="error">{errors.name.message}</span>}
                </div>
                <div className="input-cover-form">
                    <label>Mobile Number</label>
                    <input
                        type="text"
                        placeholder='Enter Mobile Number'
                        {...register('mobile_number', {
                            required: 'Mobile number is required',
                            pattern: {
                                value: /^[0-9]{10}$/,
                                message: 'Mobile number must be 10 digits'
                            }
                        })}
                    />
                    {errors.mobile_number && <span className="error">{errors.mobile_number.message}</span>}
                </div>
                <div className="input-cover-form">
                    <label>Email Address</label>
                    <input
                        type="text"
                        placeholder='Enter Email Address'
                        {...register('email_id')}
                        readOnly
                    />
                </div>
                <div className="input-cover-form">
                    <label>Remark</label>
                    <textarea
                        placeholder="Remark"
                        {...register('remark', {
                            required: 'Remark is required',
                            maxLength: {
                                value: 200,
                                message: 'Remark cannot exceed 200 characters'
                            }
                        })}
                    />
                    {errors.remark && <span className="error">{errors.remark.message}</span>}
                </div>

                <div className="doctor-submit-btn">
                    <button type="submit">SUBMIT</button>
                </div>
            </form>

            <Medicine_Success showModal={successModal} message={doctorData?.message || doctorData?.error} status={doctorData?.data?.success || doctorData?.status} />
        </div>
    );
};

export default Medicine_Doctor;
