// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import SuccessFully from "../Pages/Medicine/animation/pending-anime.json";


const Pending = () => {
    
    return (
        <Lottie animationData={SuccessFully} className='no-doctor-found' />
    )
}

export default Pending;
