import React from 'react'
import SuccessPack from '../../../Animation/SuccessPack'
import { appCommonlyBookTest } from '../../../images'

const ThankRadiology = ({ ThankData }) => {
    
    return (
        <div>
            <div className='thank-rep-wrapper'>
                {ThankData?.order_status === "Booked" && (
                    <div className="order-status-data-repo-wrapper">
                        <SuccessPack />
                        <p>{ThankData?.order_message}</p>
                        <span className='book-time-date-te'>Booked on {ThankData?.booked_at}</span>
                    </div>
                )}

                <div className="booked-test-wrapper repo-cover-thank-data mt-3" style={{marginBottom:'108px'}}>
                    {
                        ThankData?.members?.map((item, index) => (
                            <div className="booked-wrapper-repo-cover-box" key={index}>
                                <div className="patient-details-wrapper-oreder">
                                    <p className='patien-text-de'>Patient Details</p>
                                    <p>Patient Name : {item?.member_name}</p>
                                    <div className="patient-details-age-wrapper">
                                        <p>Age : {item?.member_age}</p>
                                        <p>Gender : {item?.member_gender}</p>
                                    </div>
                                </div>
                                <hr className='patient-lab-data-hr' />

                                <div className="appoint-date-repo-wrapper">
                                    <div className="appointment-date-repo">
                                        <p>Appointment Date</p>
                                        <p className='bond-text-repo'>{ThankData?.user_details?.selected_date}</p>
                                    </div>
                                    <div className="appointment-date-repo">
                                        <p>Appointment Time</p>
                                        <p className='bond-text-repo'>{ThankData?.user_details?.time_slot}</p>
                                    </div>
                                </div>

                                {item?.diagnosticData?.map((itembox) => (
                                    <>
                                        <div className="test-data-content-box">
                                            <img src={appCommonlyBookTest} alt="" />
                                            <div className="test-name-data-content">
                                                <span>{itembox?.name}</span>
                                                <p>Test Includes({itembox?.parameter_count})</p>
                                            </div>
                                        </div>
                                        {index !== item?.diagnosticData.length - 1 && <hr />}
                                    </>
                                ))}

                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ThankRadiology