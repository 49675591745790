import React, { useEffect } from "react";
import ProfileHeader from "../Components/Header";
import Loader from '../../../Animation/Loader';
import { useDispatch , useSelector} from "react-redux";
import { getTerms } from "../../../Redux/actions/GetApiCall";
import AppHeader from "../../Medicine/Components/Medicine_Header"

const TermsCondition = () => {
  const dispatch = useDispatch();

  const terms_condition_response = useSelector((state) => state?.terms_condition_response)

  useEffect(() =>{
    dispatch(getTerms());
  },[dispatch]);


  return (
    <>
      <div className="home-review-banner">
        <AppHeader Title="Terms & Conditions" />
        <div className="terms-condition-main-wrapper-cover">
          {
              terms_condition_response?.data?.html_content ? (
          <div dangerouslySetInnerHTML={{ __html: terms_condition_response?.data?.html_content }} />
              ) : <Loader/>
          }
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
