import React, { useState } from 'react';
import useLocationState from '../Medicine/hooks/useLocationState';
import { headerLogo } from '../../images';
import { hospitalIcon } from '../Medicine/Images';
import { FaBuilding } from 'react-icons/fa';
import { IoLocationSharp } from "react-icons/io5";
import { Swiper, SwiperSlide } from 'swiper/react';
import { DoctorData } from '../../data';
import DoctorCard from './DoctorCard';

const HospitalDetails = () => {
    const Data = useLocationState();
    const [activeTab, setActiveTab] = useState("Overview");
    const [selectedCategory, setSelectedCategory] = useState('All');

    // Function to switch tabs
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const categories = ['All', ...new Set(DoctorData.map(doctor => doctor.category))];

    // const filteredDoctorsBySearch = DoctorData.filter(doctor =>
    //     doctor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     doctor.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     doctor.city.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const filteredDoctors = DoctorData.filter(doctor => {
        if (selectedCategory === 'All') {
            return true;
        }
        return doctor.category === selectedCategory;
    });


    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Hospital Details</p>
                    </div>
                </div>
            </div>

            {/* Hospital Basic Info */}
            <div className="doctor-data-repo" style={{ paddingTop: '0px' }}>
                <div className="hospital-tepo-image">
                    <img src={hospitalIcon} alt="Hospital Icon" />
                </div>
                <div className="doctor-data-box-tepo">
                    <h5 style={{ marginBottom: '0px' }}>{Data?.hospital_name}</h5>
                    <p style={{ fontSize: "12px", color: "#757575", marginBottom: '5px' }}>{Data?.speciality}</p>
                    <p className="doctor-category"><IoLocationSharp /> {Data?.city}</p>
                    <p className="doctor-category"><FaBuilding /> {Data?.address}</p>
                </div>
            </div>

            {/* Timing Data */}
            <div className="consultation-timing-wrapper-box">
                
            </div>

            {/* Tab Navigation */}
            <div className="tab-navigation">
                <button onClick={() => handleTabClick("Overview")} className={activeTab === "Overview" ? "active-tab" : ""}>Overview</button>
                <button onClick={() => handleTabClick("Doctors")} className={activeTab === "Doctors" ? "active-tab" : ""}>Doctors(2)</button>
                <button onClick={() => handleTabClick("Services")} className={activeTab === "Services" ? "active-tab" : ""}>Services</button>
            </div>

            {/* Tab Content */}
            <div className="tab-content">
                {activeTab === "Overview" && (
                    <div className='about-repo-control'>
                        <h5>About {Data?.hospital_name}</h5>
                        <p>{Data?.about}</p>
                        <p><strong style={{ color: "black" }}>Important Info:</strong> {Data?.important_data}</p>
                        <p><strong style={{ color: "black" }}>Timings:</strong> {Data?.timings.days} - {Data?.timings.time}</p>
                    </div>
                )}

                {activeTab === "Doctors" && (
                    <div>
                        <div className="filter-buttons">
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={2}
                                effect="slide"
                                grabCursor={true}

                            >
                                <div className="swiper-wrapper">
                                    {categories.map((category, index) => (
                                        <SwiperSlide
                                            key={index}
                                            className={`repo-cate-doc ${selectedCategory === category ? 'btn-active' : ''}`}
                                            onClick={() => setSelectedCategory(category)}
                                        >
                                            {category}
                                        </SwiperSlide>
                                    ))}
                                </div>
                            </Swiper>
                        </div>

                        <div className="doctor-grid">
                            {filteredDoctors?.length === 0 && (
                                <div className="no-doctor-found" style={{ height: '60vh', display: 'flex', alignItems: 'center' }}>
                                    <p style={{ fontSize: '18px', fontWeight: '500' }}>No Doctor Found</p>
                                </div>
                            )}
                            {filteredDoctors.map((doctor, index) => (
                                <DoctorCard key={index} doctor={doctor} />
                            ))}
                        </div>

                    </div>
                )}

                {activeTab === "Services" && (
                    <div>
                        <h5>Services</h5>
                        <p>Speciality: {Data?.speciality}</p>
                        {/* Add more specific services here if available */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default HospitalDetails;
