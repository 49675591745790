import React from 'react'
import { headerLogo } from '../../../images'
import '../Medicine_Style/eye.css'
import { EyeBanner, EyeImage, EyeImage2, EyeImage3 } from '../Images'
import { useNavigate } from 'react-router'
import useLocationState from '../hooks/useLocationState'

const Eye = () => {
    const naviagte = useNavigate();
    const PackData = useLocationState()
    const handleEyeForm = (item) => {
        const data = {
            item: item,
            type: "Eye",
            pack: PackData?.package_id
        }
        naviagte("/eye-form", { state: data })
    }
    const partners = [
        { name: "Centre For Sight", image: EyeImage2 },
        { name: "Dr Agarwals Clinic", image: EyeImage3 },
        { name: "Shree Ramkrishna Netralaya", image: EyeImage }
    ];
    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Eye</p>
                    </div>
                </div>
            </div>

            <div class="banner">
                <img src={EyeBanner} alt="" />
            </div>

            <div class="partners-section">
                <div className="part-repo-text-dot">
                    <div className="dot-line"></div>
                    <h3>Our Partners</h3>
                    <div className="dot-line"></div>
                </div>
                <div class="partners-logos">
                    {partners.map((item) => (
                        <div class="partner-card" onClick={() => handleEyeForm(item)}>
                            <img src={item?.image} alt={item.name} />
                            <p>{item?.name}</p>
                        </div>
                    ))}
                </div>
            </div>


        </div>
    )
}

export default Eye