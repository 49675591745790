import React from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { ArrowBackButton, validateNumberInput } from '../../utils/AllFunction'
import { ArrowBack, MetBottleImage, appLocationAmbulance, appSuperWatch, headerLogo } from '../../images'
import { useEffect } from 'react'
import { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { MdArrowRightAlt } from "react-icons/md";
import Loader from '../../Animation/Loader'
import SuccessFull from '../../Animation/SuccessFull'
import { AppContext } from "../../ContextApi";
import { HOME_DASHBOARD_SCREEN_API, REQUESTOPTIONS, SUPER_WATCH_API, YOGA_MET_API } from '../../Constants'
import ConfirmModal from "../MyProfile/Components/ConfirmModal";
import toast, { Toaster } from 'react-hot-toast'
import useLocationState from '../Medicine/hooks/useLocationState'
import { useDispatch, useSelector } from 'react-redux'
import { boatandyoga } from '../../Redux/actions/PostApiCall'
import { useForm } from 'react-hook-form'
import { fetchPincode } from '../../Redux/actions/GetApiCall';
import LoaderAnime from '../Medicine/animation/MediLoader'

const YogaMet = () => {

    const PackData = useLocationState();
    console.log(PackData)

    const [location, setLocation] = useState(null);
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [loadingHomeData, setLoadingHomeData] = useState(true);
    const [showCurrentLocation, setShowCurrentLocation] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [showaddress, setShowAddress] = useState(true);
    const [liveLocation, setLiveLocation] = useState(null)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const cityData = useSelector(state => state.medicine_pincode);
    const YogaData = useSelector(state => state.boat_and_yoga);
    console.log(YogaData?.data)


    const { handleClose, formShow, handleShow } = useContext(AppContext)

    const pincodeValue = watch("pincode");

    useEffect(() => {
        if (pincodeValue?.length === 6) {
            dispatch(fetchPincode(pincodeValue));
        }
    }, [pincodeValue, dispatch]);

    // It Is for LocationAPi
    useEffect(() => {
        const getLocation = async () => {
            try {
                if (navigator.geolocation) {
                    const position = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                resolve(position);
                            },
                            { enableHighAccuracy: true },
                            reject,
                        );
                    });

                    const { latitude, longitude } = position.coords;

                    const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBz8qUh5g91GyggiZf6eNqOC6jKMErllxA`;

                    const response = await fetch(geocodingApiUrl);
                    const data = await response.json();
                    console.log(data)

                    if (data.results && data.results.length > 0) {
                        const fullAddress = data.results[3]?.formatted_address;
                        setLiveLocation(data.results[3]?.address_components)
                        setLocation(fullAddress);
                    } else {
                        console.error('No results from Google Maps Geocoding API:', data);
                    }
                } else {
                    console.error('Geolocation is not supported by this browser.');
                }
            } catch (error) {
                console.error('Error getting location:', error.message);
            } finally {
                setLoadingHomeData(false);
            }
        };
        getLocation();
    }, []);


    const onSubmit = async () => {
        // e.preventDefault();

        const checkbox = document.getElementById('watchlocation');
        if (!checkbox.checked) {
            toast.error("Please Select Address")
            return
        }

        const data = {
            package_id: PackData?.product_data?.package_id,
            product_id: PackData?.product_data?.product_id,
            address: liveLocation?.[0]?.long_name,
            landmark: liveLocation?.[1]?.long_name,
            pincode: liveLocation?.[8]?.long_name,
            city: liveLocation?.[3]?.long_name,
            state: liveLocation?.[6]?.long_name
        }
        try {
            dispatch(boatandyoga(data))

        } catch (error) {
            console.error(error);
        }
    }


    const onSubmitAlternate = async (data) => {
        console.log(data)
        try {

            const alterData = {
                ...data,
                city: cityData?.data?.city,
                state: cityData?.data?.state,
                package_id: PackData?.product_data?.package_id,
                product_id: PackData?.product_data?.product_id,

            }
            dispatch(boatandyoga(alterData))

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        if (YogaData?.data?.success === true || YogaData?.success === false) {
            setSuccessModal(true);
            setSuccessMessage(YogaData?.data?.message)
            setModalShow(true);
            setTimeout(() => {
                setModalShow(false);
                setSuccessModal(false);
                navigate('/home');
                window.location.reload();
            }, 3000)
        }
    },[YogaData])


    const toggleSection = () => {
        setShowCurrentLocation(!showCurrentLocation);
        setShowAddress(false);
    }

    useEffect(() => {
        if (cityData?.data?.city === "" || cityData?.data?.state === "" || cityData?.data?.success === false) {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    }, [cityData, setValue]);

    const height = "75vh"

    return (
        <div className='home-review-banner'>
            <div className="home-app-header nutrition-repo-box">

                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name">
                        <p>{PackData?.product_data?.name}</p>
                    </div>
                </div>
            </div>

            {loadingHomeData && loadingHomeData ? (
                <p><LoaderAnime height={height} /></p>
            ) : (
                <>
                    <div class="ambulance-cover-section">
                        <div class="ambulance-header-image watch-data">
                            <img src={PackData?.product_data?.logo} width="100%" alt="review" />
                            <p>{PackData?.product_data?.name}</p>
                        </div>
                        <div className="watch-description">
                            <p>{PackData?.product_data?.description}</p>
                        </div>
                    </div>
                    <div className="current-location-section">
                        {showCurrentLocation ? (
                            <div className="current-location-box-section">
                                <div className="head-location-section head-text-watch">
                                    <p>Use my current location</p>
                                </div>
                                <div className="current-location-box">
                                    <div className="location-image-box">
                                        <img src={appLocationAmbulance} alt="" /><p>Current Location</p>
                                    </div>
                                    <div className="location-box">
                                        <p>{location}</p>
                                    </div>
                                </div>
                                <div className="same-address-location">
                                    <Form.Check
                                        inline
                                        label="Add above address as delivery address"
                                        name="location"
                                        type="checkbox"
                                        id="watchlocation"
                                    />
                                </div>
                                <div className="super-watch-submit-btn">
                                    <button onClick={handleShow}>Submit</button>
                                </div>
                                <p className="or-text">OR</p>
                                <div className="form-section-button">
                                    <button onClick={toggleSection}>
                                        <p>Add Address Here</p>
                                        <MdArrowRightAlt />
                                    </button>
                                </div>
                                {successmodal && (
                                    <SuccessFull
                                        show={modalShow}
                                        successMessage={successMessage}
                                        animationtype={successmodal}
                                    />
                                )}
                            </div>

                        ) : (
                            <div className="super-watch-form-section">

                                <div className="form-section-box">
                                    <div className="ambulance-form-box">
                                        <form onSubmit={handleSubmit(onSubmitAlternate)}>
                                            <label>Address</label>
                                            <textarea
                                                type="text"
                                                name="address"
                                                {...register('address', { required: 'Address Line 1 is required' })}
                                                placeholder="Enter Your Address"
                                            />
                                            {errors.address && <span className='error-text-msg-met'>{errors.address.message}</span>}

                                            <label>Landmark (Optional)</label>
                                            <input
                                                type="text"
                                                name="landmark"
                                                {...register('landmark')}
                                                placeholder="Enter Landmark"
                                            />

                                            <label>Pincode</label>
                                            <div className="inputcontainer">
                                                <input
                                                    name='pincode'
                                                    placeholder="Enter Pincode"
                                                    {...register("pincode", {
                                                        required: "Pincode is required",
                                                        pattern: {
                                                            value: /^[0-9]{6}$/,
                                                            message: "Invalid pincode"
                                                        },
                                                    })}
                                                    disabled={cityData?.loader === true}
                                                    maxLength={6}
                                                    onInput={validateNumberInput}
                                                />
                                                {cityData?.loader === true && (
                                                    <div className="icon-container">
                                                        <i className="loader"></i>
                                                    </div>
                                                )}
                                            </div>
                                            {cityData?.loader === false && pincodeValue && pincodeValue.length === 6 && (
                                                <p className={cityData?.data?.success ? 'green-repo' : 'red'}>
                                                    {cityData?.data?.message}
                                                </p>
                                            )}

                                            <label>City</label>
                                            <input
                                                disabled
                                                value={cityData?.data?.city}
                                                placeholder="Enter City"
                                            />

                                            <label>State</label>
                                            <input
                                                disabled
                                                value={cityData?.data?.state}
                                                placeholder="Enter State"
                                            />

                                            <button id="submitButton" type="submit" className='sub-meta-btn-repo'>
                                                Submit
                                            </button>
                                        </form>

                                        <p className="or-text">OR</p>
                                        <div className="form-section-button">
                                            <button onClick={toggleSection} className='sub-meta-btn-repo'>
                                                <p>Current Address Here</p>
                                                <MdArrowRightAlt />
                                            </button>
                                        </div>
                                    </div>
                                    {successmodal && (
                                        <SuccessFull
                                            show={modalShow}
                                            successMessage={successMessage}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <ConfirmModal handleClose={handleClose} formShow={formShow} onSubmit={onSubmit} />
                </>
            )
            }

            {successmodal && (
                <SuccessFull show={modalShow} successMessage={YogaData?.data?.message} animationtype={successmodal} />
            )}
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: "custom-toast",
                    duration: 2000,
                    style: {
                        background: "#fff",
                        color: "#363636",
                        fontSize: "14px",
                        width: "300px",
                        top: "55px !important",
                    },
                }}
            />
        </div>
    )
}

export default YogaMet