import { useNavigate } from "react-router-dom";
import { headerLogo } from "../../../images";
import { IoIosSearch } from "react-icons/io";
import SpecialityProduct from "../Components/SpecialityProduct";
import Loader from "../../../Animation/Loader";
import no_tele_found from "../../Medicine/Images/no-tele-found.png";
import { useSelector, useDispatch } from "react-redux";
import { fetchDepartmentsList } from "../../../Redux/actions/GetApiCall";
import { useState, useEffect } from "react";

const SpecialistTeleConsultation = () => {
  const navigate = useNavigate();
  const storedPackageData = JSON.parse(
    localStorage.getItem("PackageSelectData")
  );

  const departmentsListData = useSelector(
    (state) => state.tele_department_list_response
  );
  const [filteredData, setFilteredData] = useState([]);
  const [searchquery, setSearchQuery] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    // if(storedPackageData?.id){
    dispatch(fetchDepartmentsList(storedPackageData?.id));
    // }
  }, []);

  const handleSearch = (e) => {
    const searchquery = e.target.value.toLowerCase();

    if (departmentsListData?.data?.data?.department.length > 0) {
      const searchedData = departmentsListData?.data?.data?.department.filter(
        (teleData) => teleData.name.toLowerCase().includes(searchquery)
      );
      setFilteredData(searchedData);
      setSearchQuery(searchquery);
    } else {
      setFilteredData(null);
    }
  };

  const handleBooking = (data) => {
    navigate(`/book-teleconsultation`, { state: data });
  };

  return (
    <>
      <div className="home-review-banner mb-4">
        <div className="home-app-header d-flex justify-content-start align-items-center">
          <div className="header-logo mx-1">
            <img
              src={headerLogo}
              className="header-medibhai-logo"
              width="100%"
              alt="header-logo"
            />
          </div>
          <div className="home-header-teleconsultation d-flex justify-content-between align-items-center">
            <div className="header-navigation-icon">
              <h6 className="mb-0">Teleconsultation</h6>
            </div>
          </div>
        </div>
        <div className="app-searh-section">
          <div className="inputWithIcon input-tele-search">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => handleSearch(e)}
            />
            <button className="symptom-search-icon-box">
              <IoIosSearch />
            </button>
          </div>
        </div>
        <div className="home-teleconsultation-main-wrapper mt-4 px-2">
          <div className="specialist-doctor-home-wrapper">
            <div className="specialist-header d-flex justify-content-between align-items-center">
              <h6 className="mb-0">Specialist Doctors</h6>
            </div>
            <div className="specialist-product-main-wrapper mt-3 mb-5 px-1">
              {departmentsListData?.loader ? (
                <Loader />
              ) : (
                <>
                  {searchquery.length > 0 ? (
                    <div className="specialist-product-box d-flex justify-content-between">
                      {filteredData && filteredData.length > 0 ? (
                        filteredData
                          .filter(
                            (specialityData) =>
                              searchquery
                                .toLowerCase()
                                .includes("general physician") ||
                              specialityData.is_nutrition === false
                          )
                          .map((specialityData) => (
                            <SpecialityProduct
                              key={specialityData.id}
                              data={specialityData}
                              handleBooking={() =>
                                handleBooking(specialityData)
                              }
                            />
                          ))
                      ) : (
                        <div className="div-data-lab-image">
                          <img src={no_tele_found} />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="specialist-product-box d-flex justify-content-between">
                      <div className="specialist-product-box d-flex justify-content-between">
                        {departmentsListData?.data?.data?.department?.length >
                        0 ? (
                          departmentsListData?.data?.data?.department
                            .filter(
                              (specialityData) =>
                                specialityData.is_nutrition === false
                            )
                            .map((specialityData) => (
                              <SpecialityProduct
                                key={specialityData.id}
                                data={specialityData}
                                handleBooking={() =>
                                  handleBooking(specialityData)
                                }
                              />
                            ))
                        ) : (
                          <div className="div-data-lab-image">
                            <img src={no_tele_found} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialistTeleConsultation;
