// import toast from "react-hot-toast";

export const createApiAction = (actionType, apiCall, options = {}) => {
  return async (dispatch) => {
    dispatch({ type: `${actionType}_REQUEST` });
    try {
      // Call the API and pass the options (which can include request headers)
      const response = await apiCall(options);
      const ResponseToken = response.headers.get("Authorization");
      if (ResponseToken && ResponseToken !== null) {
        localStorage.setItem("APP_TOKEN", ResponseToken);
        window.location.reload();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();

        throw new Error(errorData.message || "Something went wrong");
      }

      const data = await response.json();
      dispatch({ type: `${actionType}_SUCCESS`, payload: data });

      setTimeout(() => {
        dispatch({ type: `${actionType}_IDLE` });
      }, 1000);
    } catch (error) {
      dispatch({ type: `${actionType}_FAILURE`, payload: error.message });
      setTimeout(() => {
        dispatch({ type: `${actionType}_IDLE` });
      }, 1500);
    }
  };
};
