import { useSelector } from "react-redux";
import {
  appArrowBack,
  appPackageDetail,
  appWalletIcon,
  appOrderDetails,
  myHealthRecordIcon,
  appTermCondition,
  appPrivacyPolicy,
  appLogout,
} from "../../../images";
import { NavLink, useNavigate } from "react-router-dom";

const navItems = [
  {
    path: "/my-package-details",
    icon: appPackageDetail,
    label: "Package Details",
    alt: "Package-Detail",
  },
  {
    path: "/my-wallet-utilization",
    icon: appWalletIcon,
    label: "Wallet",
    alt: "wallet",
  },
  {
    path: "/order-details",
    icon: appOrderDetails,
    label: "Order Details",
    alt: "order-detail",
  },
  {
    path: "/reimbursment-details",
    icon: appOrderDetails,
    label: "Reimbursment Details",
    alt: "Reimbursment Details",
  },
  {
    path: "/health-records",
    icon: myHealthRecordIcon,
    label: "Health Record",
    alt: "Health-record",
  },
  {
    path: "/terms-conditions",
    icon: appTermCondition,
    label: "Terms & Condition",
    alt: "term-condition",
  },
  {
    path: "/privacy-policy",
    icon: appPrivacyPolicy,
    label: "Privacy Policy",
    alt: "Privacy-policy",
  },
];

const NavigationList = ({ handleShow }) => {
  const navigate = useNavigate();

  const package_list_box = useSelector((state) => state.package_list);
  console.log(package_list_box?.data)


  const handleReimubursement = () => {
    navigate('/reimbursment-details', { state: package_list_box?.data });
  };

  return (
    <>
      {navItems.map(({ path, icon, label, alt }, index) => (
        <div className="user-cover-list-bar" key={index}>
          {label === "Reimbursment Details" ? (
            <button onClick={handleReimubursement}>
              <div className="user-cover-list-img-box">
                <img src={icon} width="100%" alt={alt} />
                <p>{label}</p>
              </div>
              <div className="user-list-arrow-icon">
                <img src={appArrowBack} width="100%" alt="Back-arrow" />
              </div>
            </button>
          ) : (
            <NavLink to={path}>
              <div className="user-cover-list-img-box">
                <img src={icon} width="100%" alt={alt} />
                <p>{label}</p>
              </div>
              <div className="user-list-arrow-icon">
                <img src={appArrowBack} width="100%" alt="Back-arrow" />
              </div>
            </NavLink>
          )}
        </div>
      ))}
      <div className="user-cover-list-bar">
        <button onClick={handleShow}>
          <div className="user-cover-list-img-box">
            <img src={appLogout} width="100%" alt="logout" />
            <p>Logout</p>
          </div>
          <div className="user-list-arrow-icon">
            <img src={appArrowBack} width="100%" alt="Back-arrow" />
          </div>
        </button>
      </div>
    </>
  );
};

export default NavigationList;
