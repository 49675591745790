import React, { useEffect, useState } from "react";
import {
  ArrowBack,
  appCart,
  appHealthRightArrow,
  appNotification,
  headerLogo,
} from "../../images";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPackageDetailApi } from "../../Redux/actions/GetApiCall";
import useLocationState from "../Medicine/hooks/useLocationState";
import NoDataFoundComp from "../../components/NoDataFound";
import SuccessFull from "../../Animation/SuccessFull";
import MediLoader from "../Medicine/animation/MediLoader";
import toast from "react-hot-toast";
import ToastBox from "../Medicine/animation/ToastBox";

const PackageDetailBox = () => {

  const dispatch = useDispatch();
  const id = useLocationState();
  const navigate = useNavigate();
  const [combined, setCombined] = useState([])
  const [packageRepo, setPackageRepo] = useState([]);
  const [successmodal, setSuccessModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const PackageData = useSelector(state => state.package_details);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const PackId = queryParams.get("id")
  console.log(PackId)

  const navigateToHelp = () => {
    const data = PackageData?.data?.data?.faq_data;
    navigate("/help", { state: data })
  }

  useEffect(() => {
    if (id || PackId) {
      dispatch(fetchPackageDetailApi(id || PackId))
    }
  }, [])

  const PackDetailsData = PackageData?.data?.data?.info_data;
  const PackDetailsRepo = PackageData?.data?.data?.package_details;
  console.log(PackDetailsRepo)

  useEffect(() => {
    if (PackDetailsData?.length) {
      const combinedArray = [];
      const packageRepoArray = [];

      PackDetailsData.forEach(item => {
        if (item?.combined_modules === 1) {
          combinedArray.push(item);
        } else if (item?.combined_modules === 0) {
          packageRepoArray.push(item);
        }
      });

      setCombined(combinedArray);
      setPackageRepo(packageRepoArray);
    }
  }, [PackDetailsData]);

  const message = "Thank you for request!Our executive will contact you shortly to coordinate further."

  const handlePackageRedirect = (item) => {
    console.log(item)
    const localTeleData = {
      id: PackDetailsRepo?.id,
      name: PackDetailsRepo?.name

    }
    if (item?.utilized === 1) {
      setModalShow(true);
      setSuccessModal(true);
      setTimeout(() => {
        setModalShow(false);
        setSuccessModal(false);
      }, 2000);

      return;
    }

    else if (item?.product_data === "" && (item?.type === "fitness_product" || item?.type === "yoga")) {
      toast.error("Product details not found");
      return;
    }
    switch (item?.type) {
      case "medicine":
        navigate('/buyMedicines')
        break;

      case "diagnostic":
        navigate('/diagnostictest')
        break;

      case "teleconsultation":
        localStorage.setItem("PackageSelectData", JSON.stringify(localTeleData));
        navigate('/teleconsultation')
        break;

      case "opd":
        navigate('/reimbursement', { state: item })
        break;

      case "eye":
        navigate('/reimbursement', { state: item })
        break;

      case "dental":
        navigate('/reimbursement', { state: item })
        break;

      case "medicine reimbursement":
        navigate('/reimbursement', { state: item })
        break;

      case "diagnostic reimbursement":
        navigate('/reimbursement', { state: item })
        break;

      case "teleconsultation reimbursement":
        navigate('/reimbursement', { state: item })
        break;

      case "fitness_product":
        navigate('/PackageProduct', { state: item })
        break;

      case "fitness_pro":
        navigate('/fitnesspro', { state: item })
        break;

      case "yoga":
        navigate('/PackageProduct', { state: item })
        break;
      case "psychiatrist":
        navigate('/book-psychiatrist', { state: item })
        break;

      case "yoga session":
        navigate('/yoga-session', { state: item })
        break;

      case "eye consultation":
        navigate('/eye', { state: item })
        break;

      case "dental consultation":
        navigate('/dental', { state: item })
        break;

      case "nutrition":
        navigate('/book-teleconsultation', { state: item?.nutrition?.[0] })
        break;

      default:
        break;
    }
  }

  const height = "75vh"

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">

          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name">
              <p>Health Care Package</p>
            </div>
          </div>
        </div>

        {PackageData?.status === "loading" ? (
          <MediLoader height={height} />
        ) : (
          <>
            <div className="health-care-package-shield-back">
              <div className="health-care-background">
                <div className="health-care-content">
                  <p>{PackageData?.data?.data?.package_details?.name}</p>
                  <h5 className="text-center">{PackageData?.data?.data?.package_details?.corporate_name}</h5>
                </div>
                <div className="health-care-dates">
                  <div className="member-dates">
                    <p>Member Since</p>
                    <span>{PackageData?.data?.data?.package_details?.ordered_at}</span>
                  </div>
                  <div className="valid-dates">
                    <p>Valid Till</p>
                    <span>{PackageData?.data?.data?.package_details?.expiring_on}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="package-care-details">
              <p>Package Details</p>
              <button className="faq-repo-btn-repo" onClick={navigateToHelp}>FAQ</button>
            </div>
            {PackageData?.data?.data?.package_details?.description !== null && (
              <div className="package-pop-desc">
                <p dangerouslySetInnerHTML={{ __html: PackageData?.data?.data?.package_details?.description }}></p>
              </div>
            )}
            <div className="package-details-wrapper-boxs">
              {combined &&
                combined.length !== 0 && (
                  <div className="Combined-data-box">
                    <p className="combined-description" dangerouslySetInnerHTML={{ __html: PackageData?.data?.data?.package_details?.description }}></p>
                    {combined && combined.length > 0 && combined?.map((item, index) => (
                      <div className="package-detail-box-cover package-detail-boxs" key={index} onClick={() => handlePackageRedirect(item)}>
                        <div className="package-detail-image-box">
                          <img
                            src={item?.icon}
                            width="100%"
                            alt="package-icon"
                          />
                        </div>
                        <div className="package-detail-content-box">
                          <h5>{item?.title}</h5>
                          <hr />
                          <p>{item?.description}</p>
                        </div>
                        <div className="package-detail-arrow-box">
                          <img
                            src={appHealthRightArrow}
                            width="100%"
                            alt="review"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

              {packageRepo && packageRepo.length > 0 && packageRepo?.map((item, index) => (
                <div className="package-detail-box-cover package-detail-boxs" key={index} onClick={() => handlePackageRedirect(item)}>
                  <div className="package-detail-image-box">
                    <img
                      src={item?.icon}
                      width="100%"
                      alt="package-icon"
                    />
                  </div>
                  <div className="package-detail-content-box">
                    <h5>{item?.title}</h5>
                    <hr />
                    <p>{item?.description}</p>
                  </div>
                  <div className="package-detail-arrow-box">
                    <img
                      src={appHealthRightArrow}
                      width="100%"
                      alt="review"
                    />
                  </div>
                </div>
              ))}

              {combined &&
                combined.length === 0 &&
                packageRepo &&
                packageRepo.length === 0 && <NoDataFoundComp />}
            </div>
          </>
        )}

        <ToastBox />
      </div>

      {successmodal && (
        <SuccessFull show={modalShow} successMessage={message} animationtype={successmodal} />
      )}

    </>
  );
};

export default PackageDetailBox;
