import React from 'react'
import { page_not_found } from '../Medicine/Images'
import { NavLink } from 'react-router-dom'
import { MdOutlineArrowRightAlt } from "react-icons/md";
import Medicine_Header from '../Medicine/Components/Medicine_Header';
import { headerLogo } from '../../images';

const PageNotFound = () => {
  return (
    <div className="home-review-banner">
      <div className="home-app-header">
        <div className="header-logo">
          <img src={headerLogo} width="100%" alt="header-logo" />
        </div>
      </div>
      <div className="page-not-found-wrapper">
        <img src={page_not_found} alt="page-not-found" />
      </div>
      <div className="redirect-link-to-home">
        <NavLink to={'/home'}>
          <p>BACK TO HOME <MdOutlineArrowRightAlt /></p>
        </NavLink>
      </div>
    </div>
  )
}

export default PageNotFound