import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../Medicine_Style/search_result.css';

const Medicine_Search = () => {
    const navigate = useNavigate();
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const handleSearch = () => {
        const dataState = {
            Title: "Search Product",
            Link: "medicines"
        }
        navigate('/search-medicine', { state: dataState })
    }

    const PlaceorderData = [
        { category: "Medicine" },
        { category: "Health Care" },
        { category: "Skin Care" },
        { category: "Hair Care" },
        { category: "Homeopathy Products" },
        { category: "Ayurveda Products" },
        { category: "Fitness & Supplements" },
        { category: "Diabetic Care" },
        { category: "Sexual Wellness" }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setPlaceholderIndex((prevIndex) => (prevIndex + 1) % PlaceorderData.length);
        }, 1500);
        return () => clearInterval(interval);
    }, [PlaceorderData.length]);

    return (
        <div className='search-data-input-box'>
            <input type="text"
                placeholder={`Search for ${PlaceorderData[placeholderIndex].category}...`}
                onClick={handleSearch} />
        </div>
    )
}

export default Medicine_Search