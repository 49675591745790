import React from 'react'
import { IoLocationSharp } from "react-icons/io5";
import { appOPDIcon } from '../../images';
import { online_consultation, person_consultation } from '../Medicine/Images';
import { useNavigate } from 'react-router-dom';

const DoctorCard = ({ doctor }) => {
    const navigate = useNavigate();
    const handleDetails = (data) => {
        navigate("/doctor-detail", { state: data })
    }
    return (
        <div className="doctor-card">
            <div className="doctor-info" onClick={() => handleDetails(doctor)}>
                <div className="doctor-rpo-cover">
                    <div className="doctor-repo-clover-box">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h3 className="doctor-name">{doctor.name}</h3>
                            <span className="admin-tag">{doctor.category}</span>
                        </div>
                    </div>
                    <div className="doctor-repo-cover">
                        <div className="doctor-image">
                            <img src={appOPDIcon} alt="" />
                        </div>
                        <div className="doctor-header">
                            <p className="doctor-category">{doctor.education}</p>
                            <p className="doctor-category"><IoLocationSharp /> {doctor?.hospital_name}, {doctor?.city}</p>

                        </div>
                    </div>

                </div>
                <div className="doctor-buttons-repo">
                    <div className="repo-clover-price-data">
                        <div className="online">
                            <img src={online_consultation} />
                            Online
                        </div>
                        <div className="price-tag-doc">
                            <p>₹ 900</p>
                        </div>
                    </div>
                    <div className="repo-clover-price-data">
                        <div className="online">
                            <img src={person_consultation} />
                            In-person
                        </div>
                        <div className="price-tag-doc">
                            <p>₹ 900</p>
                        </div>
                    </div>
                   
                </div>

                <div className="book-apointment-btn">
                    <button>
                        BOOK YOUR APPOINTMENT
                    </button>
                </div>

            </div>
        </div>
    )
}

export default DoctorCard