import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchorderhistory } from '../../../../Redux/actions/GetApiCall';
import useLocationState from '../../../Medicine/hooks/useLocationState';
import Order_Card from './Order_Card';
import '../../style/SplitOrder.css'
import PaymentSummaryDetails from './PaymentSummaryDetails';
import Need_Help from '../../../Medicine/Components/Need_Help';
import { NavLink, useNavigate } from 'react-router-dom';
import CancelOrderModal from './CancelOrderModal';
import ToastBox from '../../../Medicine/animation/ToastBox';
import MediLoader from '../../../Medicine/animation/MediLoader';
import { ReorderApi } from '../../../../Redux/actions/PostApiCall';
import toast from 'react-hot-toast';
import { IoHome } from 'react-icons/io5';

const SplitOrderDetails = () => {
  const dispatch = useDispatch();
  const SplitData = useLocationState();
  const orderHistoryData = useSelector(state => state.order_history);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false)
  const reorderData = useSelector(state => state.reorder_medicine)

  const SplitSummary = orderHistoryData?.data?.data?.[0]

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (SplitData) {
      dispatch(fetchorderhistory(SplitData?.order_id));
    }
  }, [])

  const handleReorder = async (item) => {
    console.log(item)
    setShowModal(true); // Show the modal initially

    const data = {
      medicine_order_id: item
    };

    try {
      await dispatch(ReorderApi(data));

      // Wait for the state to update before navigating
      setShowModal(false); // Close the modal

      if (reorderData?.data?.success === true) {
        // Use a timeout to ensure the modal closes before navigating
        setTimeout(() => {
          const tabkeyUrl = "medicines";
          const tabkeyData = { tabkey: tabkeyUrl };
          navigate('/cart', { state: tabkeyData });
        }, 300); // Adjust the delay if necessary

      } else if (reorderData?.data?.success === false) {
        toast.error(reorderData?.data?.message);
      }
    } catch (error) {
      toast.error('An error occurred while processing the reorder.');
    } finally {
      setShowModal(false)
    }
  };

  const height = "75vh"
  const V2HREF = localStorage.getItem("V2HREF")

  return (
    <div className="home-review-banner">
      <div className="home-app-header order-details-repo-data">

        <div className="header-logo ">
          <div className="user-name text-center text-repoo">
            <p>Order Details</p>
          </div>
          {!V2HREF && V2HREF === null && (
            <div className="order-home-repo">
              <NavLink to={'/buyMedicines'}>
                <IoHome />
              </NavLink>
            </div>
          )}
        </div>
      </div>

      {orderHistoryData?.status === "loading" ? (
        <MediLoader height={height} />
      ) : (
        <>
          <div className="split-order-wrapper">
            <div className="order-shipment-data">
              <h6>Order Details</h6>
              <p>Ordered on: {SplitSummary?.ordered_at}</p>
              <p>Order ID: {SplitSummary?.onemg_order_id}</p>
              <p>Order Total: {SplitSummary?.amount_paid?.[0]?.amount}</p>
            </div>
          </div>

          <div className="medicine-order-details-cover">
            <Order_Card SplitOrderData={orderHistoryData} />
          </div>

          <div className="split-summary-data-box-repo">
            <PaymentSummaryDetails TrackSummaryData={SplitSummary} />

            <Need_Help type="medicine" />
          </div>

          {SplitSummary?.is_cancelable === 1 && (
            <div className="cancel-order-repo-wrap">
              <button onClick={handleShow}>CANCEL ORDER</button>
            </div>
          )}

          {SplitSummary?.is_reorderable === 1 && (
            <div className="reorder-btn-data-repo sticky-bottom">
              <button onClick={() => handleReorder(SplitSummary?.medicine_order_id)}>REORDER</button>
            </div>
          )}
        </>
      )}



      <CancelOrderModal show={show} setShow={setShow} OrderId={SplitSummary?.medicine_order_id} />
      <ToastBox />

    </div>
  )
}

export default SplitOrderDetails