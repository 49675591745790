import React, { useState, useContext, useEffect } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { ArrowBackButton, handleNavigateSearch } from "../../utils/AllFunction";
import { IoMdArrowDropdown, IoIosSearch } from "react-icons/io";
import { fetchCategoriesTestList, fetchRadioCartDetails, fetchRadioCategoriesApi } from "../../Redux/actions/GetApiCall";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "../Medicine/Components/Medicine_Header"
import { fetchDiagnosticCartDetails } from "../../Redux/actions/GetApiCall";
import Medicine_footer from "../Medicine/Components/Medicine_footer";
import toast from "react-hot-toast";
import RadioTestCard from "./RadioTestCard";
import RadioPackageCard from "./RadioPackageCard";
import RadioFooter from "./RadioFooter";

const RadioTestByCategories = () => {
  const categories_test_list_response = useSelector((state) => state?.home_list_radio_categories)
  console.log(categories_test_list_response)
  const key = "diagnostics"
  const cartDetailsResponse = useSelector(
    (state) => state.radio_cart_details
  );
  const [testCategories, setTestCategories] = useState([]);
  const cartResponseStatus = useSelector(state => state.radio_cart_add);

  const dispatch = useDispatch()
  const { category_id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchRadioCategoriesApi());
  }, [])

  useEffect(() => {
    if (categories_test_list_response?.data?.data) {
      const categoryData = categories_test_list_response?.data?.data.find((cat_data) => cat_data.id == category_id);
      setTestCategories(categoryData)
    }
  }, [categories_test_list_response, category_id]);

  useEffect(() => {
    dispatch(fetchRadioCartDetails());
  }, [dispatch]);

  // useEffect(() => {
  //   if (cartResponseStatus?.status === "failure") {
  //     toast.error(cartResponseStatus?.error);
  //   }
  // }, [cartResponseStatus]);

  return (
    <>
      <div className="home-review-banner">
        {/* className={`${testCategories.length >= 2 ? "add-mx-height" : ""} lab-book-categories`} */}
        <div  >
          <AppHeader
            Title="Book Test by Categories"
            labCartCount={cartDetailsResponse?.data?.response?.cart_count}
            prescrip={key}
          />
          <div className="app-searh-section">
            <div className="inputWithIcon">
              <input type="text" placeholder="Search" onClick={() => handleNavigateSearch(navigate)} />
              <button>
                <IoIosSearch />
              </button>
            </div>
          </div>
          {testCategories === undefined ? (
            <div style={{ height: "67.7vh",display:"flex",alignItems:'center',justifyContent:'center'}}>
              <p style={{fontSize:'25px',fontWeight:"500"}}>No Data Found</p>
            </div>
          ) : (
            <div className="Test-categories-content-wrapper-box">
              <div className="container">
                {testCategories?.tests?.length > 0 && (
                  <>
                    <h5>Tests <span className="test-cat-count">{testCategories?.tests?.length}</span> </h5>
                    <div className="row d-flex mb-4">
                      {testCategories?.tests?.length > 0 && (
                        testCategories?.tests.map((test, elem) => (
                          <div className="col-6" key={elem}>
                            <RadioTestCard test={test} callCategories={true} />
                          </div>
                        ))
                      )}
                    </div>
                  </>
                )}
                {testCategories?.packages?.length > 0 && (
                  <>
                    <h5>Packages
                      <span className="test-cat-count">{testCategories?.packages?.length}</span>
                    </h5>
                    <div className="row d-flex mb-4">
                      {testCategories?.packages?.length > 0 && (
                        testCategories?.packages.map((test, elem) => (
                          <div className="col-12">
                            <RadioPackageCard data={test} callCategories={true} />
                          </div>
                        ))
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {cartDetailsResponse &&
            (cartDetailsResponse?.data?.response?.tests?.length > 0 ||
              cartDetailsResponse?.data?.response?.packages?.length > 0) && (
              <RadioFooter
                labCartData={cartDetailsResponse?.data.response}
                tabkey={key}
              />
            )}

        </div>

      </div>
    </>
  );
};

export default RadioTestByCategories;
