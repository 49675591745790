import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { headerLogo } from "../../../images";
import NoDataFoundComp from "../../../components/NoDataFound";
import SymptomsCard from "../Components/SymptomsCard";
import { fetchSymptomsList } from "../../../Redux/actions/GetApiCall";
import { useSelector, useDispatch } from "react-redux";
import { IoIosSearch } from "react-icons/io";
import Loader from "../../../Animation/Loader";
import { no_tele_found } from "../../Medicine/Images";

const SymptomsTeleConsultation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storedPackageData = JSON.parse(
    localStorage.getItem("PackageSelectData")
  );
  const symptomsListData = useSelector(
    (state) => state.tele_symptoms_list_response
  );

  const [filteredData, setFilteredData] = useState([]);
  const [searchquery, setSearchQuery] = useState("");

  useEffect(() => {
    if (storedPackageData?.id) {
      dispatch(fetchSymptomsList(storedPackageData?.id));
    }
  }, []);

  const handleSearch = (e) => {
    const searchquery = e.target.value.toLowerCase();

    if (symptomsListData?.data?.data?.symptoms.length > 0) {
      const searchedData = symptomsListData?.data?.data?.symptoms.filter(
        (teleData) => teleData.name.toLowerCase().includes(searchquery)
      );
      setFilteredData(searchedData);
      setSearchQuery(searchquery);
    } else {
      setFilteredData(null);
    }
  };

  const handleBooking = (data) => {
    navigate(`/book-teleconsultation`, { state: data });
  };

  return (
    <>
      <div className="home-review-banner mb-4">
        <div className="home-app-header d-flex justify-content-start align-items-center sticky-top">
          <div className="header-logo mx-1">
            <img
              src={headerLogo}
              className="header-medibhai-logo"
              width="100%"
              alt="header-logo"
            />
          </div>
          <div className="home-header-teleconsultation d-flex justify-content-between align-items-center">
            <div className="header-navigation-icon">
              <h6 className="mb-0">Teleconsultation</h6>
            </div>
          </div>
        </div>
        <div className="app-searh-section">
          <div className="inputWithIcon input-tele-search">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => handleSearch(e)}
            />
            <button className="symptom-search-icon-box">
              <IoIosSearch />
            </button>
          </div>
        </div>
        <div className="symptoms-main-wrapper-cover test-wrapper px-3 mt-4">
          <div className="symptoms-header mb-3">
            <h6>Symptoms</h6>
          </div>

          <div className="symptoms-product-main-wrapper d-flex justify-content-between">
            {symptomsListData?.loader ? (
              <Loader />
            ) : (
              <>
                {searchquery.length > 0 ? (
                  <div className="specialist-product-box row">
                    {filteredData && filteredData.length > 0 ? (
                      filteredData.map((specialityData) => (
                        <div className="col-lg-4">
                          <SymptomsCard
                            key={specialityData.id}
                            data={specialityData}
                            handleBooking={() => handleBooking(specialityData)}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="div-data-lab-image">
                        <img src={no_tele_found} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="specialist-product-box d-flex justify-content-between">
                    {symptomsListData?.data?.data?.symptoms?.length > 0 ? (
                      symptomsListData?.data?.data?.symptoms.map(
                        (symptomsData) => {
                          return (
                            <SymptomsCard
                              data={symptomsData}
                              handleBooking={() => handleBooking(symptomsData)}
                            />
                          );
                        }
                      )
                    ) : (
                      <div className="div-data-lab-image">
                        <img src={no_tele_found} />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SymptomsTeleConsultation;
