import React, { useEffect, useState } from 'react'
import '../../style/orderDetails.css'
import { no_preview } from '../../../Medicine/Images'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ReorderApi } from '../../../../Redux/actions/PostApiCall';
import LoaderAnime from '../../../Medicine/animation/prescription_loader';
import toast from 'react-hot-toast'
import ToastBox from '../../../Medicine/animation/ToastBox';
import { HiOutlineArrowSmDown } from "react-icons/hi";
import { HiArrowSmUp } from "react-icons/hi";
import { GiMedicinePills } from "react-icons/gi";

const Order_Card = ({ OrderData, SplitOrderData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reorderData = useSelector(state => state.reorder_medicine)
    const [showModal, setShowModal] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const maxVisible = OrderData ? 10 : 3;
    const pincode = localStorage.getItem("pincode");

    const toggleShowAll = () => {
        setShowAll((prev) => !prev)
    }

    const visibleItems = showAll ? (OrderData?.data?.data || SplitOrderData?.data?.data?.[0]?.split_order_data) : (OrderData?.data?.data || SplitOrderData?.data?.data?.[0]?.split_order_data)?.slice(0, maxVisible);

    const OrderStatusColor = OrderData?.data || SplitOrderData?.data;
    const OrderSummaryData = OrderData?.data?.data || SplitOrderData?.data?.data?.[0]?.split_order_data;


    const handleShipment = (item) => {
        const DataId = {
            medicine_order_id: item?.medicine_order_id,
            onemg_order_id: item?.onemg_order_id
        }
        const splitId = {
            order_id: item?.medicine_order_id
        }
        if (item?.no_of_order_split > 0) {
            navigate("/split-order-details", { state: splitId })
        }
        else {
            navigate("/shipment-details", { state: DataId })
        }
    }

    const handleReorder = async (item) => {
        if (pincode === "") {
            toast.error("Please Enter Pincode");
            return;
        }
        setShowModal(true);

        const data = {
            medicine_order_id: item,
        };

        try {
            await dispatch(ReorderApi(data));

            // Wait for the API response, state update is handled in `useEffect`
        } catch (error) {
            toast.error('An error occurred while processing the reorder.');
            setShowModal(false);
        }
    };

    useEffect(() => {
        if (!showModal) return; // Only proceed if the modal is open

        if (reorderData?.data?.success === true) {
            setShowModal(false); // Close the modal

            setTimeout(() => {
                const tabkeyUrl = "medicines";
                const tabkeyData = { tabkey: tabkeyUrl };
                navigate('/cart', { state: tabkeyData });
            }, 300); // Adjust the delay if necessary
        } else if (reorderData?.data?.success === false) {
            toast.error(reorderData?.data?.message);
            setShowModal(false); // Close the modal on failure as well
        }
    }, [reorderData, showModal, navigate]);


    return (
        <>

            {showModal && <LoaderAnime showModal={showModal} />}
            {visibleItems && visibleItems?.length === 0 ? (
                <div class="empty-order-container">
                    <p class="empty-order-text">Your medicine order list is empty. Explore and order now!</p>
                    <NavLink to={'/buyMedicines'}>
                        <button class="empty-order-button"><GiMedicinePills /> Explore Medicine</button>
                    </NavLink>
                </div>

            ) : (
                <>
                    {visibleItems?.map((item) => (
                        <div className="order-cover-repo-wrap-box-con" onClick={() => handleShipment(item)} key={item?.medicine_order_id}>
                            <div className="order-box-coverdetails">
                                <div className="order-date-details">
                                    {SplitOrderData && SplitOrderData?.data?.data ? (
                                        <p>Split Order ID: {item.onemg_order_id}</p>
                                    ) : (
                                        <p>Ordered on {item?.ordered_at}</p>
                                    )}
                                    <span
                                        style={{
                                            backgroundColor: item?.order_status_color_code,
                                        }}
                                    >
                                        {item?.order_status}
                                    </span>

                                </div>
                                <hr className='orderhr' />
                                {item?.no_of_order_split > 0 && (
                                    <div className="order-split-text">
                                        <p>Order split into {item?.no_of_order_split} parts</p>
                                    </div>
                                )}
                                <div className="orderDetails-product-box">
                                    <div className="left-box-cover-details">
                                        <div className="orderdetails-image-box">
                                            <img src={item?.medicines?.[0]?.banner_url ? item?.medicines?.[0]?.banner_url : no_preview}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = no_preview;
                                                }} alt="order-image" />
                                        </div>
                                        <div className="details-box-repo-text">
                                            {item?.medicines?.[0] && (
                                                <>
                                                    <h5 className='ellipsis-text'>Name: {item?.medicines?.[0]?.name}</h5>
                                                    <p>Quantity: {item?.medicines?.[0]?.quantity}</p>
                                                </>
                                            )}
                                            <p>Order ID: {item?.onemg_order_id}</p>
                                        </div>
                                    </div>
                                    <div className="right-box-cover-details">
                                        {item?.is_reorderable === 1 && (
                                            <button
                                                className='reorder-btn-history'
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents the click event from bubbling up
                                                    handleReorder(item?.medicine_order_id); // Calls the handleReorder function with the order ID
                                                }}
                                            >
                                                Reorder
                                            </button>

                                        )}
                                        <button>View Details</button>
                                    </div>
                                </div>
                            </div>
                            {item?.medicine_count > 0 && (
                                <div className="product-length-box">
                                    <p>+ {item?.medicine_count} More Products</p>
                                </div>
                            )}
                        </div >
                    ))}
                </>

            )
            }

            {
                (OrderData?.data?.data || SplitOrderData?.data?.data?.[0]?.split_order_data)?.length > maxVisible && (
                    <div className="view-more-less">
                        <button onClick={toggleShowAll} className="view-more-btn">
                            {showAll ? (
                                <>
                                    View Less <HiArrowSmUp />
                                </>
                            ) : (
                                <>
                                    View More <HiOutlineArrowSmDown />
                                </>
                            )}
                        </button>

                    </div>
                )
            }
            <ToastBox />
        </>
    )
}

export default Order_Card