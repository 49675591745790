// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import SuccessFully from "../../../AnimationJson/success.json";
import ErrorFull from "../../../AnimationJson/error-message.json";

import Modal from 'react-bootstrap/Modal';

const Medicine_Success = ({ showModal, message, status }) => {
    // const { show, successMessage, animationtype } = props;
    console.log(status)

    return (
        <Modal
            show={showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Lottie animationData={status === (false) ? ErrorFull : SuccessFully} className='success-data-animation' />
                <p
                    className="success-msg"
                    style={{ color: status === false ? 'red' : 'inherit' }}
                >
                    {message}
                </p>

            </Modal.Body>
        </Modal>
    )
}

export default Medicine_Success;
