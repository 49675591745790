import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { selectedMedicineAddress, selectedDiagnosticAddress } from "../../../Redux/actions/PostApiCall";
import { useDispatch, useSelector } from "react-redux";
import useLocationState from "../hooks/useLocationState";
import { fetchMedicineAddress } from "../../../Redux/actions/GetApiCall";

const Medicine_footer = ({ presMedi, addressMedi, cartData, tabkey, labCartData, AddressId, mediCart, handleOrderMedicine, handleDiagnosticOrder }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabKeyUrl = useLocationState();
  console.log(tabKeyUrl)
  // const medicartData = tabKeyUrl?.cartdata || cartData;
  const medicartData = useSelector(state => state.medicine_cart_list.data);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const autoParam = queryParams.get('auto');
  const addressList = useSelector(state => state.medicine_address);
  const pres = medicartData?.prescription_required
  const WebLink = window.location.pathname;
  const [activeTabKey, setActiveTabKey] = useState(tabkey || tabKeyUrl == "diagnostics" ? "diagnostics" : tabKeyUrl?.tabkey);
  const [dataToUse, setDataToUse] = useState(activeTabKey === "medicines" ? medicartData : labCartData);

  useEffect(() => {
    const newActiveTabKey = tabkey || (tabKeyUrl == "diagnostics" ? "diagnostics" : tabKeyUrl?.tabkey);
    setActiveTabKey(newActiveTabKey);

    if (newActiveTabKey === "medicines") {
      setDataToUse(medicartData);
    } else {
      setDataToUse(labCartData);
    }

  }, [tabKeyUrl, tabkey, medicartData, labCartData]);



  const totalAmount =
    activeTabKey === "medicines"
      ? dataToUse?.payable_amount
      : dataToUse?.calculation?.find((item) => item?.key === "Total payable price")?.value;

  const totalItems =
    activeTabKey === "medicines"
      ? dataToUse?.total_items || (dataToUse?.total_item)
      : dataToUse?.members ? dataToUse?.members?.length : dataToUse?.cart_count;

  // console.log(AddressId)
  const handleCartLink = async () => {
    const Title = "Upload Prescription";
    const AddressTitle = "medicines"
    const cartLink = window.location.pathname;

    if (activeTabKey === "medicines" && addressList?.data?.data && addressList?.data?.data?.length === 0) {
      console.log('hii')
      navigate('/medicines-address', { state: activeTabKey });
    }

    else if (AddressId !== undefined) {
      const addressData = {
        address_id: AddressId,
      };

      const diagnosData = {
        patientCount: tabKeyUrl?.patientCount || tabkey?.patientCount,
        AddressTitle: AddressTitle
      }

      if (activeTabKey === "medicines") {
        await dispatch(selectedMedicineAddress(addressData));
        navigate("/place-order", { state: AddressTitle });
      } else {
        await dispatch(selectedDiagnosticAddress(addressData));
        navigate("/date-timeslot", { state: diagnosData });
      }
    }
    else if (
      activeTabKey === "medicines" &&
      pres === 1 && presMedi === 3
    ) {
      navigate("/prescription", { state: Title });

    }
    else if (AddressId !== undefined && activeTabKey === "medicines") {
      navigate("/place-order", { state: AddressTitle });

    }
    else if (
      activeTabKey === "medicines" &&
      pres === 0 && mediCart === 1) {
      navigate("/address-list", { state: AddressTitle });
    }
    else if (cartLink === "/date-timeslot" && activeTabKey === "diagnostics") {
      navigate("/place-order");

    } else if (
      activeTabKey === "medicines" &&
      pres === 0 && addressMedi === 2
    ) {
      navigate("/medicines-address", { state: AddressTitle });
    } else if (cartLink === "/cart" && activeTabKey === "diagnostics") {
      navigate("/patients");
    }
    else {
      navigate("/cart", { state: activeTabKey });
    }
  };


  useEffect(() => {
    if (WebLink === "/cart") {
      dispatch(fetchMedicineAddress());
    }
  }, [WebLink])

  return (
    <div className="footer-repo-head-boxs sticky-lg-bottom sticky-md-bottom sticky-bottom">
      {AddressId !== undefined && activeTabKey !== "medicines" ? (
        <div className="product-price-repo-store">
          <p>
            Total <span>{tabkey?.patientCount || 0}
              {tabkey?.patientCount ? " Patients" : " Item(s)"}
            </span>
          </p>
        </div>
      ) : (
        <div className="product-price-repo-store">
          <p>
            Total <span>₹ {totalAmount?.toLocaleString('en-IN') || 0}</span>
          </p>
          <span className="item-qty">{totalItems || 0}
            {dataToUse?.members ? " Patients" : " Item(s)"}
          </span>
        </div>
      )}
      <div className="product-btn-box-store">
        <button
          className="repo-cont-btn"
          onClick={() => {
            if (WebLink === "/place-order" || WebLink === "/medicine-place-order") {
              if (activeTabKey === "medicines") {
                handleOrderMedicine();
              } else if (activeTabKey === "diagnostics" || autoParam) {
                handleDiagnosticOrder();
              }
            } else {
              handleCartLink();
            }
          }}
          disabled={
            (WebLink === "/cart" && activeTabKey === "medicines" && medicartData?.out_of_stock?.length !== 0)
              ? true
              : (AddressId === null || AddressId === "")
          }
        >
          {WebLink === "/place-order" || WebLink === "/medicine-place-order" ? "Place Order" : "CONTINUE"}
        </button>
      </div>
    </div>
  );
};

export default Medicine_footer;