import React from 'react'
import SuccessPack from '../../../Animation/SuccessPack'

const ThankTeleconsultation = ({ ThankData }) => {

    return (
        <div>
            <div className='thank-rep-wrapper'>
                {ThankData?.order_status === "New Consultation" && (
                    <div className="order-status-data-repo-wrapper">
                        <SuccessPack />
                        <p>{ThankData?.order_message}</p>
                        <span className='book-time-date-te'>Booked on {ThankData?.booked_on}</span>
                    </div>
                )}

                <div className="telewrapper-cont-text-cover">
                    <div className="test-data-content-box" style={{marginTop:'0px',marginBottom:'10px'}}>
                        <img src={ThankData?.departments?.file_name} alt="" />
                        <div className="test-name-data-content">
                            <span style={{fontSize:'15px'}}>{ThankData?.departments?.name}</span>
                            {/* <p>Test Includes(20)</p> */}
                        </div>
                    </div>
                    <hr className='patient-lab-data-hr' />
                    <div className="patient-details-wrapper-oreder">
                        <p className='patien-text-de'>Patient Details</p>
                        <p>Patient Name : {ThankData?.patient_name}</p>
                        <div className="patient-details-age-wrapper">
                            <p>Age : {ThankData?.age}</p>
                            <p>Gender : {ThankData?.gender}</p>
                        </div>
                    </div>
                    <hr className='patient-lab-data-hr' />

                    <div className="appoint-date-repo-wrapper">
                        <div className="appointment-date-repo">
                            <p>Appointment Date</p>
                            <p className='bond-text-repo'>{ThankData?.appointment_date}</p>
                        </div>
                        <div className="appointment-date-repo">
                            <p>Appointment Time</p>
                            <p className='bond-text-repo'>{ThankData?.appointment_time}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThankTeleconsultation