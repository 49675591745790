import { React, useEffect, useState, forwardRef, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Apploader from "../../Medicine/animation/prescription_loader";
import {
    fetchSymptomsList,
    fetchPackageList,
    fetchDepartmentsList,
} from "../../../Redux/actions/GetApiCall";
import {
    getDepartmentTimeSlot,
    bookTeleconsult,
    utilizeTeleconsultAmount,
} from "../../../Redux/actions/PostApiCall";
import { validateNumberInput } from "../../../utils/AllFunction";
import { Toaster, toast } from "react-hot-toast";
import { validateTextInput } from "../../../utils/AllFunction";
import MedicinePopup from "../../Medicine/animation/Medicine_Success";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import { InputGroup } from "react-bootstrap";
import { format } from "date-fns";
import { FaCalendarAlt } from "react-icons/fa";
import useLocationState from "../../Medicine/hooks/useLocationState";
import { PiUploadBold } from "react-icons/pi";
import { FaRegCircleXmark } from "react-icons/fa6";
import LoaderAnime from "../../Medicine/animation/prescription_loader";
import { psychiatristBanner, savings_image } from "../../Medicine/Images";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="date-picker-input" onClick={onClick} ref={ref}>
        <input
            type="text"
            readOnly
            value={value}
            placeholder="Select a Booking Date"
            className="booking-date"
        />
        <FaCalendarAlt className="calendar-icon" />
    </div>
));

const BookPyscritist = () => {
    const navigate = useNavigate();
    const [BookingDate, setBookingDate] = useState(null);
    const dispatch = useDispatch();
    const ClaimPackId = useLocationState();
    console.log(ClaimPackId)
    const BookedTeleConsultDataRepo = useLocationState();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const packagesData = useSelector((state) => state.package_list);
    const department_time_slot_response = useSelector(
        (state) => state.department_time_slot_response
    );
    const storedPackageData = JSON.parse(
        localStorage.getItem("PackageSelectData")
    );
    const V2HREF = localStorage.getItem("V2HREF");
    const NutritionID = localStorage.getItem("NutritionID");
    const profileDetails = packagesData?.data?.profile_details;
    const previousDepartmentId = useRef(null);
    const utilize_amount_response = useSelector(
        (state) => state.utilize_amount_response
    );
    const bookTeleconsult_response = useSelector(
        (state) => state.bookTeleconsult_response
    );

    const departmentListData = useSelector(
        (state) => state.tele_department_list_response.data
    );

    const NutroData = departmentListData?.data;
    const mappedNutritionID = NutritionID === "10" ? "67" : NutritionID; //for claim engine broker site nutrition

    // Convert the mapped NutritionID to a number
    const nutritionIdAsNumber =
        mappedNutritionID && mappedNutritionID !== "null"
            ? Number(mappedNutritionID)
            : null;

    const FindNutroData = NutroData?.department?.find(
        (item) => item.id === nutritionIdAsNumber
    );

    const BookedTeleConsultData = BookedTeleConsultDataRepo || FindNutroData;

    const {
        handleSubmit,
        control,
        register,
        setValue,
        clearErrors,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            patient_name: "",
            email: "",
            mobile: "",
            department_id: 11,
            // Add default values for other fields as needed
        },
    });

    const teleformData = { ...watch(), department_id: 78 };


    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 6);

    const formatDate = (date) => {
        if (!date) return "";
        const formattedDate = format(date, "dd-MM-yyyy");
        return formattedDate;
    };


    const handlePreview = handleSubmit(async (data) => {
        const formData = new FormData();
        formData.append("patient_name", data.patient_name);
        formData.append("email", data.email);
        formData.append("contact", data.mobile);
        formData.append("appointment_date", data.appointment_date);
        formData.append("appointment_time", data?.appointment_time);
        formData.append("age", data.age);
        formData.append("age_type", data?.age_type);
        formData.append("remark", data.remark);
        formData.append("gender", data.gender);
        formData.append("department_id", 78);
        formData.append("package_id", ClaimPackId?.package_id ? ClaimPackId?.package_id : "");

        if (BookedTeleConsultData?.type == "symptoms") {
            formData.append("symptom_id", BookedTeleConsultData?.id);
        }

        // Await the first API call
        await dispatch(bookTeleconsult(formData));

        if (bookTeleconsult_response?.status === "success") {
            const data = {
                booked_id: bookTeleconsult_response?.data?.data?.consultation?.id,
                package_id: ClaimPackId?.package_id ? ClaimPackId?.package_id : "",
            };
            await dispatch(utilizeTeleconsultAmount(data));
        }
    });

    console.log(bookTeleconsult_response)

    useEffect(() => {
        if (utilize_amount_response?.status === "success") {
            setShowSuccessModal(true)
            setTimeout(() => {
                setShowSuccessModal(false)
                navigate('/home')
            }, 2000);
        }
    }, [utilize_amount_response])

    // Assuming bookTeleconsult_response is fetched using useSelector from Redux state
    useEffect(() => {
        if (bookTeleconsult_response?.status === "success") {
            const data = {
                booked_id: bookTeleconsult_response?.data?.data?.consultation?.id,
                package_id: ClaimPackId?.package_id ? ClaimPackId?.package_id : "",
            };
            dispatch(utilizeTeleconsultAmount(data));
            setShowSuccessModal(true)
            // setTimeout(() => {
            //     setShowSuccessModal(false)
            // }, 2000);
        }
    }, [bookTeleconsult_response, dispatch, ClaimPackId]);



    useEffect(() => {
        const formData = new FormData();
        // Check if department_id has changed to prevent redundant API calls
        if (BookingDate !== null) {
            if (
                teleformData?.department_id &&
                teleformData?.department_id !== previousDepartmentId.current
            ) {
                if (BookedTeleConsultData?.type === "symptoms") {
                    formData.append("department_id", 78);
                    formData.append("date", formatDate(BookingDate));
                    setValue("appointment_date", formatDate(BookingDate));
                    setValue("appointment_time", null);
                    // dispatch(getDepartmentTimeSlot(formData));
                } else if (BookedTeleConsultData?.type !== "symptoms") {
                    formData.append("department_id", 78);
                    formData.append("date", formatDate(BookingDate));
                    setValue("appointment_date", formatDate(BookingDate));
                    setValue("appointment_time", null);
                    // dispatch(getDepartmentTimeSlot(formData));
                }

                previousDepartmentId.current = 11;
            }
        }
    }, [teleformData?.department_id, BookingDate]);

    useEffect(() => {
        dispatch(
            fetchSymptomsList(storedPackageData?.id ? storedPackageData?.id : "")
        );
        dispatch(fetchPackageList());
    }, []);

    useEffect(() => {
        if (storedPackageData?.id && V2HREF && V2HREF !== null) {
            dispatch(fetchDepartmentsList(storedPackageData?.id));
        } else {
            dispatch(fetchDepartmentsList());
        }
    }, []);

    useEffect(() => {
        if (profileDetails) {
            reset({
                patient_name: profileDetails.name || "",
                email: profileDetails.email_id || "",
                mobile: profileDetails.mobile_number || "",
                appointment_date: formatDate(BookingDate) || "",
            });
        }
    }, [profileDetails, reset]);

    console.log(BookingDate)

    useEffect(() => {
        if (bookTeleconsult_response?.status === "success") {
            if (bookTeleconsult_response?.data?.data?.consultation?.id) {
                // navigate("/order-consultation", {
                //     state: bookTeleconsult_response?.data?.data?.consultation?.id,
                // });
            }
        } else if (bookTeleconsult_response?.status === "failure") {
            toast.error(bookTeleconsult_response?.error);
        }
    }, [bookTeleconsult_response]);

    useEffect(() => {
        if (department_time_slot_response?.status === "loading") {
            // Show modal when status is 'loading'
            setModalShow(true);
        } else if (department_time_slot_response?.status === "success") {
            if (
                teleformData?.appointment_date !== "" &&
                teleformData.department_id &&
                department_time_slot_response?.data?.data?.time_slots?.length === 0
            ) {
                toast.error("No time slots available");
            }
            // Hide modal when status is 'success'
            setModalShow(false);
        }
    }, [department_time_slot_response]);

    useEffect(() => {
        if (BookingDate) {
            if (teleformData.department_id) {
                const formData = new FormData();
                formData.append("department_id", teleformData.department_id);
                formData.append("date", formatDate(BookingDate));
                setValue("appointment_date", formatDate(BookingDate));
                setValue("appointment_time", null);
                dispatch(getDepartmentTimeSlot(formData));
            }
        }
    }, [BookingDate]);

    return (
        <>
            <div className="home-review-banner mb-4">
                {modalShow && <LoaderAnime showModal={modalShow} />}
                <div className="home-app-header d-flex justify-content-start align-items-center sticky-top">
                    <div className="home-header-teleconsultation d-flex align-items-center">
                        {/* <div className="teleconsult-icon-box">
                            <img
                                src={`${BookedTeleConsultData?.file_name}`}
                                width="100%"
                                alt="Cart-icon"
                            />
                        </div> */}
                        <div className="header-navigation-icon">
                            <h6 className="mb-0">Psychiatrist</h6>
                        </div>
                    </div>
                </div>
                <Apploader showModal={bookTeleconsult_response?.loader} />
                <div className="Booking-teleconsult-main-wrapper px-3">
                    <div className="banner-repo-pyscr">
                        <img src={psychiatristBanner} alt="" />
                    </div>
                    <div className="book-now-form-main-cover">
                        <div className="book-now-form">
                            <h6 className="tele-form-heading">Enter Patient Details</h6>
                            <form>
                                <div className="mb-3 book-now-input-box">
                                    <label className="tele-labels">Patient Name</label>
                                    <input
                                        type="text"
                                        placeholder="Patient Name"
                                        {...register("patient_name", {
                                            required: "Please enter patient name",
                                        })}
                                        onInput={(e) => validateTextInput(e.target)}
                                    />
                                    <span className="text-danger validation-error">
                                        {errors.patient_name && errors.patient_name.message}
                                    </span>
                                </div>
                                {BookedTeleConsultData?.type == "symptoms" && (
                                    <Form.Group
                                        className="mb-3 book-now-input-box"
                                        controlId="exampleForm.ControlSelect1"
                                    >
                                        <label className="tele-labels">Select Department </label>
                                        <Form.Select
                                            {...register("department_id", {
                                                required: "Please select department id",
                                            })}
                                            className="department_type_select"
                                        >
                                            <option value="">Select department</option>
                                            {BookedTeleConsultData?.department &&
                                                Object.entries(BookedTeleConsultData?.department).map(
                                                    ([key, value]) => (
                                                        <option key={key} value={key}>
                                                            {value}
                                                        </option>
                                                    )
                                                )}
                                        </Form.Select>
                                        <span className="text-danger validation-error">
                                            {errors.department_id && errors.department_id.message}
                                        </span>
                                    </Form.Group>
                                )}
                                <label className="tele-labels">Set Appointment Date</label>
                                <div className="mb-3 date-prod-data-box">
                                    <DatePicker
                                        name="start_date"
                                        selected={BookingDate}
                                        {...register("start_date", {
                                            required: "Please select an appointment date",
                                        })}
                                        onChange={(date) => {
                                            setBookingDate(date);
                                            setValue("start_date", date, { shouldValidate: true }); // Manually set value with validation
                                            clearErrors("start_date"); // Clear any existing errors
                                        }}
                                        placeholderText="Select a Booking Date"
                                        showYearDropdown={false}
                                        showMonthDropdown={false}
                                        autoComplete="off"
                                        minDate={new Date()}
                                        maxDate={maxDate}
                                        dateFormat="dd-MM-yyyy"
                                        customInput={<CustomInput />}
                                    />
                                    <span className="text-danger validation-error">
                                        {errors.start_date && errors.start_date.message}
                                    </span>
                                </div>
                                {teleformData?.appointment_date !== "" &&
                                    teleformData.department_id &&
                                    department_time_slot_response?.data?.data?.time_slots
                                        ?.length > 0 && (
                                        <div className="time-slot-input-box mb-3">
                                            <label htmlFor="selectOption" className="tele-labels">
                                                Select Appointment Time
                                            </label>
                                            <select
                                                id="selectOption"
                                                // required
                                                {...register("appointment_time", {
                                                    required: "Please select appoinment time",
                                                })}
                                            >
                                                <option value="">Select time slot</option>
                                                {department_time_slot_response?.data?.data?.time_slots
                                                    ?.length > 0 &&
                                                    department_time_slot_response?.data?.data?.time_slots.map(
                                                        (timeslot) => (
                                                            <option key={timeslot} value={timeslot}>
                                                                {timeslot}
                                                            </option>
                                                        )
                                                    )}
                                            </select>
                                            <span className="text-danger validation-error">
                                                {errors.appointment_time &&
                                                    errors.appointment_time.message}
                                            </span>
                                            {department_time_slot_response?.data?.data?.time_slots &&
                                                department_time_slot_response?.data?.data?.time_slots
                                                    ?.length === 0 && (
                                                    <p
                                                        style={{
                                                            color: "red",
                                                            fontSize: "13px",
                                                            marginLeft: "3px",
                                                        }}
                                                    >
                                                        No time slots available
                                                    </p>
                                                )}
                                        </div>
                                    )}

                                <div className="row">
                                    <div className="col-6 mb-3 book-now-input-box">
                                        <label className="tele-labels">Age</label>
                                        <input
                                            type="text"
                                            maxLength={3} // Max length should be 3 to allow age 100 and 101
                                            placeholder="Age"
                                            {...register("age", {
                                                required: "Please enter patient age",
                                                pattern: {
                                                    value: /^\d{1,3}$/, // Allow only numbers and limit to 3 digits
                                                    message: "Please enter a valid age",
                                                },
                                                validate: (value) =>
                                                    value <= 101 || "Age cannot be more than 101", // Custom validation for max age
                                            })}
                                            onInput={(e) => validateNumberInput(e)}
                                        />

                                        <span className="text-danger validation-error">
                                            {errors.age && errors.age.message}
                                        </span>
                                    </div>
                                    <div className="col-6 time-slot-input-box mb-3">
                                        <label className="tele-labels">Age Type</label>

                                        <select
                                            {...register("age_type", {
                                                required: "Age type is required",
                                            })}
                                            defaultValue="" // sets default selected value
                                        >
                                            <option value="" disabled>
                                                Select
                                            </option>
                                            <option value="days">Days</option>
                                            <option value="months">Month</option>
                                            <option value="years">Years</option>
                                        </select>

                                        <span className="text-danger validation-error">
                                            {errors.age_type && errors.age_type.message}
                                        </span>
                                    </div>
                                </div>
                                <div className="gender-input-radio-box mb-3">
                                    <Form.Group>
                                        <Form.Label className="radio-title-label">
                                            Select Gender
                                        </Form.Label>
                                        <InputGroup>
                                            <Controller
                                                name="gender"
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: "Gender is required" }}
                                                render={({ field }) => (
                                                    <>
                                                        <div className="radio-input-box d-flex align-items-center">
                                                            <InputGroup.Radio
                                                                {...field}
                                                                value="male"
                                                                className="gender-input-radio p-0"
                                                                checked={field.value === "male"}
                                                            />
                                                            <Form.Label className="mr-2">Male</Form.Label>
                                                        </div>

                                                        <div className="radio-input-box d-flex align-items-center">
                                                            <InputGroup.Radio
                                                                {...field}
                                                                value="female"
                                                                className="gender-input-radio"
                                                                checked={field.value === "female"}
                                                            />
                                                            <Form.Label>Female</Form.Label>
                                                        </div>

                                                        <div className="radio-input-box d-flex align-items-center">
                                                            <InputGroup.Radio
                                                                {...field}
                                                                value="other"
                                                                className="gender-input-radio"
                                                                checked={field.value === "other"}
                                                            />
                                                            <Form.Label>Other</Form.Label>
                                                        </div>
                                                    </>
                                                )}
                                            />
                                        </InputGroup>
                                        <span className="text-danger validation-error">
                                            {errors.gender && errors.gender.message}
                                        </span>
                                    </Form.Group>
                                </div>

                                {BookedTeleConsultData?.type == "symptoms" && (
                                    <input
                                        type="hidden"
                                        {...register("symptom_id")}
                                        value={BookedTeleConsultData?.id}
                                    ></input>
                                )}
                                {BookedTeleConsultData?.type != "symptoms" && (
                                    <input
                                        type="hidden"
                                        {...register("department_id")}
                                        value={BookedTeleConsultData?.id}
                                    ></input>
                                )}


                                <Form.Group
                                    className="mb-3 book-now-input-box"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <label className="tele-labels">
                                        Describe your symptoms{" "}
                                        <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Describe your symptoms..."
                                        rows={4}
                                        cols={50}
                                        {...register("remark", {
                                            required: "This field is required",
                                        })}
                                    />
                                    {errors.remark && (
                                        <span className="text-danger">{errors.remark.message}</span>
                                    )}
                                </Form.Group>

                                <Form.Group
                                    className="mb-3 book-now-input-box"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <label className="tele-labels">Phone Number</label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Phone Number"
                                        {...register("mobile", {
                                            required: "Please enter patient phone number",
                                        })}
                                        disabled={
                                            profileDetails?.mobile_number !== "" &&
                                            profileDetails?.mobile_number !== null &&
                                            profileDetails?.mobile_number !== undefined
                                        }
                                        onInput={(e) => validateNumberInput(e)}
                                        maxLength={10}
                                    />
                                    <span className="text-danger validation-error">
                                        {errors.mobile && errors.mobile.message}
                                    </span>
                                </Form.Group>
                                <Form.Group
                                    className="mb-3 book-now-input-box"
                                    controlId="exampleForm.ControlInput1"
                                >
                                    <label className="tele-labels">Email Address</label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email"
                                        disabled={
                                            profileDetails?.email_id !== "" &&
                                            profileDetails?.email_id !== null &&
                                            profileDetails?.email_id !== undefined
                                        }
                                        {...register("email", {
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Please enter a valid email address",
                                            },
                                        })}
                                    />
                                    <span className="text-danger validation-error">
                                        {errors.email && errors.email.message}
                                    </span>
                                </Form.Group>
                                <p className="wallet_summary-point"></p>
                                <div className="submit-btn-box d-flex justify-content-center">
                                    {/* <button type="submit" disabled={submitLoader}>{submitLoader ? "Submitting" : "Submit"}</button> */}
                                    {/* <button type="submit">{"Submit"}</button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName=""
                    containerStyle={{}}
                    toastOptions={{
                        className: "custom-toast position-middle-center",
                        duration: 2000,
                        style: {
                            background: "#fff",
                            color: "#363636",
                            fontSize: "14px",
                            width: "300px",
                            top: "55px !important",
                        },
                    }}
                />
                {/* <div className="middle_toaster">
          </div> */}
                <div className="go-to-cart-main-page-wrapper">
                    <div className="cart-count-box-cover" style={{ justifyContent: 'center' }}>
                        <div className="go-cart-btn">
                            <button
                                style={{ padding: '8px 35px' }}
                                className="patient-btn"
                                disabled={
                                    department_time_slot_response?.data?.data?.time_slots
                                        ?.length === 0
                                }
                                onClick={() => handlePreview()}
                            >
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showSuccessModal && (
                <MedicinePopup
                    showModal={showSuccessModal}
                    setShowSuccessModal={setShowSuccessModal}
                    message="Consultation booked successfully!"
                    image={savings_image}
                />
            )}
        </>
    );
};

export default BookPyscritist;
