import React, { useState } from 'react'
import { appOPDIcon, headerLogo } from '../../images'
import useLocationState from '../Medicine/hooks/useLocationState'
import { MdSchool } from 'react-icons/md';
import { FaBuilding, FaCalendarAlt } from 'react-icons/fa';
import './doctorslot.css'
import { IoPartlySunnySharp } from "react-icons/io5";
import { MdSunny } from "react-icons/md";
import { IoMdMoon } from "react-icons/io";
import { Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-date-picker';

const TimeSlots = () => {
    const Data = useLocationState();
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);

    // Function to handle slot selection
    const handleSlotSelection = (slot) => {
        setSelectedSlot(slot); // Set the selected slot
    };

    // Get formatted dates for tabs based on the selected date
    const formatDate = (date) => date.toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
    const nextDay = new Date(selectedDate);
    nextDay.setDate(selectedDate.getDate() + 1);

    // List of slots with availability status
    const slots = {
        morning: [
            { time: '07:30 AM', available: false },
            { time: '07:40 AM', available: true },
            { time: '08:00 AM', available: false },
            { time: '08:45 AM', available: false },
            { time: '09:00 AM', available: true },
            { time: '09:30 AM', available: false },
        ],
        afternoon: [
            { time: '02:30 PM', available: false },
            { time: '02:50 PM', available: true },
            { time: '03:30 PM', available: true },
            { time: '04:00 PM', available: false },
            { time: '04:30 PM', available: true },
            { time: '05:00 PM', available: true },
        ],
        evening: [
            { time: '06:00 PM', available: true },
            { time: '06:30 PM', available: true },
            { time: '07:00 PM', available: true },
            { time: '07:30 PM', available: true },
            { time: '08:00 PM', available: true },
            { time: '08:30 PM', available: true },
        ],
    };
    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Doctor Details</p>
                    </div>
                </div>
            </div>
            <div className="doctor-data-repo">
                <div className="doctor-tepo-image">
                    <img src={appOPDIcon} alt="" />
                    <div className="dot"></div>
                </div>
                <div className="doctor-data-box-tepo">
                    <h5 style={{ marginBottom: '0px' }}>{Data?.name}</h5>
                    <p style={{ fontSize: "11px", color: "#757575", marginBottom: '5px' }}>{Data?.category}</p>
                    <p className="doctor-category"><MdSchool /> {Data?.education}</p>
                    <p className="doctor-category"><FaBuilding /> {Data?.hospital_name}, {Data?.city}</p>
                </div>
            </div>

            <div class="container">
                <div className="repo-cal-clover">
                    <h6>Choose Your Consultation Date</h6>
                    {/* <button onClick={() => setShowCalendar(!showCalendar)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                        <FaCalendarAlt size={24} />
                    </button> */}

                    {/* Only show the calendar when showCalendar is true */}
                    <FaCalendarAlt size={24} />
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        dateFormat="dd MMMM yyyy"
                        minDate={new Date()} // Disable past dates
                        clearIcon={false}
                        inline // Display the calendar inline
                        className="datepicker-calendar"
                    />
                </div>
                {/* <!-- Date Tabs --> */}
                <Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example" className="mb-1 slot-clover-tabs-repo" style={{ justifyContent: 'center', flexFlow: 'row' }}>
                    <Tab eventKey="tab1" title={<><div>{formatDate(selectedDate)}</div><div style={{ fontSize: '10px', marginTop: '3px' }}>20 slots available</div></>} className='tab-box-repo-clover'>
                        <div className="time-slot-section mt-3">
                            <div className="time-slot-header">
                                <h3><IoPartlySunnySharp /> Morning</h3>
                                <span className="slot-count">{slots.morning.filter(slot => slot.available).length} Slots</span>
                            </div>
                            <div className="time-slots">
                                {slots.morning.map((slot, index) => (
                                    <div
                                        key={index}
                                        className={`slot ${slot.available ? 'available' : 'unavailable'} ${selectedSlot === slot.time ? 'selected' : ''}`}
                                        onClick={() => slot.available && handleSlotSelection(slot.time)}
                                    >
                                        {slot.time}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="time-slot-section">
                            <div className="time-slot-header">
                                <h3><MdSunny /> Afternoon</h3>
                                <span className="slot-count">{slots.afternoon.filter(slot => slot.available).length} Slots</span>
                            </div>
                            <div className="time-slots">
                                {slots.afternoon.map((slot, index) => (
                                    <div
                                        key={index}
                                        className={`slot ${slot.available ? 'available' : 'unavailable'} ${selectedSlot === slot.time ? 'selected' : ''}`}
                                        onClick={() => slot.available && handleSlotSelection(slot.time)}
                                    >
                                        {slot.time}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="time-slot-section">
                            <div className="time-slot-header">
                                <h3><IoMdMoon /> Evening</h3>
                                <span className="slot-count">{slots.evening.filter(slot => slot.available).length} Slots</span>
                            </div>
                            <div className="time-slots">
                                {slots.evening.map((slot, index) => (
                                    <div
                                        key={index}
                                        className={`slot ${slot.available ? 'available' : 'unavailable'} ${selectedSlot === slot.time ? 'selected' : ''}`}
                                        onClick={() => slot.available && handleSlotSelection(slot.time)}
                                    >
                                        {slot.time}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="tab2" title={<><div>{formatDate(nextDay)}</div><div style={{ fontSize: '10px', marginTop: '3px' }}>30 slots available</div></>} className='tab-box-repo-clover'>
                        <div className="time-slot-section mt-3">
                            <div className="time-slot-header">
                                <h3><IoPartlySunnySharp /> Morning</h3>
                                <span className="slot-count">{slots.morning.filter(slot => slot.available).length} Slots</span>
                            </div>
                            <div className="time-slots">
                                {slots.morning.map((slot, index) => (
                                    <div
                                        key={index}
                                        className={`slot ${slot.available ? 'available' : 'unavailable'} ${selectedSlot === slot.time ? 'selected' : ''}`}
                                        onClick={() => slot.available && handleSlotSelection(slot.time)}
                                    >
                                        {slot.time}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="time-slot-section">
                            <div className="time-slot-header">
                                <h3><MdSunny /> Afternoon</h3>
                                <span className="slot-count">{slots.afternoon.filter(slot => slot.available).length} Slots</span>
                            </div>
                            <div className="time-slots">
                                {slots.afternoon.map((slot, index) => (
                                    <div
                                        key={index}
                                        className={`slot ${slot.available ? 'available' : 'unavailable'} ${selectedSlot === slot.time ? 'selected' : ''}`}
                                        onClick={() => slot.available && handleSlotSelection(slot.time)}
                                    >
                                        {slot.time}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="time-slot-section">
                            <div className="time-slot-header">
                                <h3><IoMdMoon /> Evening</h3>
                                <span className="slot-count">{slots.evening.filter(slot => slot.available).length} Slots</span>
                            </div>
                            <div className="time-slots">
                                {slots.evening.map((slot, index) => (
                                    <div
                                        key={index}
                                        className={`slot ${slot.available ? 'available' : 'unavailable'} ${selectedSlot === slot.time ? 'selected' : ''}`}
                                        onClick={() => slot.available && handleSlotSelection(slot.time)}
                                    >
                                        {slot.time}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default TimeSlots