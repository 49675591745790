import React from 'react';
import './RecentLoader.css'; // Assuming you create a CSS file for styles

const RecentLoader = () => {
    return (
        <div className='repo-data-load'>
            <div className="loader-repo"></div>
        </div>
    );
};

export default RecentLoader;
