import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { medi_wallet_image, savings_image } from '../Pages/Medicine/Images';
import '../Pages/Medicine/Medicine_Style/order-style.css';
import { appDeliverLocation, appEmptyCart } from '../images';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useRazorpay from 'react-razorpay';
import LoaderAnime from "../Pages/Medicine/animation/prescription_loader";
import toast from 'react-hot-toast';
import { fetchMedicineCartList, fetchMedicineOrderList, fetchPackageList } from '../Redux/actions/GetApiCall';
import Need_Help from '../Pages/Medicine/Components/Need_Help';
import Cart_Card from '../Pages/Medicine/Components/Cart_Card';
import Medicine_Header from '../Pages/Medicine/Components/Medicine_Header';
import Medicine_footer from '../Pages/Medicine/Components/Medicine_footer';
import EmptyCart from '../Pages/Medicine/Components/EmptyCart';
import { orderMedicine } from '../Redux/actions/PostApiCall';

const AutoPlaceOrder = () => {
    // const Title = "Placed Order";
    const [Razorpay] = useRazorpay();
    const dispatch = useDispatch();
    const naviagte = useNavigate();
    const [cartListData, setCartListData] = useState([]);
    // const [cartUpdatedData, setCartUpdatedData] = useState([]);
    const { data: MedicineData, loader: cartLoader } = useSelector(state => state.medicine_cart_list);
    const CartStatus = useSelector(state => state.medicine_cart_list.status);
    console.log(MedicineData)
    const { data: MedicineOrderData } = useSelector(state => state.medicine_order_list);
    const orderedData = useSelector(state => state.order_medicine.data);
    const orderResponse = useSelector(state => state.order_medicine)
    const packagesData = useSelector(state => state.package_list);
    const [selectedData, setSelectedData] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const [walletId, setWalletId] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const AppToken = localStorage.getItem("APP_TOKEN");
    const AutoToken = localStorage.getItem("AUTO_TOKEN");
    // const [paymentClose, setPaymentClose] = useState(false);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const autoParam = queryParams.get('auto');
    const pincodePara = queryParams.get('pincode');

    useEffect(() => {
        const storedToken = localStorage.getItem("AUTO_TOKEN");
        const pincodeToken = localStorage.getItem("pincode");
        const tokenTimestamp = localStorage.getItem("AUTO_TOKEN_TIMESTAMP");

        const THREE_HOURS = 3 * 60 * 60 * 1000;
        const currentTime = new Date().getTime();

        // Handle reload logic
        // if (localStorage.getItem("reload_triggered") === "true") {
        //     window.history.pushState({}, "", window.location.pathname);
        //     localStorage.removeItem("reload_triggered");
        //     return;
        // }

        // Set token and pincode if conditions are met, and store the current timestamp
        if (autoParam && storedToken !== autoParam && pincodePara && pincodeToken !== pincodePara) {
            localStorage.setItem("AUTO_TOKEN", autoParam);
            localStorage.setItem("pincode", pincodePara);
            localStorage.setItem("AUTO_TOKEN_TIMESTAMP", currentTime.toString()); // Save timestamp
            localStorage.setItem("reload_triggered", "true");
            window.location.reload();
        }
        // If there's an AUTO_TOKEN and timestamp, check if it has expired (3 hours passed)
        if (storedToken && tokenTimestamp && (currentTime - tokenTimestamp) > THREE_HOURS) {
            // Clear specific token-related items instead of clearing the whole localStorage
            localStorage.removeItem("AUTO_TOKEN");
            localStorage.removeItem("AUTO_TOKEN_TIMESTAMP");
            localStorage.removeItem("pincode");
        }

    }, [autoParam, location.search, pincodePara]);


    const cartUpdatedData = useSelector((state) => state.medicine_cart_list.data);

    useEffect(() => {
        dispatch(fetchPackageList())
        dispatch(fetchMedicineOrderList())
    }, [dispatch])


    const handleSelectPackage = (e) => {
        const selectedValue = e.target.value;
        console.log(selectedValue)
        if (selectedValue) {
            try {
                const selectedItem = JSON.parse(selectedValue);
                console.log(selectedItem)
                setSelectedPackage(selectedItem?.id);
                setSelectedData(selectedItem);
                setIsChecked(false);
                setWalletId("");
            } catch (error) {
                console.error("Invalid JSON input:", selectedValue);
            }
        } else {
            setSelectedPackage(""); // Reset selection if value is empty
            setSelectedData("");
        }
    }


    const handleWalletChange = (e) => {
        const isCheckedNow = e.target.checked;
        setIsChecked(isCheckedNow);
        if (isCheckedNow) {
            setWalletId(selectedData?.wallet_id);
        } else {
            setWalletId("");
        }
    };

    const DataId = {
        medicine_order_id: orderedData?.medicine_order_id,
        onemg_order_id: orderedData?.onemg_order_id
    }

    useEffect(() => {
        if (orderedData?.success === true && orderedData?.out_of_stock?.length === 0 && orderedData?.is_payable === 0) {
            setSuccessModal(true);
            setTimeout(() => {
                setSuccessModal(false)
                naviagte(`/medicine-thank-you?auto=${AutoToken}`, { state: DataId });
            }, 3000);
        }
        // else if (orderedData?.success === false && orderedData?.out_of_stock?.length === 0) {
        //     setSuccessModal(true);
        //     setTimeout(() => {
        //         setSuccessModal(false)
        //         naviagte('/medicines');
        //     }, 3000);
        // }
        // else if (orderedData?.success === true && orderedData?.out_of_stock?.length > 0) {
        //     toast.error(orderedData?.message);
        //     setTimeout(() => {
        //         naviagte('/cart');
        //     }, 2000);
        // }
        else {
            setSuccessModal(false)
        }
    }, [orderedData, naviagte]);

    useEffect(() => {
        if (orderedData?.success === true && orderedData?.is_payable === 1 && orderedData?.out_of_stock?.length === 0 && orderResponse?.status === "success") {
            const optionsData = orderedData?.razorpay_details;

            const options = {
                key: optionsData?.key,
                amount: optionsData?.amount,
                currency: optionsData?.currency,
                name: optionsData?.name,
                description: optionsData?.description,
                order_id: optionsData?.razorpay_order_id,
                handler: async (response) => {
                    console.log("Payment successful", response);
                    if (response) {
                        const data = {
                            medicine_order_id: MedicineOrderData?.data?.medicine_order_id,
                            transaction_id: response?.razorpay_payment_id
                        }
                        setShowModal(true)
                        try {
                            const cartUpdateData = await fetch(`${process.env.REACT_APP_NEW_APP_API_URL}/employee-auto-login/medicine-capture-payment-details`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: autoParam ? AutoToken : AppToken,
                                },
                                body: JSON.stringify(data),
                            });

                            console.log(cartUpdateData)

                            const CartpaymentData = await cartUpdateData.json();
                            console.log(CartpaymentData)
                            if (CartpaymentData.success === true) {
                                naviagte(`/medicine-thank-you?auto=${AutoToken}`, { state: DataId });
                                // window.location.reload();
                            }
                            else if (CartpaymentData.success === false) {
                                toast.error(CartpaymentData.message);
                                setTimeout(() => {
                                    naviagte(`/medicine-thank-you?auto=${AutoToken}`, { state: DataId });
                                }, 1500);
                                // window.location.reload();
                            }
                            else if (cartUpdateData.ok === false) {
                                naviagte(`/medicine-thank-you?auto=${AutoToken}`, { state: DataId });
                                // window.location.reload();
                            }
                        } catch (error) {
                            console.log(error);
                        } finally {
                            setShowModal(false)
                        }
                        // await dispatch(captureOrderMedicine(data))
                    }
                },
                prefill: {
                    name: optionsData?.prefill?.name,
                    email: optionsData?.prefill?.email,
                    contact: optionsData?.prefill?.contact,
                },
                theme: {
                    color: "#3399cc",
                },
                modal: {
                    ondismiss: function () {
                        naviagte(`/medicine-thank-you?auto=${AutoToken}`, { state: DataId });
                    }
                }

                // callback_url:optionsData?.callback_url
            };

            const rzp1 = new Razorpay(options);
            rzp1.open();
        }
    }, [orderedData]);

    const handleOrderMedicine = async () => {
        setShowModal(true)
        const orderData = {
            medicine_order_id: MedicineOrderData?.data?.medicine_order_id,
            package_id: selectedPackage,
            wallet_id: walletId,
            source: 0
        }
        await dispatch(orderMedicine(orderData))
    }


    // useEffect(() => {
    //     if (MedicineData?.available_stock?.length === 0 && tabkey === "medicines") {
    //         naviagte('/medicines')
    //     }
    // }, [MedicineData])
    // const handleAddressChange = () => {
    //     naviagte('/address-list', { state: tabkey })
    // }

    useEffect(() => {
        // If `selectedPackage` is either empty or has a value, or when `walletId` changes
        if (selectedPackage !== undefined || walletId !== "") {
            dispatch(fetchMedicineCartList(selectedPackage, walletId));
        }
    }, [selectedPackage, walletId, dispatch]);

    const Title = "Placed Order"
    const tabKeyRepo = "medicines";

    console.log(CartStatus)

    useEffect(() => {
        if (CartStatus === "loading") {
            setShowModal(true)
        }
        else if (CartStatus === "idle") {
            setShowModal(false)
        }
    }, [CartStatus])

    return (

        <div className="home-review-banner">
            <Medicine_Header Title={Title} />

            {showModal && <LoaderAnime showModal={showModal} />}

            {(MedicineData?.total_item > 0 || MedicineData?.total_items > 0) ? (
                <>
                    <Cart_Card setCartListData={setCartListData} MedicineData={MedicineData} cartListData={cartListData} cartLoader={cartLoader} selectedPackage={selectedPackage} walletId={walletId} />


                    {packagesData?.data?.active_package?.length > 0 && (
                        <div className="packages-cover-wrapper">
                            <div className="options-wrapper">
                                <select value={selectedData ? JSON.stringify(selectedData) : ''} onChange={handleSelectPackage}>
                                    <option value="">Select Package</option> {/* Default selected and disabled */}
                                    {packagesData?.data?.active_package?.map((item) => (
                                        <option key={item.id} value={JSON.stringify(item)}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        </div>
                    )}

                    {selectedData !== "" && (
                        <>
                            <div className="wallet-repo-wrapper">
                                <div className="check-data-wrapper">
                                    <div className="checkbox-wrapper">
                                        <input
                                            id="terms-checkbox-37"
                                            name="checkbox"
                                            type="checkbox"
                                            checked={isChecked} // Bind to the checkbox state
                                            onChange={handleWalletChange} // Handle change event directly
                                        />
                                        <label className="terms-label" htmlFor="terms-checkbox-37">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                                <mask fill="white" id="path-1-inside-1_476_5-37">
                                                    <rect height="200" width="200"></rect>
                                                </mask>
                                                <rect mask="url(#path-1-inside-1_476_5-37)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                                <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                            </svg>
                                        </label>
                                    </div>
                                    <div className="wrapper-text-content-box">
                                        <p>Medibhai Wallet</p>
                                        <span>Remaining Balance : ₹ {selectedData?.wallet_details?.medicine?.remaining}</span>
                                    </div>
                                </div>
                                <div className="used-data-wrapper">
                                    <img src={medi_wallet_image} alt="" />
                                </div>

                            </div>
                            {isChecked && cartUpdatedData?.payable_amount > 0 && cartLoader === false && (
                                <div className="wallet-heading-insu">
                                    <p>
                                        Your wallet balance is insufficient ₹{cartUpdatedData?.payable_amount}  payment is required online
                                    </p>
                                </div>
                            )}
                        </>
                    )}

                    <div className="payment-repo-cover-box">
                        <div className="payment-text-repo">
                            <p>Payment Summary</p>
                        </div>

                        <div className="payment-summary-box-repo">
                            {cartUpdatedData?.vas_charges?.details?.map((item, index) => (
                                <div className="price-payment-sum-repo" key={index}>
                                    <p>{item.display_text}</p>
                                    <p>₹ {item.amount?.toLocaleString('en-IN')}</p>
                                </div>
                            ))}
                            {cartUpdatedData?.wallet_deducted?.map((item, index) => (
                                <div className="price-payment-sum-repo" key={index}>
                                    <p>MB Wallet</p>
                                    <p>- ₹ {item.amount?.toLocaleString('en-IN')}</p>
                                </div>
                            ))}
                        </div>
                        <div className="fin-text-repo">
                            <div className="price-payment-sum-repo total-pay-text">
                                <p>Total Payable</p>
                                <p>₹ {cartUpdatedData?.payable_amount?.toLocaleString('en-IN')}</p>
                            </div>
                            <div className="price-payment-sum-repo total-save-text">
                                <p><img src={savings_image}></img>
                                    Total Savings</p>
                                <p>₹ {cartUpdatedData?.total_saving?.toLocaleString('en-IN')}</p>
                            </div>
                        </div>
                    </div>

                    <Need_Help type="medicine" />

                    <div className="address-wrapper-box-order">
                        <div className="address-content-box-save">
                            <img
                                src={appDeliverLocation}
                                width="100%"
                                alt="location"
                            />

                            <div className="address-content-repo-text">
                                <p>Deliver to {MedicineOrderData?.data?.address?.address_type} <br /> {MedicineOrderData?.data?.address?.address},{MedicineOrderData?.data?.address?.landmark},<br />{MedicineOrderData?.data?.address?.city},{MedicineOrderData?.data?.address?.state}-{MedicineOrderData?.data?.address?.pincode}</p>
                            </div>
                        </div>

                        {/* <div className="chnage-address-btn">
                    <button onClick={handleAddressChange}>Change</button>
                </div> */}
                    </div>
                </>
            ) : (
                <div className="medicines-empty-cart-cover">
                    <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
                        <div className="empty-cart-image">
                            <img
                                src={appEmptyCart}
                                width="100%"
                                alt="EmptyCart"
                            />
                        </div>
                        <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                            <p>Your Product Cart is empty</p>
                        </div>
                    </div>
                </div>
            )}

            {MedicineData?.total_items > 0 && (
                <Medicine_footer tabkey={tabKeyRepo} handleOrderMedicine={handleOrderMedicine} />
            )}
            {/* {orderedData && <Medicine_Success showModal={successModal} message={paymentClose ? "Payment Cancelled" : orderedData?.message} status={paymentClose ? false : orderedData?.success} />} */}
        </div>
    )
}

export default AutoPlaceOrder;