import React from 'react'
import SuccessPack from '../../../Animation/SuccessPack'
import Pending from '../../../Animation/Pending'
import ErrorPack from '../../../Animation/ErrorPack'
import OrderRecent from './OrderRecent'

const ThankMedicine = ({ address, product, ThankData }) => {
    const UrlData = window.location.pathname;
    return (
        <div>
            <div className='thank-rep-wrapper'>
                {ThankData?.data?.order_status === "Order Placed" && (
                    <div className="order-status-data-repo-wrapper">
                        <SuccessPack />
                        <p>Your order placed successfully!</p>
                    </div>
                )}
                {ThankData?.data?.order_status === "Payment Pending" && (
                    <div className="order-status-data-repo-wrapper pending-anime">
                        <Pending />
                        <p>Your order is pending.</p>
                        <span>We are currently verifying your payment status. If you have already completed the payment, please allow up to 30 minutes for your order status to be updated.</span>
                    </div>
                )}
                {ThankData?.data?.order_status === "Order Cancelled" && (
                    <div className="order-status-data-repo-wrapper cancelled-anime">
                        <ErrorPack />
                        <p>Your order has been cancelled.</p>
                    </div>
                )}



                {address && (
                    <div className="order-shipping-address-wrapper">
                        <div className="heading-ship">
                            <p>Shipping Address</p>
                        </div>
                        <div className="ship-address-content">
                            <p>Name: {address?.name}</p>
                            <p>Mobile Number: {address?.mobile_no}</p>
                            <p>Address: {address?.address}</p>
                            <p>Pincode: {address?.pincode}  , <span>City: {address?.city}</span></p>
                            <p>State: {address?.state}</p>
                        </div>
                    </div>
                )}

                {product && (
                    <div className="order-shiping-order-wrapper">
                        <div className="order-head-item-text">
                            <p>Product Details</p>
                        </div>
                        <div className="product-content-box-repo-wrapper-box">
                            <div className="left-side-box-order">
                                <img src={product?.banner_url} alt="" />
                                {ThankData?.data?.medicine_count > 0 && (
                                    <p>+{ThankData?.data?.medicine_count} More Products</p>
                                )}
                            </div>
                            <div className="right-side-box-order">
                                <p className='ellipsis-text-cart'>{product?.name}</p>
                                <div className="pridc-order-repo">
                                    <p>
                                        {(product?.discounted_price !== null && product?.discounted_price !== 0)
                                            ? (String(product?.discounted_price).startsWith('₹') ? product?.discounted_price : `₹${product?.discounted_price}`)
                                            : (product?.price !== 0 && product?.price !== null)
                                                ? (String(product?.price).startsWith('₹') ? product?.price : `₹${product?.price}`)
                                                : "No price available"}
                                    </p>
                                    <span>
                                        {(product?.discounted_price !== null && product?.discounted_price !== 0) &&
                                            (String(product?.price).startsWith('₹') ? product?.price : `₹${product?.price}`)}
                                    </span>
                                </div>
                                <p className='qty-order-qty'>Quantity:{product?.quantity}</p>
                            </div>
                        </div>
                    </div>
                )}


                {UrlData !== "/medicine-thank-you" && (

                    ThankData?.data?.previous_order_list && ThankData?.data?.previous_order_list?.length > 0 && (
                        <div className="recent-order-data-repoo-wrapper">
                            <div className="repo-recent-heade">
                                <p>Recent Ordered Products</p>
                            </div>

                            <OrderRecent mediData={ThankData?.data?.previous_order_list} />

                        </div>
                    )

                )}
            </div>
        </div>
    )
}

export default ThankMedicine