import React, { useEffect, useState } from 'react'
import { formatYY_MM_DD } from '../../../../../utils/AllFunction';
import { useSelector } from 'react-redux';

const LabTimeData = ({ setSelectedDate, setSelectedTimeSlot }) => {
    const DateData = useSelector(state => state.get_time_slots);
    const DateRespo = DateData?.data?.response;
    console.log(DateData)
    const [activeIndex, setActiveIndex] = useState(0);

    const [currentMonth, setCurrentMonth] = useState("");

    useEffect(() => {
        const today = new Date();
        const month = today.toLocaleDateString("en-US", {
            month: "long",
            year: "numeric",
        });
        setCurrentMonth(month);
        const currentDate = new Date(DateRespo?.date[0]);
        setSelectedDate(formatYY_MM_DD(currentDate));
    }, [DateRespo]);

    const handleTimeSlot = (currentDate, index) => {
        const newDate = formatYY_MM_DD(currentDate)
        setSelectedDate(newDate)
        setActiveIndex(index);
    };

    const handleTimeSlotChange = (event) => {
        setSelectedTimeSlot(event.target.value);
    };

    return (
        <div>
            <div className="date-time-slot-main-cover-wrapper">
                <div className="date-calender-box">
                    <div className="date-heading-box">
                        <p>Select date : {currentMonth}</p>
                    </div>
                    <div className="date-slot-main-wrapper lab-date-repo">
                        {DateRespo?.date?.length > 0 &&
                            DateRespo?.date?.map(
                                (time_data, index) => {
                                    const currentDate = new Date(time_data);
                                    const day = currentDate.toLocaleDateString("en-US", {
                                        weekday: "short",
                                    });
                                    const date = currentDate.getDate();
                                    const month = currentDate.toLocaleDateString("en-US", {
                                        month: "short",
                                    });

                                    return (
                                        <div className="date-box" key={time_data}>
                                            <div className="per-date-box">
                                                <button
                                                    id="date"
                                                    className={`element ${index === activeIndex ? "activeDatebox" : ""
                                                        }`}
                                                    onClick={() =>
                                                        handleTimeSlot(currentDate, index)
                                                    }
                                                >
                                                    <p>{day}</p>
                                                    <hr className="element1" />
                                                    <span>{date}</span>
                                                    <br />
                                                    <span>{month}</span>
                                                </button>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                    </div>
                    <div className="time-section">
                        <div className="time-heading">
                            <p>Select Time</p>

                            <div className="patient-input-form">
                                <select
                                    name="time"
                                    id="time"
                                    className="relation"
                                    onChange={handleTimeSlotChange}
                                >
                                    <option value="">Select Time</option>
                                    {DateRespo?.time_slot?.length >
                                        0 &&
                                        DateRespo?.time_slot?.map(
                                            (timeSlot, index) => (
                                                <option key={index} value={timeSlot}>
                                                    {timeSlot}
                                                </option>
                                            )
                                        )}
                                </select>
                                {DateRespo &&
                                    DateRespo?.time_slot?.length ===
                                    0 && (
                                        <p
                                            style={{
                                                color: "red",
                                                fontSize: "13px",
                                                marginLeft: "3px",
                                            }}
                                        >
                                            No time slots available
                                        </p>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LabTimeData