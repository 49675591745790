import { React, useContext, useEffect, useState } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import Accordion from "react-bootstrap/Accordion";
import Loader from "../../../Animation/Loader";
import NoDataFoundComp from "../../../components/NoDataFound";
import { REQUESTOPTIONS, MY_ACCOUNT_API , MY_ORDERS_API } from "../../../Constants";
import { ArrowBack, appCart, appNotification , dummyMedcine , appEmptyCart} from "../../../images";
import { NavLink , useNavigate } from "react-router-dom";
import "swiper/css";
import { Navigation} from 'swiper/modules';
import 'swiper/css/navigation';
import { IoHome } from "react-icons/io5";



const NewOrderDetails = (props) => {
  const [isPackageDataloading, setPackageDataLoading] = useState(true);
  const [teleConsultationData, setTeleConsultationData] = useState([]);
  const [diagnosticsData, setDiagnosticsData] = useState([]);
  const [orderedMedicineData, setOrderedMedicineData] = useState([]);
  const token = localStorage.getItem("ACCESS_TOKEN");
  const navigate = useNavigate();
  const [key, setKey] = useState("first");


  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${props?.token ?? token}`,
    },
  };

  async function getOrderDetails() {
    try {
      const response = await fetch(
        `${MY_ORDERS_API}`,
        requestOptions
      );
      const res = await response.json();
      setOrderedMedicineData(res?.data?.order_medicine);
      setTeleConsultationData(res?.data?.order_teleconsultation);
      setDiagnosticsData(res?.data?.order_diagnostics);
      setPackageDataLoading(false)
     
    } catch (error) {
      setPackageDataLoading(false)
      console.error(error);
    }
  }

  useEffect(() => {
    getOrderDetails();
  }, []);


  const navigateTrackOrder = (medicineData ,  childData , index1Data) => {
    
    const sendOrderData = {
      order_id : medicineData?.order_id ,
      payment_type : medicineData?.razorpay_responce,
      order_Items : index1Data != null ? index1Data : childData
    }

    navigate("/trackorder", { state: sendOrderData });
  };

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header">
          
          <div className="header-logo ">
            <div className="user-name text-center">
              <p>Order Details</p>
            </div>
            <div className="order-home-repo">
              <NavLink to={'/home'}>
                <IoHome />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="tab-main-cover-wrapper">
          <Tab.Container id="left-tabs-example" defaultActiveKey={key}>
            <Row>
              <Col lg={12} className="mb-2 tab-cover order-details-tab">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={2.5}
                  navigation={true}
                  pagination={{ clickable: true }}
                  modules={[Navigation]}
                  className="order-tab-swiper"
                >
                  <SwiperSlide>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          onSelect={() => setKey("first")}
                        >
                          Medicine Order
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          onSelect={() => setKey("second")}
                        >
                          Lab Tests
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="third"
                          onSelect={() => setKey("third")}
                        >
                          Teleconsultation
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="fourth"
                          onSelect={() => setKey("fourth")}
                        >
                          Doctor Appointments
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </SwiperSlide> */}
                  {/* Add more SwiperSlides for additional tabs if needed */}
                </Swiper>
              </Col>
 
              <Col lg={12}>
              <>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="lab-test-main-wrapper-cover">
                          <div className="medicine-main-cover-wrapper">
                            {isPackageDataloading ? (
                              <Loader />
                            ) : orderedMedicineData?.length ? (
                              orderedMedicineData.map((medicine_data, ind) => {
                                return (
                                  <>
                                      <div className="medicine-card-cover-main-wrapper">
                                          <div className="medicine-card-cover">
                                              <div className="row">
                                                  <div className="col-3">
                                                      <div className="medicine-img-box">
                                                      <img
                                                          src={medicine_data?.order_items[0]?.image}
                                                          alt="medicine-img-box"
                                                      ></img>
                                                      </div>
                                                  </div>
                                                  <div className="col-9">
                                                      <div className="medicine-details-box">
                                                      <h6>
                                                          {medicine_data?.order_items[0]?.medicine_name}
                                                      </h6>
                                                      <h6 className="order_id">
                                                          Order ID :{" "}
                                                          <span>
                                                          {medicine_data?.order_id}
                                                          </span>
                                                      </h6>
                                                      <ul>
                                                          <li>
                                                          Quantity :
                                                          <span>
                                                              {medicine_data?.order_items[0]?.quantity}
                                                          </span>
                                                          </li>
                                                          <li>
                                                          Payment :{" "}
                                                          <span>
                                                              {medicine_data?.razorpay_responce}
                                                          </span>
                                                          </li>
                                                      </ul>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="medicine-order-status-cover-wrapper">
                                                  <div className="medicine-order-status-box">
                                                      <div className='medicine-order-content-box'>
                                                          {medicine_data?.order_items[0]?.delivery_status != "" && (
                                                              <h6>Order Status :  <span className={`${medicine_data?.order_items[0]?.delivery_status == "Cancelled" ? "highlight-red" : "highlight-blue"}`}>
                                                                                                      {medicine_data?.order_items[0]?.delivery_status}
                                                                                                  </span></h6>
                                                          )}
                                                          {medicine_data?.order_items[0]?.delivery_by != "" && (
                                                              <h6>Delivered :  <span className="highlight-blue">{medicine_data?.order_items[0]?.delivery_by}</span></h6>
                                                          )}
                                                          
                                                      </div>
                                                      <div className="track-order-box">
                                                          <button className="track-order-btn" onClick={() =>navigateTrackOrder(medicine_data ,medicine_data?.order_items[0], null)}>Track Order</button>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <Accordion
                                                  defaultActiveKey={null}
                                                  flush
                                                  className="accordion-product-wrapper"
                                              >
                                                  <Accordion.Item eventKey={ind}>
                                                      {medicine_data?.order_items?.length > 1 && (
                                                          <Accordion.Header>
                                                              View
                                                              <span className="mx-1 product-count-box">
                                                              {medicine_data?.order_items?.length - 1}
                                                              </span>
                                                              more product(s)
                                                          </Accordion.Header>
                                                      )}
                                                      <Accordion.Body>
                                                          <div className="medicine-inner-product-wrapper">
                                                              {medicine_data?.order_items?.slice(1).map((orderedData) => {
                                                                      return (
                                                                          <div className="medicine-inner-product mb-3">
                                                                              <div className="row">
                                                                                  <div className="col-3">
                                                                                      <div className="medicine-inner-product-img">
                                                                                      <img
                                                                                          src={orderedData?.image}
                                                                                          alt="ordered-medicine"
                                                                                      ></img>
                                                                                      </div>
                                                                                  </div>
                                                                                  <div className="col-9">
                                                                                      <div className="medicine-inner-product-details">
                                                                                          <div className="medicine-details-box">
                                                                                              <h6>
                                                                                                  {orderedData?.medicine_name}
                                                                                              </h6>
                                                                                              <h6>
                                                                                              Quantity : {orderedData?.quantity}
                                                                                              </h6>
                                                                                          </div>
                                                                                          
                                                                                          
                                                                                      </div>
                                                                                      

                                                                                  </div>
                                                                              </div>
                                                                              <div className="medicine-order-status-box">
                                                                                          <div className='medicine-order-content-box'>
                                                                                              <h6>Order Status :  
                                                                                                  <span className={`${orderedData?.delivery_status === "Cancelled" ? "highlight-red" : "highlight-blue"}`}>
                                                                                                      {orderedData?.delivery_status}
                                                                                                  </span>
                                                                                              </h6> 
                                                                                          </div>
                                                                                          <div className="track-order-box">
                                                                                              <button className="track-order-btn" onClick={() =>navigateTrackOrder(medicine_data,orderedData , null)}>Track Order</button>
                                                                                          </div>
                                                                                      </div>
                                                                          </div>
                                                                      )
                                                              })}
                                                          </div>
                                                      </Accordion.Body>
                                                  </Accordion.Item>
                                          </Accordion>
                                      </div>
                                  </>
                                  );
                                })
                              ) : (
                                <NoDataFoundComp />
                              )}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="teleconsultation-main-wrapper-cover">
                          <div className="">
                            {diagnosticsData?.length > 0 ? diagnosticsData?.map((diagData) => {
                              return (
                                <ul className="labtest-card labtest-bg mb-3">
                                <li>
                                  Patient :{" "}
                                  <span>{diagData?.patient_name}</span>(
                                  <span className="age">
                                    {diagData?.patient_age}
                                  </span>
                                  /
                                  <span>
                                    {diagData?.patient_gender}
                                  </span>
                                  )
                                </li>
                                <li>{diagData?.testname}</li>
                                <li>
                                  Appointment Date :{" "}
                                  <span>{diagData?.visit_date}</span>
                                </li>
                                <li>
                                  Appointment Time :{" "}
                                  <span>{diagData?.visit_time}</span>
                                </li>
                                <li>
                                  Booked on :{" "}
                                  <span>{diagData?.created_on}</span>
                                </li>
                                <li>
                                  Fees : ₹<span>{diagData?.cost}</span>
                                </li>
                                </ul>
                              )
                            }) : (
                              <div className="medicines-empty-cart-cover">
                                <div className="cart-box-image d-flex flex-column justify-content-center align-items-center">
                                  <div className="empty-cart-image">
                                    <img
                                      src={appCart}
                                      width="100%"
                                      alt="EmptyCart"
                                    />
                                  </div>
                                  <div className="cart-content d-flex flex-column justify-content-center align-items-center">
                                    <p>Your Cart is empty</p>
                                    <NavLink to="/diagnostictest">
                                      <button>
                                        <p>ADD TESTS</p>
                                      </button>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        {teleConsultationData?.length == 0 ? (
                          <NoDataFoundComp />
                        ) : (
                          <div className="teleconsultation-main-wrapper-cover">
                            {teleConsultationData?.length > 0 && teleConsultationData?.map((booking_data,ind) =>{
                              return (
                                <div className="labtest-card mb-3" key={ind}>
                                  <ul>
                                    <li>
                                      Patient :{" "}
                                      <span>
                                        {booking_data?.patient_name}
                                      </span>
                                      (
                                      <span className="age">
                                        {booking_data?.patient_age}
                                      </span>
                                      /
                                      <span>
                                        {booking_data?.patient_gender}
                                      </span>
                                      )
                                    </li>
                                    <li>{booking_data?.department_name}</li>
                                    <li>
                                      Appointment Date :{" "}
                                      <span>
                                        {booking_data?.appointment_date}
                                      </span>
                                    </li>
                                    <li>
                                      Booked on :{" "}
                                      <span>
                                        {booking_data?.bookedDate}
                                      </span>
                                    </li>
                                    <li>
                                      Appointment Time :{" "}
                                      <span>
                                        {booking_data?.appointment_time}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              )
                            })}
                            
                          </div>
                        )}
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="fourth">
                        <div className="teleconsultation-main-wrapper-cover">
                          <h5>No Data Found</h5>
                        </div>
                      </Tab.Pane> */}
                    </Tab.Content>
                  </>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default NewOrderDetails;
