import React from "react";
import { pdfIcon, RecordPrescriptionIcon } from "../../../images";
import { useNavigate } from "react-router";

const HealthRecordCard = ({ btnText, record, recordkey }) => {
  const navigate = useNavigate();

  const handlePrescripDownload = (downloadLink) => {
    // Create an anchor element
    const link = document.createElement("a");
    link.href = downloadLink;
    document.body.appendChild(link);
    // Programmatically click the anchor to trigger the download
    link.click();
    document.body.removeChild(link);
  };

  const navigateToOrderDetails = (record) => {
    if(recordkey == 0){
        const data = {
          orderId: record?.consultation_id,
          tabKey: "first",
        };
        navigate("/teleconsultation-order-details", { state: data });
    }else{
        const data={
            orderId:record?.order_id,
            tabKey: "first"
        }
        navigate('/lab-order-details', { state: data })
    }
  };    

  console.log(record?.prescription_download_link)
  return (
    <>
      <div className="health-records-card">
        <div className="health-card-box-cover order-box-coverdetails">
          <div className="health-records-card-details">
            <div className="health-record-img-icon">
                <img src={pdfIcon} alt="order-image" />
            </div>
            <div className="health-record-content-detail-box">
              <h5>
                Uploaded on <span>{record?.created_on}</span>
              </h5>
              <div className="health-record-content-detail">
                <h6>{record?.patient_name || record?.member_name}</h6>
              </div>
            </div>
          </div>
          <div className="health-record-card-footer">
            <button
              className="health-record-appointment-btn"
              onClick={() => navigateToOrderDetails(record)}
            >
              Appointment Details
            </button>
            <button
              className="health-record-download-btn"
              onClick={() =>
                handlePrescripDownload(record?.prescription_download_link)
              }
            >
              {recordkey == 1 ? "Download Reports" : "Download Prescription"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HealthRecordCard;
