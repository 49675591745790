import React, { useState } from 'react'
import { DoctorData } from '../../data'
import DoctorCard from './DoctorCard'
import './doctorStyle.css'
import { headerLogo } from '../../images'
import { FiSearch } from "react-icons/fi";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const Doctor = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');

    const filteredDoctorsBySearch = DoctorData.filter(doctor =>
        doctor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doctor.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doctor.city.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const categories = ['All', ...new Set(DoctorData.map(doctor => doctor.category))];

    const filteredDoctors = filteredDoctorsBySearch.filter(doctor => {
        if (selectedCategory === 'All') {
            return true;
        }
        return doctor.category === selectedCategory;
    });

    console.log(filteredDoctors)

    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Doctor</p>
                    </div>
                </div>
            </div>

            {/* Search bar */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search doctor..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;  // Regular expression to allow letters and spaces only
                        if (!regex.test(e.key)) {
                            e.preventDefault(); // Prevent non-letter characters
                        }
                    }}
                />
                <FiSearch />
                <div className="filter-buttons">
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={2}
                        effect="slide"
                        grabCursor={true}

                    >
                        <div className="swiper-wrapper">
                            {categories.map((category, index) => (
                                <SwiperSlide
                                    key={index}
                                    className={`repo-cate-doc ${selectedCategory === category ? 'btn-active' : ''}`}
                                    onClick={() => setSelectedCategory(category)}
                                >
                                    {category}
                                </SwiperSlide>
                            ))}
                        </div>
                    </Swiper>
                </div>
            </div>

            {/* Category filter buttons */}


            {/* Doctor List */}
            <div className="doctor-grid">
                {filteredDoctors?.length === 0 && (
                    <div className="no-doctor-found" style={{ height: '60vh', display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontSize: '18px', fontWeight: '500' }}>No Doctor Found</p>
                    </div>
                )}
                {filteredDoctors.map((doctor, index) => (
                    <DoctorCard key={index} doctor={doctor} />
                ))}
            </div>
        </div>
    )
}

export default Doctor;
