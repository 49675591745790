import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FaStar } from "react-icons/fa6";
import { no_preview } from '../Images';
import CartLoader from '../animation/CartLoader';
import useCartFunction from '../hooks/useCartFunction';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ToastBox from '../animation/ToastBox';


const Medicine_Recent = ({ mediData, similarId, setCartListData }) => {

    const navigate = useNavigate();
    const { availability, cartStatus, cart, CartData, DeleteStatus, loadingItems, handleCartAdd, handleIncrement, handleDecrement, handleDelete } = useCartFunction(null, null, setCartListData,null,null);
    const ListStatus = useSelector(state => state.medicine_list.status);
    const hanldeDescription = (item) => {
        const data = {
            Title: "Product Details",
            data: item
        };
        navigate('/medicine-description', { state: data });
    };

    return (
        <>
            <div className='recent-order-repo-box'>
                <Swiper className="mySwiper" slidesPerView={2.1}>
                    {mediData?.data?.filter(item => item?.sku_id !== similarId).map((item, index) => {
                        const cartItem = cart.find(cartItem => cartItem.sku_id === item.sku_id);
                        return (
                            <SwiperSlide key={index}>
                                <div className="recent-product-data-repo-cover" onClick={() => hanldeDescription(item)}>
                                    <div className="image-cover-recent">
                                        <img
                                            src={item?.banner_url || no_preview}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = no_preview;
                                            }}
                                            alt="product-icon"
                                        />
                                    </div>
                                    <p className='recent-ellipsis-text'>{item?.name}</p>
                                    <div className="price-repo-text">
                                        <div className="price-tag-repo">
                                            <p>
                                                {(item?.prices?.discounted_price !== null && item?.prices?.discounted_price !== 0)
                                                    ? (String(item?.prices?.discounted_price).startsWith('₹') ? item?.prices?.discounted_price : `₹${item?.prices?.discounted_price}`)
                                                    : (item?.prices?.mrp !== 0 && item?.prices?.mrp !== null)
                                                        ? (String(item?.prices?.mrp).startsWith('₹') ? item?.prices?.mrp : `₹${item?.prices?.mrp}`)
                                                        : "No price available"}
                                            </p>

                                            <span>
                                                {(item?.prices?.discounted_price !== null && item?.prices?.discounted_price !== 0) &&
                                                    (String(item?.prices?.mrp).startsWith('₹') ? item?.prices.mrp : `₹${item?.prices?.mrp}`)}
                                            </span>
                                        </div>
                                        <div className="price-star">
                                            {item?.average_rating !== '' && (
                                                <span><FaStar />{item?.average_rating}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="offer-recent-text-box">
                                        {item?.prices?.discount && (
                                            <p>{item?.prices?.discount}</p>
                                        )}
                                    </div>
                                    <div className="line-repo"></div>
                                    <div className="qty-repo-box">
                                        <p>Min. Qty : {item?.min_quantity}</p>
                                    </div>
                                    <div className="add-product-box">
                                        {(loadingItems.has(item.sku_id) && cartStatus === 'loading') || (loadingItems.has(item.sku_id) && DeleteStatus === "loading" && ListStatus === "loading") ? (
                                            <CartLoader />
                                        ) : (
                                            <div className="add-product-box">
                                                {(loadingItems.has(item.sku_id) && cartStatus === 'loading') || (loadingItems.has(item.sku_id) && DeleteStatus === "loading") ? (
                                                    <CartLoader />
                                                ) : (
                                                    <div className="add-cart-repo-boxs">
                                                        {availability[item.sku_id] === 0 || item?.is_available === 0 || (item?.min_quantity === 0 && item?.max_quantity === 0) ? (
                                                            <div className="out-of-stock">
                                                                <p>Out of Stock</p>
                                                            </div>
                                                        ) : (cartItem || (item?.in_cart === 1 && item?.is_available === 1 && item?.quantity !== 0)) ? (
                                                            <div className="increase-cart-repo-boxs">
                                                                <button
                                                                    className={`incre-btn-reop ${cartItem && cartItem.quantity <= item.min_quantity ? 'border-red' : ''}`}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (cartItem && cartItem.quantity <= item.min_quantity) {
                                                                            handleDelete(item.sku_id);
                                                                        } else {
                                                                            handleDecrement(item.sku_id);
                                                                        }
                                                                    }}
                                                                >
                                                                    <p>-</p>
                                                                </button>


                                                                {CartData?.data?.some(count => count.sku_id === item.sku_id) ? (
                                                                    CartData.data.filter(count => count.sku_id === item.sku_id).map((count, idx) => (
                                                                        <span key={idx}>
                                                                            {count.quantity}
                                                                        </span>
                                                                    ))
                                                                ) : (
                                                                    <span>
                                                                        {item?.quantity}
                                                                    </span>
                                                                )}
                                                                <button
                                                                    className='incre-btn-reop'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (cartItem && cartItem !== undefined && cartItem.quantity < cartItem.max_quantity || item?.quantity < item?.max_quantity) {
                                                                            handleIncrement(item.sku_id);
                                                                        } else {
                                                                            toast.error("Max Reached");
                                                                        }
                                                                    }}
                                                                >
                                                                    <p>+</p>
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleCartAdd(item);
                                                                }}
                                                            >
                                                                <p>ADD</p>
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            <ToastBox />
        </>

    )
}


export default Medicine_Recent