import React, { useContext, useEffect, useState } from 'react'
import { ArrowBack, appBannerImg, appPlayButton, appVideoImage } from '../../images'
import { NavLink, useNavigate } from 'react-router-dom'
import useLocationState from '../Medicine/hooks/useLocationState'

const VideoBox = () => {
    const [videobox, setVideoBox] = useState([]);
    const navigate = useNavigate();
    const VideoData = useLocationState();

    useEffect(() => {
        setVideoBox(VideoData)
    }, [VideoData]);

    const handleSingleVideo = (item) => {
        navigate('/videodetailbox', { state: item })
    }



    return (
        <>
            <div className="home-review-banner">
                <div className="app-header-wraper video-box-repo">
                    <div className="header-top-section">
                        <div className="header-top-section-category-section">
                            <h3>Health Talk</h3>
                        </div>
                        <div className="header-top-section-add-to-cart-section">
                        </div>
                    </div>
                </div>

                <div className="video-box-wrapper">
                    {videobox.videoData && videobox.videoData.map((videoData, ind) => (
                        <div className="video-box-section" onClick={()=>handleSingleVideo(videoData)}>
                                <div className="video-box-image">
                                    <div className="video-image-repo" style={{ backgroundImage: `url(${videoData.icon})` }}>
                                        {/* <img src={videoData.icon} alt="" /> */}
                                    </div>
                                    <div className="video-btns-repo">
                                        <img src={appPlayButton} alt="" />
                                        <p>{videoData.video_time}</p>
                                    </div>

                                </div>
                                <div className="video-header">
                                    <p>{videoData.title}</p>
                                </div>
                                {/* <hr /> */}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default VideoBox