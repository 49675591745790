// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import SuccessFully from "../images/success.json";


const SuccessPack = () => {
    
    return (
        <Lottie animationData={SuccessFully} className='no-doctor-found' />
    )
}

export default SuccessPack;
