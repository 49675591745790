import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { medicineCartAdd, medicineCartDelete } from '../../../Redux/actions/PostApiCall';
import { fetchMedicineCartList, fetchMedicinelist, fetchMedicineRecent, fetchSingleMedicinelist } from '../../../Redux/actions/GetApiCall';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import ToastBox from '../animation/ToastBox';

const useCartFunction = (inputPara, setLoaderStatus, setCartListData, walletId, selectedPackage) => {
    const [cart, setCart] = useState([]);
    const pincode = localStorage.getItem("pincode");
    const [loadingItems, setLoadingItems] = useState(new Set());
    const dispatch = useDispatch();
    const [deletedItems, setDeletedItems] = useState(new Set());
    const { data: mediData } = useSelector(state => state.medicine_recent_list);
    const cartStatus = useSelector(state => state.medicine_cart_add.status);
    const cartStatusBook = useSelector(state => state.medicine_cart_add);
    const DeleteStatus = useSelector(state => state.medicine_cart_delete.status);
    const Similar = useSelector(state => state.medicine_cart_delete.similar);
    const CartData = useSelector(state => state.medicine_cart_add.data);
    const [availability, setAvailability] = useState({});
    const [loaderTap, setLoaderTap] = useState(false)
    const { data: MedicineData } = useSelector(state => state.medicine_cart_list);
    const { data: SingleMedicineData } = useSelector(state => state.single_medicine_list);
    const { data: MedicineListRepo } = useSelector(state => state.medicine_list);
    const location = useLocation();
    const data = location.state || {};
    const [errorDisplayed, setErrorDisplayed] = useState(false);

    const sku_id = SingleMedicineData?.data?.sku_id;
    const type = "otc";


    useEffect(() => {
        if (cartStatusBook?.error !== null && cartStatusBook?.status === "failure") {
            toast.error(cartStatusBook?.error);
            setErrorDisplayed(true);
        }
    }, [cartStatusBook, errorDisplayed])

    useEffect(() => {
        if (MedicineData && setCartListData !== undefined) {
            setCartListData(MedicineData);
        }
    }, [MedicineData])

    useEffect(() => {
        if (CartData && setCartListData !== undefined) {
            setCartListData(CartData);
        }
    }, [CartData]);


    useEffect(() => {
        if (cartStatus === 'success' && CartData) {
            const updatedCart = CartData.data.reduce((acc, cartItem) => {
                const itemAvailability = cartItem.is_available;
                setAvailability(prev => ({ ...prev, [cartItem.sku_id]: itemAvailability }));

                if (itemAvailability) {
                    const itemIndex = acc.findIndex(item => item.sku_id === cartItem.sku_id);
                    if (itemIndex !== -1) {
                        acc[itemIndex].quantity = cartItem.quantity || acc[itemIndex].quantity;
                    } else {
                        acc.push({ ...cartItem, quantity: cartItem.min_quantity });
                    }
                } else {
                    acc = acc.filter(item => item.sku_id !== cartItem.sku_id);
                }

                return acc;
            }, [...cart]);

            setCart(updatedCart);

            // Clear loader for all added items
            if (cartStatus !== 'loading') {
                setLoadingItems(prev => {
                    const newSet = new Set(prev);
                    CartData.data.forEach(item => newSet.delete(item.sku_id));
                    return newSet;
                });
            }
        }
    }, [cartStatus, CartData]);


    const updateCartInServer = async (updatedProducts) => {
        const cartDataToSend = updatedProducts.map(item => ({
            sku_id: item.sku_id,
            quantity: item.quantity,
            min_quantity: item.min_quantity,
            max_quantity: item.max_quantity
        }));

        const cartAddData = {
            pincode: pincode,
            cart: cartDataToSend,
        };

        await dispatch(medicineCartAdd(cartAddData));
    };


    const MedicineCartList = MedicineData?.available_stock;
    const MedicineDatas = mediData?.data;
    const SingleMedicineDatas = SingleMedicineData?.data;
    const SimilarMedicineDatas = MedicineListRepo?.data;

    useEffect(() => {
        const pathname = window.location.href;

        let itemsToProcess = [];

        if (pathname.includes('cart')) {
            itemsToProcess = Array.isArray(MedicineCartList) ? MedicineCartList : [];
        }
        else if (pathname.includes('place-order')) {
            itemsToProcess = Array.isArray(MedicineCartList) ? MedicineCartList : [];
        }

        else if (pathname.includes('medicines' || "medicine-description")) {
            itemsToProcess = Array.isArray(MedicineDatas) ? MedicineDatas : [];
        } else {
            itemsToProcess = Array.isArray(SimilarMedicineDatas) ? SimilarMedicineDatas :
                SingleMedicineDatas ? [SingleMedicineDatas] : [];
        }

        const newCartItems = [];

        itemsToProcess.forEach(item => {
            if (item?.is_available === 1 && item?.in_cart === 1 && !deletedItems.has(item.sku_id)) {
                const updateCart = {
                    sku_id: item.sku_id,
                    quantity: item.quantity,
                    min_quantity: item.min_quantity,
                    max_quantity: item.max_quantity,
                };

                const isItemInCart = cart.some(cartItem => cartItem.sku_id === item.sku_id);
                if (!isItemInCart) {
                    newCartItems.push(updateCart);
                }
            }
        });

        if (newCartItems.length > 0) {
            setCart(prevCart => {
                return [...prevCart, ...newCartItems];
            });
        }
    }, [MedicineDatas, cart, deletedItems, MedicineCartList, SingleMedicineDatas, SimilarMedicineDatas]);


    useEffect(() => {
        if (DeleteStatus === "success") {
            // Filter out deleted items from cart
            setCart(prevCart => prevCart.filter(item => !loadingItems.has(item.sku_id)));

            // Add the deleted item to the deletedItems set
            setDeletedItems(prev => new Set(prev).add([...loadingItems][0]));

            // Remove the loader for the deleted item
            setLoadingItems(prev => {
                const newSet = new Set(prev);
                [...loadingItems].forEach(id => newSet.delete(id));
                return newSet;
            });

            const currentUrl = window.location.href;
            if (currentUrl.includes("cart")) {
                dispatch(fetchMedicineCartList());
            } else if (currentUrl.includes("place-order")) {
                dispatch(fetchMedicineCartList());
            } else if (currentUrl.includes("medicine-description")) {
                dispatch(fetchSingleMedicinelist(data?.data?.sku_id, data?.data?.type));
                dispatch(fetchMedicinelist(data?.data?.name));
            } else if (currentUrl.includes("medicines")) {
                dispatch(fetchMedicineRecent());
                dispatch(fetchMedicineCartList());
            } else {
                dispatch(fetchMedicinelist(data?.data?.name || inputPara));
            }
        }
    }, [DeleteStatus]);

    const handleCartAdd = async (item) => {
        setLoadingItems(prev => new Set(prev).add(item.sku_id)); // Add the item to loading state

        const updatedCart = cart.map(cartItem =>
            cartItem.sku_id === item.sku_id
                ? {
                    ...cartItem,
                    quantity: Math.min(cartItem.quantity + item.min_quantity, item.max_quantity),
                }
                : cartItem
        );

        if (!updatedCart.some(cartItem => cartItem.sku_id === item.sku_id)) {
            updatedCart.push({
                sku_id: item.sku_id,
                quantity: item.min_quantity,
                min_quantity: item.min_quantity,
                max_quantity: item.max_quantity,
            });
        }

        await updateCartInServer(updatedCart);
        await dispatch(fetchMedicineCartList());

        setLoadingItems(prev => {
            const newSet = new Set(prev);
            newSet.delete(item.sku_id); // Remove the item from loading state once done
            return newSet;
        });
    };

    const enqueueIncrement = async (sku_id) => {
        setLoadingItems(prev => new Set(prev).add(sku_id));

        // Find the specific product and increment its quantity
        const productToUpdate = cart.find(cartItem => cartItem.sku_id === sku_id);

        if (productToUpdate && productToUpdate.quantity < productToUpdate.max_quantity) {
            const updatedProduct = {
                ...productToUpdate,
                quantity: productToUpdate.quantity + 1
            };

            // Update only the specific product on the server
            await updateCartInServer([updatedProduct]);
        }

        // Fetch updated cart and reset loading state
        await dispatch(fetchMedicineCartList(selectedPackage, walletId));

        setLoadingItems(prev => {
            const newSet = new Set(prev);
            newSet.delete(sku_id);
            return newSet;
        });
    };


    const enqueueDecrement = async (sku_id) => {
        setLoadingItems(prev => new Set(prev).add(sku_id));

        // Find the specific product and decrement its quantity
        const productToUpdate = cart.find(cartItem => cartItem.sku_id === sku_id);

        if (productToUpdate && productToUpdate.quantity > productToUpdate.min_quantity) {
            const updatedProduct = {
                ...productToUpdate,
                quantity: productToUpdate.quantity - 1
            };

            // Update only the specific product on the server
            await updateCartInServer([updatedProduct]);
        }

        // Fetch updated cart and reset loading state
        await dispatch(fetchMedicineCartList(selectedPackage, walletId));

        setLoadingItems(prev => {
            const newSet = new Set(prev);
            newSet.delete(sku_id);
            return newSet;
        });
    };


    // Sequential execution logic
    let incrementQueue = Promise.resolve();
    let decrementQueue = Promise.resolve();

    // Handle Increment with Sequential Logic
    const handleIncrement = (sku_id) => {
        incrementQueue = incrementQueue
            .then(() => enqueueIncrement(sku_id)) // Wait for previous increment to finish
            .catch(error => {
                console.error("Error in increment:", error);
                // Handle error here if needed
            });
    };

    // Handle Decrement with Sequential Logic
    const handleDecrement = (sku_id) => {
        decrementQueue = decrementQueue
            .then(() => enqueueDecrement(sku_id)) // Wait for previous decrement to finish
            .catch(error => {
                console.error("Error in decrement:", error);
                // Handle error here if needed
            });
    };




    const handleDelete = async (id) => {
        setLoadingItems(prev => new Set(prev).add(id)); // Add to loading state

        const CartId = { sku_id: id };
        await dispatch(medicineCartDelete(CartId));

        setCart(prevCart => prevCart.filter(item => item.sku_id !== id));
        await dispatch(fetchMedicineCartList(selectedPackage, walletId));

        setLoadingItems(prev => {
            const newSet = new Set(prev);
            newSet.delete(id); // Remove from loading state once done
            return newSet;
        });
    };

    return {
        cart,
        loadingItems,
        handleCartAdd,
        handleIncrement,
        handleDecrement,
        handleDelete,
        loaderTap,
        cartStatus,
        DeleteStatus,
        availability,
        CartData,
        Similar
    };
};

export default useCartFunction;
