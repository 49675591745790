// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import SuccessFully from "../images/success.json";
import ErrorJson from "../AnimationJson/error-message.json";
import LoaderAnime from "../Pages/Medicine/animation/loader_anime";

import Modal from 'react-bootstrap/Modal';

const SuccessFull = (props) => {
    const { show, successMessage, animationtype, status } = props;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                {status === "loading" ? (
                    <LoaderAnime />
                ) : (
                    <>
                        <Lottie animationData={status === "failure" && status!==undefined ? ErrorJson : SuccessFully} className='success-data-animation' />
                        {successMessage && <p className='success-msg'>{successMessage}</p>}
                    </>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default SuccessFull;
