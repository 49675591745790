import React, { useState } from 'react';
import { appOPDIcon, headerLogo } from '../../images';
import useLocationState from '../Medicine/hooks/useLocationState';
import { IoLocationSharp } from 'react-icons/io5';
import { MdSchool } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";
import { MdPersonAddAlt1 } from "react-icons/md";
import { FaBuilding, FaCalendarPlus, FaHospital } from "react-icons/fa";
import { IoIosVideocam } from "react-icons/io";
import { Tab, Tabs } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from 'react-router';

const DoctorDetails = () => {
    const Data = useLocationState();
    const navigate = useNavigate();

    const [onlineSelectedTime, setOnlineSelectedTime] = useState(null);
    const [inPersonSelectedTime, setInPersonSelectedTime] = useState(null);
    const [consultationType, setConsultationType] = useState("");

    const handleViewAll = () => {
        navigate("/doctor-slots", { state: Data });
    };

    const handleOnlineTimeSlotClick = (time) => {
        setOnlineSelectedTime(time);
        setInPersonSelectedTime(null)
        setConsultationType("Online Consultation");
    };

    const handleInPersonTimeSlotClick = (time) => {
        setInPersonSelectedTime(time);
        setOnlineSelectedTime(null)
        setConsultationType("In-Person Consultation");
    };

    const handleDoctorOrder = () => {
        navigate("/doctor-order", { state: Data })
    }

    const TimeSlot = [
        { "time": "09:45 AM" },
        { "time": "10:00 AM" },
        { "time": "10:15 AM" },
        { "time": "10:30 AM" },
        { "time": "10:45 AM" },
        { "time": "11:00 AM" },
        { "time": "11:15 AM" },
        { "time": "11:30 AM" },
        { "time": "11:45 AM" },
        { "time": "12:00 PM" },
        { "time": "12:15 PM" },
        { "time": "12:30 PM" },
        { "time": "12:45 PM" },
        { "time": "01:00 PM" },
        { "time": "01:15 PM" },
        { "time": "01:30 PM" },
        { "time": "01:45 PM" },
        { "time": "02:00 PM" },
        { "time": "02:15 PM" },
        { "time": "02:30 PM" }
    ];

    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Doctor Details</p>
                    </div>
                </div>
            </div>
            <div className="doctor-data-repo">
                <div className="doctor-tepo-image">
                    <img src={appOPDIcon} alt="" />
                    <div className="dot"></div>
                </div>
                <div className="doctor-data-box-tepo">
                    <h5 style={{ marginBottom: '0px' }}>{Data?.name}</h5>
                    <p style={{ fontSize: "11px", color: "#757575", marginBottom: '5px' }}>{Data?.category}</p>
                    <p className="doctor-category"><MdSchool /> {Data?.education}</p>
                    <p className="doctor-category"><FaBuilding /> {Data?.hospital_name}, {Data?.city}</p>
                </div>
            </div>

            <div className="consultation-wrapper-clover">
                <div className="online-consultation-wrapper-clover mb-3" onClick={() => setConsultationType("Online Consultation")}>
                    <div className="online-text-repo">
                        <div className="icons-box-online">
                            <IoIosVideocam />
                            Online Consultation
                        </div>
                        <div className="onlinr-price">
                            <p>₹ 900</p>
                        </div>
                    </div>
                    <div className="tabs-days-repo-clover">
                        <Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example" className="mb-1 repo-clover-tabs-repo" style={{ justifyContent: 'center' }}>
                            <Tab eventKey="tab1" title="Today" className='tab-box-repo-clover'>
                                <div className='time-slot-clover-repo'>
                                    <Swiper spaceBetween={8} slidesPerView={3.5}>
                                        {TimeSlot.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <div
                                                    className={`slot-repo-clover ${onlineSelectedTime === item.time ? 'active-slot' : ''}`}
                                                    onClick={() => handleOnlineTimeSlotClick(item.time)}
                                                >
                                                    <p>{item.time}</p>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                                <div className="view-slots-repo-lover">
                                    <button onClick={handleViewAll}>View All Slots <FaAngleRight /></button>
                                </div>
                            </Tab>
                            <Tab eventKey="tab2" title="Tomorrow">
                                <div className='time-slot-clover-repo'>
                                    <Swiper spaceBetween={8} slidesPerView={3.5}>
                                        {TimeSlot.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <div
                                                    className={`slot-repo-clover ${onlineSelectedTime === item.time ? 'active-slot' : ''}`}
                                                    onClick={() => handleOnlineTimeSlotClick(item.time)}
                                                >
                                                    <p>{item.time}</p>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                                <div className="view-slots-repo-lover">
                                    <button onClick={handleViewAll}>View All Slots <FaAngleRight /></button>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>

                <div className="online-consultation-wrapper-clover" onClick={() => setConsultationType("In-Person Consultation")}>
                    <div className="online-text-repo inperson-repo">
                        <div className="icons-box-online inpersone-repo">
                            <FaHospital />
                            In-Person Consultation
                        </div>
                        <div className="onlinr-price inpersone-pri">
                            <p>₹ 900</p>
                        </div>
                    </div>
                    <div className="tabs-days-repo-clover">
                        <Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example" className="mb-1 repo-clover-tabs-repo" style={{ justifyContent: 'center' }}>
                            <Tab eventKey="tab1" title="Today" className='tab-box-repo-clover'>
                                <div className='time-slot-clover-repo'>
                                    <Swiper spaceBetween={8} slidesPerView={3.5}>
                                        {TimeSlot.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <div
                                                    className={`slot-repo-clover ${inPersonSelectedTime === item.time ? 'active-slot' : ''}`}
                                                    onClick={() => handleInPersonTimeSlotClick(item.time)}
                                                >
                                                    <p>{item.time}</p>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                                <div className="view-slots-repo-lover">
                                    <button onClick={handleViewAll}>View All Slots <FaAngleRight /></button>
                                </div>
                            </Tab>
                            <Tab eventKey="tab2" title="Tomorrow">
                                <div className='time-slot-clover-repo'>
                                    <Swiper spaceBetween={8} slidesPerView={3.5}>
                                        {TimeSlot.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <div
                                                    className={`slot-repo-clover ${inPersonSelectedTime === item.time ? 'active-slot' : ''}`}
                                                    onClick={() => handleInPersonTimeSlotClick(item.time)}
                                                >
                                                    <p>{item.time}</p>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                                <div className="view-slots-repo-lover">
                                    <button onClick={handleViewAll}>View All Slots <FaAngleRight /></button>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="About-doctor-repo">
                    <h6 style={{ marginTop: '20px', fontSize: '17px', marginBottom: '10px' }}>About the doctor</h6>
                    <div className="about-user-data">

                        <div className="experience-repo-text mb-2">
                            <div className="user-icon">
                                <FaCalendarPlus />
                            </div>
                            <div className="expo-text-tepo">
                                <p>10 years of experience</p>
                                <span>Specializing in comprehensive healthcare and preventive care</span>
                            </div>
                        </div>
                        <div className="experience-repo-text mb-2">
                            <div className="user-icon">
                                <AiOutlineMessage />
                            </div>
                            <div className="expo-text-tepo">
                                <p>85% Recommend</p>
                                <span>358 patients would Recommend this doctor to their friends and family.</span>
                            </div>
                        </div>
                        <div className="experience-repo-text">
                            <div className="user-icon">
                                <MdPersonAddAlt1 />
                            </div>
                            <div className="expo-text-tepo">
                                <p>Online Consultation</p>
                                <span>The consultation is possible on site and online</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bio-doctor-repo">
                    <h6 style={{ marginTop: '20px', fontSize: '17px', marginBottom: '10px' }}>Short Bio</h6>
                    <div className="desc-text-repo">
                        <p>{Data?.description}</p>
                    </div>
                </div>
            </div>
            {consultationType !== "" && (
                <div className="doctor-buttons-repo-detail sticky-bottom repo-buttons-tepo">
                    <button className="online-btn" onClick={handleDoctorOrder}>
                        {consultationType}
                    </button>
                </div>
            )}
        </div>
    );
};

export default DoctorDetails;
