import React from 'react'
import './mediLoader.css'
import { medi_loader } from '../Images'

const MediLoader = ({ height }) => {
    return (
        <div className="mediloader-data" style={{ height: height }}>
            {/* <img src={medi_loader} alt="" /> */}
            {/* <div class="loading loading03">
                <span data-text="M">M</span>
                <span data-text="E">E</span>
                <span data-text="D">D</span>
                <span data-text="I">I</span>
                <span data-text="B">B</span>
                <span data-text="H">H</span>
                <span data-text="A">A</span>
                <span data-text="I">I</span>
            </div> */}

            <div className='input-load'>
                <div class="dot-spinner">
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                </div>
                <p>Please Wait...</p>
            </div>
        </div>
    )
}

export default MediLoader