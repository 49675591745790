import React, { useEffect, useState } from "react";
import Loader from "../../Animation/Loader";
import HealthPackageCard from "./components/HealthPackageCard";
import NoDataFoundComp from "../../components/NoDataFound";
import { IoIosSearch } from "react-icons/io";
import AppHeader from "../Medicine/Components/Medicine_Header";
import AppFooter from "../Medicine/Components/Medicine_footer";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDiagnosticCartDetails,
  getDiagnosticPackageList,
} from "../../Redux/actions/GetApiCall";
import LoaderAnime from "../Medicine/animation/prescription_loader";
import { useNavigate } from "react-router";
import { handleNavigateSearch } from "../../utils/AllFunction";

const TestbyCategories = () => {
  const cartDetailsResponse = useSelector(
    (state) => state.diagnostic_cart_details
  );
  const packageData = useSelector(
    (state) => state.diagnostic_package_list_response
  );
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (packageData?.status === "loading") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [packageData]);

  useEffect(() => {
    dispatch(fetchDiagnosticCartDetails());
    dispatch(getDiagnosticPackageList());
  }, [dispatch]);

  return (
    <>
      <div className="home-review-banner">
        <div className="lab-book-categories">
          <AppHeader Title="Select Packages" labCartCount={0} />
          <div className="app-searh-section">
            <div className="inputWithIcon">
              <input type="text" placeholder="Search" onClick={() => handleNavigateSearch(navigate)} />
              <button>
                <IoIosSearch />
              </button>
            </div>
          </div>
          <div className="health-packages-content-wrapper-box mb-5 mt-2">
            <div className="container">
              {packageData?.status === "loading" && (
                <LoaderAnime showModal={showModal} />
              )}
              <div className="row">
                <div className="col-lg-12">
                  {packageData?.data?.data.length > 0 ? (
                    packageData?.data?.data.map((pack_data) => {
                      return <HealthPackageCard data={pack_data} />;
                    })
                  ) : (
                    <NoDataFoundComp />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {cartDetailsResponse &&
          (cartDetailsResponse?.data?.response?.tests?.length > 0 ||
            cartDetailsResponse?.data?.response?.packages?.length > 0) && (
            <AppFooter
              labCartData={cartDetailsResponse?.data.response}
              tabkey="diagnostics"
            />
          )}
      </div>
    </>
  );
};

export default TestbyCategories;
