import React, { useEffect, useState } from "react";
import SuccessFull from "../../Animation/SuccessFull";
import { useNavigate } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import { MY_ACCOUNT_API, REQUESTOPTIONS, WELLNESS_REQUEST_API } from "../../Constants";
import Loader from "../../Animation/Loader";
import ConfirmModal from "../MyProfile/Components/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import useLocationState from "../Medicine/hooks/useLocationState";
import { validateNumberInput } from "../../utils/AllFunction";
import { fetchWellnesForm } from "../../Redux/actions/PostApiCall";

const FormWellnessPages = ({ ImgData, bgColor, btnColor, borderColor, data }) => {
  const [successmodal, setSuccessModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [formShow, setFormShow] = useState(false);
  const [formData, setFormData] = useState(null); // State to hold the form data

  const navigate = useNavigate();
  const UserData = useLocationState();
  const dispatch = useDispatch();

  const WellnessResponse = useSelector(state => state.wellness_form);


  const handleShow = () => setFormShow(true);
  const handleClose = () => setFormShow(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      name: UserData && UserData?.name || '',
      mobile_number: UserData && UserData?.mobile_number || '',
      email_id: UserData && UserData?.email_id || ''
    }
  });

  const onSubmit = async (formData) => {
    const dataRepo = {
      type: data,
      ...formData
    }
    await dispatch(fetchWellnesForm(dataRepo));
  };

  useEffect(() => {
    if (WellnessResponse?.data?.success === true) {
      setModalShow(true);
      setSuccessModal(true);
      setTimeout(() => {
        setModalShow(false);
        navigate("/home");
        window.location.reload();
      }, 2500);
    }
  }, [WellnessResponse])

  const handleFormSubmit = (data) => {
    setFormData(data); // Save the form data
    handleShow(); // Show confirmation modal
  };

  return (
    <div className="wellness-wrapper-section">
      <div className="wellness-cover-box">
        <div className="wellness-image-box">
          <img src={ImgData} width="100%" alt="review" />
        </div>

        <form onSubmit={handleSubmit(handleFormSubmit)}> {/* Use handleFormSubmit */}
          <div className="wellness-form-box" style={{ background: bgColor, border: borderColor }}>
            {/* Form Inputs */}
            <div className="form-repo-well">
              <label>Enter Your Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                {...register("name", { required: true })}
              />
              {errors.name && <span>This field is required</span>}
            </div>
            <div className="form-repo-well">
              <label>Mobile Number</label>
              <input
                type="text"
                name="mobile_number"
                placeholder="Enter your number"
                {...register("mobile_number", { required: true, maxLength: 10 })}
                maxLength={10}
                onChange={validateNumberInput}
              />
              {errors.mobile_number && <span>Enter a valid 10-digit number</span>}
            </div>
            <div className="form-repo-well">
              <label>Email Address</label>
              <input
                type="text"
                name="email_id"
                placeholder="Enter your email"
                {...register("email_id", { required: true })}
              />
              {errors.email_id && <span>This field is required</span>}
            </div>
            <div className="form-repo-well">
              <label>Enter Your Message</label>
              <textarea
                name="message"
                cols={30}
                rows={3}
                placeholder="Enter Your Message"
                {...register("message", { required: true })}
              />
              {errors.message && <span>This field is required</span>}
            </div>
          </div>
          <div className="wellness-form-submit fitness-submit">
            <button type="submit" style={{ background: btnColor }}>
              Submit
            </button>
          </div>
        </form>

        <ConfirmModal
          handleClose={handleClose}
          formShow={formShow}
          onSubmit={() => onSubmit(formData)} // Pass formData to onSubmit
        />
      </div>

      {successmodal && (
        <SuccessFull show={modalShow} successMessage={WellnessResponse?.data?.message} animationtype={successmodal} />
      )}

      <Toaster position="top-center" />
    </div>
  );
};

export default FormWellnessPages;
