import React from 'react'
import { headerLogo, teleBanner1, teleBanner2 } from '../../images'
import { FiSearch } from 'react-icons/fi';
import '../Doctor/doctorhome.css'
import { ban1, ban2, hospitalIcon } from '../Medicine/Images';
import { DoctorData, HospitalCategories, HospitalData, HospitalFaq } from '../../data';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router';
import { Accordion } from 'react-bootstrap';
import DoctorCard from '../Doctor/DoctorCard';
import RecentHospitalCard from './RecentHospitalCard';

const HospitalHome = () => {
    const navigate = useNavigate();
    const handleDoctor = () => {
        navigate("/hospitals", { state: HospitalData })
    }

    const handleHospitalDetails = (item) => {
        navigate('/hospital-details', { state: item })
    }

    const handleDoctorDetails = () => {
        navigate('/doctor')
    }
    const handleDoctorDepartment = () => {
        navigate('/doctor-department')
    }

    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Hospitals</p>
                    </div>
                </div>
            </div>

            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search hospitals..."
                    onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;  // Regular expression to allow letters and spaces only
                        if (!regex.test(e.key)) {
                            e.preventDefault(); // Prevent non-letter characters
                        }
                    }}
                />
                <FiSearch />

            </div>


            <div className="hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>Categories</p>
                    {/* <button>View All</button> */}
                </div>
                <div className="hospital-categories-wrapper">
                    <Swiper
                        spaceBetween={15}
                        slidesPerView={3.2}
                        loop={true}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 3000, // Adjust the delay for autoplay
                            disableOnInteraction: false, // Allows autoplay to continue after user interactions
                        }}
                        speed={600}
                        className='pb-2'
                    >
                        {HospitalCategories.map((item, index) => (
                            <SwiperSlide key={index}

                            >
                                <div
                                    className="category-data-cover"
                                    style={{ backgroundColor: item.bgColor }}
                                >
                                    <div className="icons-repo-cover-de"
                                        style={{ backgroundColor: item.boxColor }}>
                                        {item?.icon}
                                    </div>
                                    <p style={{ color: item.boxColor }}>
                                        {item.name.split(' ').map((word, index) => (
                                            <span key={index}>
                                                {word}
                                                {index < item.name.split(' ').length - 1 && <br />} {/* Add line break except after the last word */}
                                            </span>
                                        ))}
                                    </p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            </div>


            <div className="hospital-wrapper-box">
                <div className="hospital-text-head">
                    <p>Hospitals</p>
                    <button onClick={handleDoctor}>View All</button>
                </div>
                <div className="hospital-cover-repo-box">
                    {HospitalData.slice(0, 4)?.map((item, index) => (
                        <div className="hospital-card-repo" key={index} onClick={() => handleHospitalDetails(item)}>
                            {/* <div className="hospital-card-image-repo"> */}
                            <img src={hospitalIcon} alt="hospital-image" />
                            {/* <div className="dot"></div> */}
                            {/* </div> */}
                            <div className="hospital-data-wrap">
                                <p>Name: <span>{item?.hospital_name}</span></p>
                                <p style={{ color: 'GrayText' }}>City: <span>{item?.city}</span></p>
                                <p style={{ color: 'GrayText' }}>Speciality: <span>Multi-speciality</span></p>
                                <p style={{ color: 'GrayText' }}>
                                    Doctors: <span>12</span>
                                </p>
                            </div>
                            {/* <div className="ratings-hospital">
                                <p><FaStar /> <span>{item?.ratings}</span></p>
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>

            <div className="Hospital-banner-repo-clover hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>Banners</p>
                    {/* <button>View All</button> */}
                </div>

                <div className="hospital-categories-wrapper">
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={1}
                        loop={true}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 3000, // Adjust the delay for autoplay
                            disableOnInteraction: false, // Allows autoplay to continue after user interactions
                        }}
                        speed={600}
                        className='pb-2'
                    >
                        <SwiperSlide>
                            <div className="banner-image-repo-clover">
                                <img src={ban1} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="banner-image-repo-clover">
                                <img src={ban2} alt="" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>Recent Hospital</p>
                    {/* <button>View All</button> */}
                </div>
                <div className="hospital-categories-wrapper">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.3}
                        loop={true}
                        modules={[Autoplay]}
                        // autoplay={{
                        //     delay: 2500,
                        //     disableOnInteraction: false,
                        // }}
                        speed={600}
                        className='pb-2'
                    >
                        {HospitalData.map((item, index) => (
                            <SwiperSlide key={index}
                            >
                                <RecentHospitalCard item={item} index={index} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            </div>


            <div className="hospital-faq-department-data hospital-wrapper-box">
                <div className="hospital-text-head" style={{ marginBottom: '0px' }}>
                    <p>FAQ</p>
                    {/* <button>View All</button> */}
                </div>

                <div className="need-help-accordion-main-cover-wrapper">
                    <Accordion defaultActiveKey="0" className='faq-box-clover'>
                        {HospitalFaq?.map((helpData, ind) => {
                            return (
                                <Accordion.Item eventKey={ind} key={ind}>
                                    <Accordion.Header>
                                        {helpData?.question}
                                    </Accordion.Header>
                                    <Accordion.Body>{helpData?.answer}</Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default HospitalHome