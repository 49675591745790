import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoMdArrowDropdown, IoIosSearch } from "react-icons/io";
import { useDispatch } from "react-redux";
import { FaAngleRight } from "react-icons/fa6";
import { fetchDiagnosticTestList, fetchRadioCartDetails, fetchRadiologyTestList } from "../../Redux/actions/GetApiCall";
import { debounce } from "lodash";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import MediLoader from "../Medicine/animation/loader_anime";
import Medicine_Header from "../Medicine/Components/Medicine_Header";
import { appLabCart } from "../../images";
import { fetchDiagnosticCartDetails } from "../../Redux/actions/GetApiCall";
import { no_diagonstic_found, no_tele_found } from "../Medicine/Images";

const SearchRadioTest = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const cartDetailsResponse = useSelector(
    (state) => state.radio_cart_details
  );

  const dispatch = useDispatch();
  const {
    data: SearchData,
    error: SearchError,
    loader: SearchLoader,
  } = useSelector((state) => state?.search_diagnostic_test);

  const debounceFetchdiagnostic = useCallback(
    debounce((value) => {
      if (value.trim() !== "") {
        dispatch(fetchRadiologyTestList(value));
      }
    }, 1000), // 1s debounce delay
    [dispatch]
  );

  useEffect(() => {
    debounceFetchdiagnostic(searchQuery);
    return () => {
      debounceFetchdiagnostic.cancel();
    };
  }, [searchQuery, debounceFetchdiagnostic]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <strong key={index} style={{ color: "black" }}>
          {part}
        </strong>
      ) : (
        part
      )
    );
  };

  const handleNavigateDescription = (type, id) => {
    let path;

    switch (type) {
      case "test":
        path = `/test-description/${id}`;
        break;
      case "category":
        path = `/test-by-categories/${id}`;
        break;
      default:
        path = `/package-description/${id}`; // Change this to whatever default path you want
        break;
    }

    navigate(path);
  };

  useEffect(() => {
    dispatch(fetchRadioCartDetails());
  }, [dispatch]);

  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <div className="home-review-banner">
        <Medicine_Header
          Title="Search Lab Test"
          labCartCount={cartDetailsResponse?.data?.response?.cart_count}
          prescrip="diagnostics"
        />
        <div className="app-searh-section">
          <div className="inputWithIcon">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              //   onFocus={handleSearchInputClick}
              onChange={(e) => handleInputChange(e)}
              //   onBlur={handleSearchInputBlur}
              ref={inputRef}
            />
            <button disabled>
              <IoIosSearch />
            </button>
          </div>
        </div>
        <div className="search-test-main-wrapper mx-auto">
          <div className="suggestion-main-cover-wapper">
            <div className="suggestion-list-cover">
              {SearchLoader ? (
                <MediLoader />
              ) : SearchData?.data?.length > 0 ? (
                SearchData?.data?.map((item) => (
                  <div
                    key={item.id}
                    className="search-result-data-box-cover"
                    onClick={() =>
                      handleNavigateDescription(item.type, item.id)
                    }
                  >
                    <div className="search-data-box-cont">
                      <p>{getHighlightedText(item?.name, searchQuery)}</p>
                    </div>
                    <div className="search-left-arrow-box">
                      <FaAngleRight />
                    </div>
                  </div>
                ))
              ) : searchQuery?.length > 4 ? (
                <div className="repo-div-cover">
                  <div className="div-data-lab-image lab-bot">
                    <img src={no_diagonstic_found} />
                  </div>
                </div>
              ) : (
                <div className="repo-div-cover">
                  <div className="div-data-lab-image lab-bot">
                    <img src={no_diagonstic_found} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchRadioTest;
