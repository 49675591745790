import React, { useEffect, useRef, useState } from 'react'
import Medicine_Header from '../Components/Medicine_Header'
import useLocationState from '../hooks/useLocationState'
import '../Medicine_Style/prescription.css'
import { camera_icon, folder_icon, no_preview, pdf_icon, prescription_image, valid_prescription_image } from '../Images'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPrescriptionList } from '../../../Redux/actions/GetApiCall'
import { deletePrescription, saveuploadPrescription, uploadPrescription } from '../../../Redux/actions/PostApiCall'
import LoaderAnime from "../animation/prescription_loader";
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import ToastBox from '../animation/ToastBox'
import SuccessFull from '../../../Animation/SuccessFull'

const Medicine_Prescription = () => {
    const HomeId = useLocationState()
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [checkedFiles, setCheckedFiles] = useState([])
    const fileInputRef = useRef(null);
    const [modalShow, setModalShow] = useState(false);
    const [successmodal, setSuccessModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [statusFlag, setStatusFlag] = useState(false)
    const { loader: prescriptionLoader, data: prescriptionData, error: prescriptionListError } = useSelector(state => state.prescription_list);
    const { status: deleteStatus, error: deleteError, loader: deleteLoader, message: MessageDelete } = useSelector(state => state.delete_Prescription);
    const { status: uploadStatus, error: uploadError, loader: uploadLoader, data: uploadData } = useSelector(state => state.upload_Prescription);
    const saveUploadStatus = useSelector(state => state.save_upload_prescription)
    const Title = "Upload Prescription";

    const navigate = useNavigate()

    const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const invalidFiles = selectedFiles.filter(file => !allowedExtensions.includes(file.type));

        if (invalidFiles.length > 0) {
            toast.error('Invalid file type. Please upload jpg, jpeg, png, or pdf files only.');
            return; // Prevent file upload
        }

        const newFiles = selectedFiles.filter(file =>
            !files.some(prevFile => prevFile.name === file.name)
        );

        // Separate image files and PDF files
        const imageFiles = newFiles.filter(file => file.type.startsWith('image/'));
        const pdfFiles = newFiles.filter(file => file.type === 'application/pdf'); // Filter PDF files

        const validFiles = [...pdfFiles]; // Start with valid PDFs

        // Validate image dimensions for images only
        const checkPromises = imageFiles.map(file => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = function () {
                    if (img.width < 300 || img.height < 400) {
                        toast.error(`Image size must be at least 300x400 pixels. Invalid file: ${file.name}`);
                    } else {
                        validFiles.push(file);
                    }
                    resolve();
                };
                img.src = URL.createObjectURL(file);
            });
        });

        // Once dimension checks are complete, update files and checkedFiles
        Promise.all(checkPromises).then(() => {
            // Add valid files (images and PDFs) to the state
            setFiles(prevFiles => [...prevFiles, ...validFiles]);

            // Automatically check the newly uploaded files
            setCheckedFiles(prevCheckedFiles => [...prevCheckedFiles, ...validFiles]);
        });
    };




    const handleUploadPrescription = async () => {
        setShowModal(true);
        setStatusFlag(true)
        const formData = new FormData();

        checkedFiles.forEach(item => {
            if (item instanceof File) {
                // Append File objects to 'file[]'
                formData.append('files[]', item);
            } else if (typeof item === 'number') {
                // Append numbers to 'prescription[]'
                formData.append('prescription[]', item);
            }
        });

        if (HomeId && HomeId !== null && HomeId === 1) {
            await dispatch(saveuploadPrescription(formData))
        }
        else {
            await dispatch(uploadPrescription(formData));
        }
    }

    const handleCallDoctor = () => {
        const Title = "Call a doctor"
        navigate('/medicine-doctor', { state: Title })
    }

    useEffect(() => {
        if (uploadStatus === 'success' || saveUploadStatus?.status === "success") {
            setFiles(prevFiles => prevFiles.filter(file => !checkedFiles.includes(file)));
            setCheckedFiles([]);
            setShowModal(false)
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    }, [uploadStatus, saveUploadStatus]);

    useEffect(() => {
        if (uploadStatus === 'success' && uploadData?.success === true) {
            toast.success(uploadData?.message)
            const tabkey = "medicines"
            navigate("/address-list", { state: tabkey })
        }
        else if (saveUploadStatus?.data?.success === true && statusFlag === true) {
            setModalShow(true);
            setSuccessModal(true);
            setTimeout(() => {
                setModalShow(false);
                setStatusFlag(false)
                setSuccessModal(false);
                navigate('/buyMedicines')
            }, 2000);

        }
        else if (uploadStatus === 'failure' && uploadData?.success === false) {
            toast.error(uploadData?.message)
            navigate("/prescription")
        }
    }, [uploadStatus, saveUploadStatus])


    useEffect(() => {
        dispatch(fetchPrescriptionList());
        setShowModal(true)
    }, [dispatch]);

    const handleRemoveFile = (index) => {
        const fileToRemove = files[index];
        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        setCheckedFiles(checkedFiles.filter(file => file.name !== fileToRemove.name));

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleCheckboxChange = (input, isChecked) => {
        setCheckedFiles(prevCheckedFiles => {
            if (isChecked) {
                const exists = prevCheckedFiles.some(item => (item instanceof File ? item.name : item) === (input instanceof File ? input.name : input));
                if (!exists) {
                    return [...prevCheckedFiles, input];
                }
            } else {
                return prevCheckedFiles.filter(item => (item instanceof File ? item.name : item) !== (input instanceof File ? input.name : input));
            }
            return prevCheckedFiles;
        });
    };

    const handleDataRemoveFile = async (id) => {
        setShowModal(true)
        const presciptionData = {
            prescription_id: id
        };
        dispatch(deletePrescription(presciptionData))
    };

    useEffect(() => {
        if (deleteStatus === 'success') {
            dispatch(fetchPrescriptionList());
            setShowModal(false)
        }
    }, [deleteStatus])

    const isLoading = prescriptionLoader || deleteLoader || uploadLoader || saveUploadStatus?.loader;


    return (
        <div className="home-review-banner">
            <Medicine_Header Title={Title} />
            <>
                {isLoading && <LoaderAnime showModal={showModal} />}
                <div className="home-prescription-text-boxs">
                    <p>Please upload images of your prescription</p>
                    <span>Note : Upload JPG, PNG, PDF file format <br /> (Image size atleast 300*400 pixels)</span>
                </div>

                <div class="file-manager-data-boxs-repo">
                    <div class="file-manager-data-boxs-repo-left">
                        <label for="file-left">
                            <img src={camera_icon} alt="camera-icon" />
                            Open Camera
                        </label>
                        <input type="file" id="file-left" name="file-left" multiple capture="camera" accept="image/*" onChange={handleFileChange} />
                    </div>
                    <div class="file-manager-data-boxs-repo-right">
                        <label for="file">
                            <img src={folder_icon} alt="file-icon" />
                            Open File Manager
                        </label>
                        <input type="file" id="file" name="file" multiple onChange={handleFileChange} accept=".jpg,.jpeg,.png,.pdf" ref={fileInputRef} />
                    </div>
                </div>


                {files.length > 0 && (
                    <>
                        <div className="presc-header">
                            <p>New Prescription</p>
                        </div>

                        <div className="new-prescription-box-wrapper-data">
                            {files.map((file, index) => (
                                <div key={index} className="prescription-box">
                                    <div className="image-box-data-repo-cont">
                                        <button
                                            className="close-button"
                                            onClick={() => handleRemoveFile(index)}
                                        >
                                            &times;
                                        </button>
                                        {file.type === 'application/pdf' ? (
                                            <img
                                                src={pdf_icon}
                                                alt="PDF icon"
                                                className="prescription-image pdf-icon"
                                            />
                                        ) : (
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={file.name}
                                                className="prescription-image"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = no_preview;
                                                }}
                                            />
                                        )}
                                        <div className="checkbox-wrapper-46">
                                            <input
                                                type="checkbox"
                                                className="inp-cbx"
                                                id={`cbx-${index}`}
                                                checked={checkedFiles.some(item => (item instanceof File ? item.name : item) === file.name)}
                                                onChange={(e) => handleCheckboxChange(file, e.target.checked)}
                                            />
                                            <label htmlFor={`cbx-${index}`} className="cbx">
                                                <span>
                                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                    </svg>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="prescription-name">{file.name}</div>
                                </div>
                            ))}
                        </div>

                        <div className="line-header-repo-line-repo"></div>
                    </>
                )}

                {prescriptionData?.data?.length > 0 && (
                    <>
                        <div className="presc-header">
                            <p>Select previous prescriptions</p>
                        </div>
                        <div className="prescriptionData-data-box-cover">
                            {prescriptionData && prescriptionData?.data?.length > 0 && prescriptionData?.data?.map((file, index) => (
                                <div key={index} className="prescription-box" >
                                    <div className="image-box-data-repo-cont">
                                        <button
                                            className="close-button"
                                            onClick={(e) => handleDataRemoveFile(file?.id)}
                                        >
                                            &times;
                                        </button>
                                        <img
                                            src={file?.file_url}
                                            alt={file.name}
                                            className="prescription-image"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = no_preview;
                                            }}
                                        />
                                        <div class="checkbox-wrapper-46">
                                            <input type="checkbox" id={file?.id} class="inp-cbx" onChange={(e) => handleCheckboxChange(file?.id, e.target.checked)} />
                                            <label for={file?.id} class="cbx"><span>
                                                <svg viewBox="0 0 12 10" height="10px" width="12px">
                                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="prescription-name">{file?.og_file_name}</div>
                                </div>
                            ))
                            }
                        </div>
                    </>
                )}


                <div className="not-have-presciption" onClick={handleCallDoctor}>
                    <div className="data-box-text-presciption">
                        <p>You not have prescription</p>
                        <span>Don't worry connect the call with doctor</span>
                    </div>
                    <div className="data-box-image-presc">
                        <img src={prescription_image} alt="prescription-banner" />
                    </div>
                </div>

                <div className="valid-prescription-box-cover">
                    <div className="valid-text-repo-presc">
                        <p>What is valid prescription?</p>
                    </div>
                    <div className="valide-image-data-box-repo">
                        <img src={valid_prescription_image} alt="valid_prescription_image" />
                    </div>
                    <div className="valid-para-prescription-data">
                        <p>Prescription should contain details of doctor and patient's clinic visit date, medicines will be given as per prescription, with supported file types like jpeg, jpg, png and pdf.</p>
                    </div>
                </div>

                <ToastBox />

                {successmodal && (
                    <SuccessFull show={modalShow} successMessage={saveUploadStatus?.data?.message} animationtype={successmodal} />
                )}
            </>

            <div className="go-to-cart-main-page-wrapper prescription-footer">
                <div className="contine-btn-repo-presc sticky-bottom">
                    <button onClick={handleUploadPrescription} disabled={checkedFiles.length === 0}>CONTINUE</button>
                </div>
            </div>
        </div>



    )
}

export default Medicine_Prescription