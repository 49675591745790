import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { savings_image } from '../Images'
import '../Medicine_Style/cart.css'
import Cart_Card from '../Components/Cart_Card'
import EmptyCart from '../Components/EmptyCart'
import Need_Help from '../Components/Need_Help'

const Medicine_Cart = ({ setCartData }) => {
    const [cartListData, setCartListData] = useState([]);
    const dataCartMedi = useSelector(state => state.medicine_cart_add.data);
    const [cartRepo, setCartRepo] = useState([]);
    const { data: MedicineData, loader: cartLoader } = useSelector(state => state.medicine_cart_list);
    const medicartLoadDelet = useSelector(state => state.medicine_cart_delete?.data);
    console.log(MedicineData)

    useEffect(() => {
        if (MedicineData && MedicineData?.success === true) {
            // window.location.reload();
        }
    }, [])

    useEffect(() => {
        if ((medicartLoadDelet && medicartLoadDelet.success === true) && (MedicineData && MedicineData?.success === true)) {
            // window.location.reload()
        }
    }, [medicartLoadDelet])

    useEffect(() => {
        if (cartListData) {
            setCartData(cartListData)
        }
    }, [cartListData, setCartData])

    useEffect(() => {
        if (dataCartMedi !== null) {
            setCartRepo(dataCartMedi)
        } else {
            setCartRepo(MedicineData)
        }
    }, [MedicineData, dataCartMedi])



    return (
        <>

            <Cart_Card setCartListData={setCartListData} MedicineData={MedicineData} cartListData={cartListData} cartLoader={cartLoader} />


            {(MedicineData?.total_item > 0 || MedicineData?.total_items > 0) ? (
                <>
                    <div className="payment-repo-cover-box">
                        <div className="payment-text-repo">
                            <p>Payment Summary</p>
                        </div>

                        <div className="payment-summary-box-repo">
                            {MedicineData?.vas_charges?.details?.map((item, index) => (
                                <div className="price-payment-sum-repo" key={index}>
                                    <p>{item?.display_text}</p>
                                    <p>
                                        {item?.amount === "free" ? `₹ 0` : `₹ ${item?.amount?.toLocaleString('en-IN')}`}
                                    </p>
                                </div>
                            ))}
                        </div>

                        <div className="fin-text-repo">
                            <div className="price-payment-sum-repo total-pay-text">
                                <p>Total Payable</p>
                                <p>₹ {MedicineData?.payable_amount?.toLocaleString('en-IN')}</p>
                            </div>
                            <div className="price-payment-sum-repo total-save-text">
                                <p>
                                    <img src={savings_image}></img>
                                    Total Savings</p>
                                <p>₹ {MedicineData?.total_saving?.toLocaleString('en-IN')}</p>
                            </div>

                        </div>
                    </div>

                    <Need_Help type="medicine" />
                </>
            ) : (
                <EmptyCart />
            )}
        </>
    )
}

export default Medicine_Cart