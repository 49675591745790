import React from 'react'
import { Need_Help_Image } from '../Images'
import { useNavigate } from 'react-router'

const Need_Help = ({ type }) => {
    const navigate = useNavigate();
    const navigateToHelp = () => {
        navigate("/help", { state: type })
    }

    return (
        <div className="need-help-repo-textt">
            <div className="image-data-repo">
                <img src={Need_Help_Image} alt="help-image" />
            </div>
            <div className="text-help-box">
                <p>Need help with placing or canceling orders?</p>
                <button onClick={() => navigateToHelp()}>Click Here</button>
            </div>
        </div>
    )
}

export default Need_Help