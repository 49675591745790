import React, { useEffect, useState } from 'react'
import '../Medicine_Style/thankyou.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLabOrderDetails, fetchRadiologyOrderDetails, fetchThankyouData, getConsultationDetails, getQueryParams } from '../../../Redux/actions/GetApiCall'
import LoaderAnime from '../animation/prescription_loader'
import { useNavigate } from 'react-router-dom'
import useLocationState from '../hooks/useLocationState'
import ThankMedicine from '../Components/ThankMedicine'
import ThankTeleconsultation from '../Components/ThankTeleconsultation'
import ThankDiagontics from '../Components/ThankDiagontics'
import ThankRadiology from '../Components/ThankRadiology'

const ThankYou = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const orderData = useLocationState();
    const OrderLabData = useLocationState();
    const OrderTeleData = useLocationState();
    const OrderRadioData = useLocationState();
    const ThankData = useSelector(state => state.thankyou_data);
    const OrderDetailsData = useSelector(state => state.lab_order_Data_Details);
    const radioDetailsData = useSelector(state => state.radiology_order_list_response);
    const consultation_details_response = useSelector(
        (state) => state.get_consultation_details_response
    );

    console.log(consultation_details_response)
    const OrderSummaryData = OrderDetailsData?.data?.response;
    const radioSummaryData = radioDetailsData?.data?.response;
    const navigate = useNavigate();
    const orderID = 1
    const V2HREF = localStorage.getItem("V2HREF")

    const currentUrl = window.location.href;
    const { auto } = getQueryParams(currentUrl);
    const { id } = getQueryParams(currentUrl);

    useEffect(() => {
        if (orderData && orderData?.medicine_order_id)
            dispatch(fetchThankyouData(orderData?.medicine_order_id))
    }, []);

    useEffect(() => {
        if (OrderLabData && OrderLabData !== "" && OrderLabData?.tabKey === "second")
            dispatch(fetchLabOrderDetails(OrderLabData?.orderId));
    }, []);

    useEffect(() => {
        if (OrderLabData && OrderLabData !== "" && OrderLabData?.tabKey === "forth")
            dispatch(fetchRadiologyOrderDetails(OrderLabData?.orderId));
    }, []);

    useEffect(() => {
        if ((OrderTeleData && OrderTeleData !== "" && OrderTeleData?.tabKey === "third") || id) {
            dispatch(getConsultationDetails(OrderTeleData?.orderId || id));
        }
    }, []);

    useEffect(() => {
        if (ThankData?.status === "loading" || OrderDetailsData?.status === "loading" || consultation_details_response?.status === "loading" || radioDetailsData?.status === "loading") {
            setShowModal(true);
        } else if (ThankData?.status === "success" || OrderDetailsData?.status === "success" || consultation_details_response?.status === "success" || radioDetailsData?.status === "success") {
            setShowModal(false);
        }
    }, [ThankData, OrderDetailsData, consultation_details_response, radioDetailsData]);



    const handleHome = () => {
        if (orderData?.tabKey === "third") {
            navigate("/teleconsultation");
            window.location.reload();
        }
        else if (orderData?.tabKey === "second") {
            navigate("/diagnostictest")
        }
        else if (orderData?.tabKey === "forth") {
            navigate("/radio-home")
        }
        else {
            navigate("/buyMedicines")
        }
    }

    const handleAutoclose = () => {
        if (V2HREF && V2HREF !== null) {
            window.location.href = V2HREF
        }
    }

    const TeleDataThank = consultation_details_response?.data?.data
    console.log(consultation_details_response?.data?.data)


    const DataId = {
        medicine_order_id: orderData?.medicine_order_id,
        onemg_order_id: orderData?.onemg_order_id
    };

    const TeleData = {
        orderId: TeleDataThank?.id,
        tabKey: "third",
    };

    const DignosticsData = {
        orderId: OrderLabData?.orderId,
        tabKey: "second",
    }

    const RadioData = {
        orderId: OrderRadioData?.orderId,
        tabKey: "forth",
    }

    const handleOrderDetails = () => {
        if (orderData?.tabKey === "third") {
            // Navigate and pass TeleData if tabKey is "third"
            navigate("/teleconsultation-order-details", { state: TeleData });
        }

        else if (OrderLabData?.tabKey === "second") {
            navigate("/lab-order-details", { state: DignosticsData });
        }

        else if (OrderRadioData?.tabKey === "forth") {
            navigate("/radio-order-details", { state: RadioData })
        }
        else {
            // Navigate and pass DataId otherwise
            navigate("/shipment-details", { state: DataId });
        }
    };



    console.log(radioDetailsData)

    const product = ThankData?.data?.medicines || (orderData?.orderId && orderData?.AutoData === null) || (OrderLabData?.tabKey == "second") || (OrderRadioData?.tabKey == "forth");
    const address = ThankData?.data?.address;

    return (
        <div className="home-review-banner">
            <div className="home-app-header order-details-repo-data sticky-top">
                <div className="header-logo ">
                    <div className="user-name text-center text-repoo">
                        <p>Order Placed</p>
                    </div>
                </div>
            </div>

            {/* {showModal && <LoaderAnime showModal={showModal} />} */}
            {showModal ? (
                <LoaderAnime showModal={showModal} />
            ) : (
                <>
                    {orderData?.tabKey !== "third" && orderData?.tabKey !== "second" && orderData?.tabKey !== "forth" && (
                        <ThankMedicine ThankData={ThankData} product={product} address={address} />
                    )}

                    {((orderData?.tabKey === "third" && orderData?.tabKey !== "second" && orderData?.tabKey !== "forth") || id) && (
                        <ThankTeleconsultation ThankData={TeleDataThank} />
                    )}

                    {orderData?.tabKey === "second" && orderData?.tabKey !== "third" && orderData?.tabKey !== "forth" && (
                        <ThankDiagontics ThankData={OrderSummaryData} />
                    )}

                    {orderData?.tabKey !== "second" && orderData?.tabKey !== "third" && orderData?.tabKey === "forth" && (
                        <ThankRadiology ThankData={radioSummaryData} />
                    )}

                    {V2HREF && (
                        <div className="order-placed-data-buttons sticky-lg-bottom sticky-md-bottom sticky-bottom">
                            <button className='back-home-order' onClick={handleAutoclose}>
                                BACK TO HOME
                            </button>
                        </div>
                    )}

                    {product && !auto && !V2HREF && (
                        <div className="order-placed-data-buttons sticky-lg-bottom sticky-md-bottom sticky-bottom">
                            <button className='back-home-order' onClick={handleHome}>
                                BACK TO HOME
                            </button>
                            <button className='details-home-order' onClick={handleOrderDetails}>
                                ORDER DEATILS
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>

    )
}

export default ThankYou