// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import SuccessFully from "../AnimationJson/error-message.json";


const ErrorPack = () => {
    
    return (
        <Lottie animationData={SuccessFully} className='no-doctor-found' />
    )
}

export default ErrorPack;
