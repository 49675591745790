import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const useLocationState = () => {
    const location = useLocation();

    // If there are search parameters, just ignore them and return location.state
    return location.state !== null ? location.state : '';
  // const navigate = useNavigate();

  // const excludedPaths = ['/buyMedicines', '/teleconsultation','/diagnostictest'];

  // useEffect(() => {
  //     if (!location.state && !excludedPaths.includes(location.pathname)) {
  //         // window.history.back();
  //         // navigate('/home');
  //     }
  // }, [location, navigate]);
};

export default useLocationState;
