import React from 'react';
import { freeTeleconsultBg , teleconsultBookbg } from '../../../images';

const GeneralPhysicianComponent = ({ teleconsultationData ,handleBooking  }) => {
  // Filter to get only the data where is_general is true
  const generalData = teleconsultationData?.data?.data?.department.find(
    (item) => item.is_nutrition === true
  );

  const backgroundImage = generalData?.discount_type === 'free' 
    ? `url(${freeTeleconsultBg})`
    : `url(${teleconsultBookbg})`;



  return (
    <div className="home-teleconsultation-free-teleconsultation-cover"
    style={{ backgroundImage }} 
    onClick={() =>handleBooking({...generalData , type:"specialist" })}
    >
      <div className="free-consultation d-flex align-items-center">
        {/* <div className="consultation-icon-box">
          <img src={generalData?.file_name} alt="physician icon"></img>
        </div> */}
        <div className="consultation-content-box">
          <h5>{generalData?.department_name || generalData?.name}</h5>
          <p>Consultation</p>
        </div>
      </div>
    </div>
  );
};

export default GeneralPhysicianComponent;
